import React from 'react'

type Props = {
  statistics: StatisticsType
}

export const MoneyStatisticsTable = ({ statistics }: Props) => {
  const { countryAndState, currency, activePayoutMethod, recycledStatistics, payoutStatistics, balanceStatistics } =
    statistics

  return (
    <>
      <div className="flex mb-md p-lg">
        <dl className="flex-1">
          <dt id="countryState" className="inline-block label">
            Country/State
          </dt>
          <dd aria-labelledby="countryState">{countryAndState}</dd>
        </dl>

        <dl>
          <dt id="activePayoutMethod" className="inline-block label">
            Active payout method
          </dt>
          <dd aria-labelledby="activePayoutMethod">{activePayoutMethod || '-'}</dd>
        </dl>
      </div>

      <table className="table mb-lg">
        <thead>
          <tr>
            <th>Recycling total</th>
            <th>RVM amount</th>
            <th>Bagdrop amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {recycledStatistics.totalAmount} {currency}
            </td>
            <td>
              {recycledStatistics.reverseVendingAmount} {currency}
            </td>
            <td>
              {recycledStatistics.bagdropAmount} {currency}
            </td>
          </tr>
        </tbody>
      </table>

      <table className="table mb-lg">
        <thead>
          <tr>
            <th>Payout total</th>
            <th>Voucher amount</th>
            <th>Transfer amount</th>
            <th>Donation amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {payoutStatistics.totalAmount} {currency}
            </td>
            <td>
              {payoutStatistics.voucherAmount} {currency}
            </td>
            <td>
              {payoutStatistics.transferAmount} {currency}
            </td>
            <td>
              {payoutStatistics.donationAmount} {currency}
            </td>
          </tr>
        </tbody>
      </table>

      <table className="table">
        <thead>
          <tr>
            <th>Balance total</th>
            <th>Unused voucher amount</th>
            <th>Balance amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {balanceStatistics.totalAmount} {currency}
            </td>
            <td>
              {balanceStatistics.unredeemedVoucherAmount} {currency}
            </td>
            <td>
              {balanceStatistics.balanceAmount} {currency}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
