import React, { useState, useEffect, FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { authenticatedFetchData, API_HOST } from '../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { ConsumerSessionsTable } from '../../common/ConsumerSessionsTable'

type csResult = {
  consumerSession: ConsumerSessionType
  payouts: PayoutType[]
}[]

export const UserConsumerSessions: FunctionComponent = () => {
  const { userId } = useParams()
  const [userCSList, setUserCSList] = useState<ConsumerSessionType[]>([])
  const [requestStatus, setRequestStatus] = useState<string>('loading')
  const [limit, setLimit] = useState<number>(25)
  const canPotentiallyFetchMore = userCSList.length === limit

  const fetchMore = () => setLimit(limit + 25)

  useEffect(() => {
    limit > 25 ? setRequestStatus('loadingMore') : setRequestStatus('loading')

    const f = authenticatedFetchData(
      `${API_HOST}/mytomra/v1.0/hotlinetool/usercs?userid=${encodeURIComponent(userId || '')}&limit=${limit}`
    )

    f.run().then(
      (result: csResult) => {
        setUserCSList(result.map(cs => cs.consumerSession))
        setRequestStatus('')
      },
      (error: Error) => {
        if (error.name !== 'AbortError') {
          setRequestStatus('failed')
          logError(new Error("Failed to fetch user CS's"), error)
        }
      }
    )

    return () => f.abort()
  }, [userId, limit])

  if (requestStatus === 'loading') {
    return (
      <div className="centerAbsolute">
        <div className="loadingSpinner" />
      </div>
    )
  }

  if (requestStatus === 'failed') {
    return (
      <div className="alert alert-danger centerAbsolute">Whoopsie! Failed to fetch consumer sessions for user.</div>
    )
  }

  return (
    <>
      <ConsumerSessionsTable consumerSessions={userCSList} />

      <div className="flex justify-center py-lg">
        <button className="btn btn-primary-dark" disabled={!canPotentiallyFetchMore} onClick={fetchMore}>
          {requestStatus === 'loadingMore' ? <div className="loadingSpinner" /> : 'More'}
        </button>
      </div>
    </>
  )
}
