import React, { FunctionComponent, useState } from 'react'
import { authenticatedFetchData, API_HOST } from '../../../lib'
import { logError } from '@tomra/datadog-browser-logging'

type Props = {
  refetchNotes: () => void
  setEditMode: (edit: boolean) => void
  noteId: string
  note: string
}

export const NoteEdit: FunctionComponent<Props> = ({ refetchNotes, setEditMode, noteId, note }) => {
  const [editNote, setEditNote] = useState(note)
  const [notesStatus, setNotesStatus] = useState('')
  const [editStatus, setEditStatus] = useState('')

  const noteEdit = (e: any) => {
    e.preventDefault()
    setEditStatus('loading')

    authenticatedFetchData(`${API_HOST}/mytomra/v1.0/hotlinetool/managernotes/${noteId}`, {
      method: 'PUT',
      body: JSON.stringify({
        note: editNote
      })
    })
      .run()
      .then(
        () => {
          setNotesStatus('')
          setEditStatus('')
          setEditNote('')
          setEditMode(false)
          refetchNotes()
        },
        (error: Error) => {
          setNotesStatus('failed')
          logError(new Error('Failed to edit note'), error)
        }
      )
  }

  if (notesStatus === 'failed') {
    return <div className="alert alert-danger mt-md mb-md">Whoopsie! Failed to update note. Please try again.</div>
  }

  return (
    <>
      <label htmlFor="editNote" className="block mb-sm">
        Edit note
      </label>

      <textarea
        id="editNote"
        className="textarea min-w-full resize-none"
        onChange={evt => setEditNote(evt.target.value)}
        value={editNote}
      />

      <div className="mt-md flex justify-end">
        <button className="btn mr-sm" onClick={() => setEditMode(false)}>
          Cancel
        </button>

        <button className="btn btn-primary-dark ml-sm" onClick={noteEdit} disabled={editNote === ''}>
          {editStatus === 'loading' ? <div className="loadingSpinner" aria-label="Please wait" /> : 'Save'}
        </button>
      </div>
    </>
  )
}
