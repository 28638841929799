export const convertCSVObjectsToReadable = (pendingBags: any) => {
  return {
    ...pendingBags,
    bagtag: `\t ${pendingBags.bagtag}`,
    created: new Date(pendingBags.created).toLocaleString('default', {
      timeZone: pendingBags.locationTimezone || 'UTC'
    }),
    userId: `${pendingBags.userId}`
  }
}
