import React, { FunctionComponent } from 'react'
import { ArrowRightIcon } from '../../../icons'
import { Link } from 'react-router-dom'
import { campaignRunningStateMap } from '../common/charityCampaignMap'

type Props = {
  applications: CharitySummaryType[]
}

export const CharityApplicationTable: FunctionComponent<Props> = ({ applications }) => {
  return (
    <table className="table" aria-labelledby="charityTableHeader">
      <thead>
        <tr>
          <th>Organisation</th>
          <th>Display name</th>
          <th>Date</th>
          <th>Campaign status</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {applications?.map(application => {
          const { id, organizationName, displayName, createdAt, campaignRunState } = application

          return (
            <tr key={id}>
              <td>{organizationName}</td>
              <td>{displayName ?? '-'}</td>
              <td>{new Date(createdAt).toLocaleString()}</td>
              <td>{campaignRunningStateMap[campaignRunState]}</td>
              <td>
                <div className="flex justify-end">
                  <Link to={`/charities/${id}`} aria-label={`View ${displayName} details`}>
                    <ArrowRightIcon width="1rem" height="1rem" />
                  </Link>
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
