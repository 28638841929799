import React, { FunctionComponent, useEffect, useState } from 'react'
import { Dialog } from '../../../common/Dialog'
import { ConfirmDialog, AlertDialog, StatusDialog, ErrorDialog } from '../../../common'
import { ExternalLocationsMap } from '../common/ExternalLocationsMap'
import { applyExternalLocationDiff, rejectExternalLocationDiff } from '../../../../services/external-locations.service'
import { logError } from '@tomra/datadog-browser-logging'
import { ExternalLocationListGroup } from './ExternalLocationListGroup'

type DiffRequestStatus =
  | 'idle'
  | 'applying_diff'
  | 'applying_diff_failure'
  | 'rejecting_diff'
  | 'rejecting_diff_failure'

type Props = {
  fileId: string
  diff: ExternalLocationDiffType
  onDiffApplied: () => void
  onDiffRejected: () => void
}

export const ExternalLocationDiffOverview: FunctionComponent<Props> = ({
  fileId,
  diff,
  onDiffApplied,
  onDiffRejected
}) => {
  const [allLocations, setAllLocations] = useState<ExternalLocationType[]>([])
  const [showConfirmRejectDiff, setShowConfirmRejectDiff] = useState(false)
  const [showConfirmApplyDiff, setShowConfirmApplyDiff] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState<ExternalLocationType>()
  const [added, setAdded] = useState<ExternalLocationType[]>([])
  const [updated, setUpdated] = useState<ExternalLocationType[]>([])
  const [removed, setRemoved] = useState<ExternalLocationType[]>([])
  const [status, setStatus] = useState<DiffRequestStatus>('idle')
  const [openListGroups, setOpenListGroups] = useState<string[]>(['UPDATED', 'REMOVED', 'ADDED'])

  useEffect(() => {
    const addedLocationsWithStatus = diff.added.map(location => addStatus(location)('ADDED'))
    setAdded(addedLocationsWithStatus)

    const removedLocationsWithStatus = diff.removed.map(location => addStatus(location)('REMOVED'))
    setRemoved(removedLocationsWithStatus)

    const updatedLocationsWithStatus = diff.updated.map(location => {
      return addStatus({
        ...location.newValue,
        oldValue: location.oldValue
      } as ExternalLocationType)('UPDATED')
    })
    setUpdated(updatedLocationsWithStatus)

    setAllLocations([...addedLocationsWithStatus, ...removedLocationsWithStatus, ...updatedLocationsWithStatus])
  }, [diff])

  const confirmDiff = () => {
    setShowConfirmApplyDiff(false)
    setStatus('applying_diff')

    applyExternalLocationDiff(fileId, diff)
      .then(() => {
        setShowConfirmApplyDiff(false)
        setStatus('idle')
        onDiffApplied()
      })
      .catch((error: any) => {
        setStatus('applying_diff_failure')
        logError(new Error('Unable to apply external location diff'), error)
      })
  }

  const rejectDiff = () => {
    setShowConfirmRejectDiff(false)
    setStatus('rejecting_diff')

    rejectExternalLocationDiff(fileId)
      .then(() => {
        setStatus('idle')
        onDiffRejected()
      })
      .catch((error: any) => {
        setStatus('rejecting_diff_failure')
        logError(new Error('Unable to apply external location diff'), error)
      })
  }

  const toggleOpenListGroup = (group: string) => {
    const isOpen = openListGroups.indexOf(group) !== -1
    setOpenListGroups(isOpen ? openListGroups.filter(g => g !== group) : [...openListGroups, group])
  }

  const hasChanges = added.length > 0 || removed.length > 0 || updated.length > 0

  return hasChanges ? (
    <>
      <Dialog ariaLabel="Review" position="TOP">
        <div className="card p-lg flex flex-col min-w-screen-xl max-w-screen-xl mt-5" style={{ height: '80vh' }}>
          <div className="grid grid-cols-12">
            <div className="col-span-4 mb-md">
              <h1 className="text-xl">Review</h1>
              <div className="text-sm font-bold">Showing {allLocations.length} changed locations</div>
            </div>
          </div>

          <div className="grid grid-cols-12 space-x-md flex-1 h-full overflow-hidden">
            <div className="col-span-4 flex flex-col h-full overflow-y-scroll">
              {added.length > 0 && (
                <ExternalLocationListGroup
                  title={`Added (${added.length})`}
                  isOpen={openListGroups.indexOf('ADDED') !== -1}
                  toggleOpen={() => toggleOpenListGroup('ADDED')}
                  locations={added}
                  selectedLocation={selectedLocation}
                  onLocationClicked={setSelectedLocation}
                />
              )}

              {removed.length > 0 && (
                <ExternalLocationListGroup
                  title={`Removed (${removed.length})`}
                  isOpen={openListGroups.indexOf('REMOVED') !== -1}
                  toggleOpen={() => toggleOpenListGroup('REMOVED')}
                  locations={removed}
                  selectedLocation={selectedLocation}
                  onLocationClicked={setSelectedLocation}
                />
              )}

              {updated.length > 0 && (
                <ExternalLocationListGroup
                  title={`Updated (${updated.length})`}
                  isOpen={openListGroups.indexOf('UPDATED') !== -1}
                  toggleOpen={() => toggleOpenListGroup('UPDATED')}
                  locations={updated}
                  selectedLocation={selectedLocation}
                  onLocationClicked={setSelectedLocation}
                />
              )}
            </div>
            <div className="col-span-8">
              <ExternalLocationsMap
                locations={allLocations}
                selectedLocation={selectedLocation}
                onLocationClicked={setSelectedLocation}
                onCloseMapInfo={() => setSelectedLocation(undefined)}
              />
            </div>
          </div>

          <div className="grid grid-cols-12 space-x-md pt-lg">
            <button
              type="button"
              onClick={() => setShowConfirmRejectDiff(true)}
              className="col-span-2 col-start-9 btn text-red border-red"
            >
              Reject
            </button>
            <button
              type="button"
              className="col-span-2 btn btn-primary-dark"
              onClick={() => setShowConfirmApplyDiff(true)}
            >
              Apply
            </button>
          </div>
        </div>
      </Dialog>

      {status === 'applying_diff' ? (
        <StatusDialog text="Applying changes..." />
      ) : status === 'applying_diff_failure' ? (
        <ErrorDialog
          title="Unable to apply changes"
          description="Something went wrong on our side. Please try again or contact support."
          onClose={() => setStatus('idle')}
        />
      ) : status === 'rejecting_diff' ? (
        <StatusDialog text="Rejecting changes..." />
      ) : status === 'rejecting_diff_failure' ? (
        <ErrorDialog
          title="Unable to reject changes"
          description="Something went wrong on our side. Please try again or contact support."
          onClose={() => {
            setStatus('idle')
            onDiffRejected()
          }}
        />
      ) : null}

      {showConfirmRejectDiff && (
        <ConfirmDialog
          title="Reject changes?"
          confirmButtonText="Reject"
          cancelButtonText="No"
          onCancel={() => setShowConfirmRejectDiff(false)}
          onConfirm={rejectDiff}
        />
      )}

      {showConfirmApplyDiff && (
        <ConfirmDialog
          title="Apply changes?"
          confirmButtonText="Apply"
          cancelButtonText="No"
          onCancel={() => setShowConfirmApplyDiff(false)}
          onConfirm={confirmDiff}
        />
      )}
    </>
  ) : (
    <AlertDialog
      title="No changes found"
      description="We couldn't find any changes. Perhaps you've uploaded the wrong file?"
      onClose={onDiffRejected}
    />
  )
}

const addStatus = (location: ExternalLocationType) => {
  return (status: string) => ({ ...location, status }) as ExternalLocationType
}
