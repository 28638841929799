import { RefObject } from 'react'

export function removeProperty(object: { [key: string]: any }, property: string): { [key: string]: any } {
  const { [property]: result } = object
  return result
}

export const scrollIntoViewPort = (ref: RefObject<HTMLElement>) => {
  if (ref.current && ref.current.parentElement) {
    const bottomOfIframe = ref.current.getBoundingClientRect().bottom
    const isInViewPort = bottomOfIframe < window.innerHeight

    const parent = ref.current.parentElement
    const scrollTo = parent.style.position === 'relative' ? parent.offsetTop : ref.current.offsetTop

    if (!isInViewPort) {
      window.scroll({
        top: scrollTo - 100,
        behavior: 'smooth'
      })
    }
  }
}
