import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const SearchPage = () => {
  const navigate = useNavigate()
  const [value, setValue] = useState('')

  const search = (query: any) => {
    if (query.split('-').length === 5 || query.match(/^\w{28}$/g)) {
      navigate(`/user/${query}`)
    } else if (query.match(/^\d{18}$/g)) {
      navigate(`/payout/${query}`)
    } else if (query.match(/^0{1}\d{23}$/g)) {
      navigate(`/bagtag/${query}`)
    } else if (query.match(/^\d{24,25}$/g)) {
      navigate(`/consumersession/${query}`)
    } else {
      navigate(`/users/${query}`)
    }
  }

  return (
    <form
      onSubmit={evt => {
        evt.preventDefault()
        search(value)
      }}
      className="centerAbsolute min-w-screen-sm"
    >
      <div className="flex space-x-md items-center">
        <input
          type="search"
          className="text-lg py-md px-lg"
          aria-label="Search for email, user ID, payout ID etc."
          value={value}
          placeholder="Name, user ID, payout ID etc."
          onChange={evt => setValue(evt.target.value)}
        />

        <button type="submit" className="btn w-1/3">
          Search
        </button>
      </div>
    </form>
  )
}
