import React, { FunctionComponent } from 'react'
import { ExternalLocationsList } from '../common/ExternalLocationsList'
import { ArrowDownIcon } from '../../../icons'

type Props = {
  title: string
  isOpen: boolean
  toggleOpen: () => void
  locations: ExternalLocationType[]
  selectedLocation?: ExternalLocationType
  onLocationClicked: (location: ExternalLocationType) => void
}

export const ExternalLocationListGroup: FunctionComponent<Props> = ({
  title,
  isOpen,
  toggleOpen,
  locations,
  selectedLocation,
  onLocationClicked
}) => {
  return (
    <div>
      <button
        onClick={toggleOpen}
        className="w-full text-left font-bold py-md sticky top-0 bg-white z-10 rounded-sm focus:outline-none focus:ring-4 focus:ring-grey focus:ring-opacity-50"
      >
        <div className="flex justify-between items-center">
          <span>{title}</span>
          <div
            aria-hidden="true"
            className="transition"
            style={isOpen ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0)' }}
          >
            <ArrowDownIcon width="1rem" height="1rem" />
          </div>
        </div>
      </button>

      {isOpen && (
        <ExternalLocationsList
          ariaLabel={title}
          locations={locations}
          selectedLocation={selectedLocation}
          onLocationClicked={onLocationClicked}
        />
      )}
    </div>
  )
}
