import React, { FunctionComponent } from 'react'
import { ExternalLocationChangedMap } from '../common/ExternalLocationChangedMap'
import { ArrowRightIcon, LocationIcon } from '../../../icons'
import { locationHasInvalidCoordinates } from '../locationHelpers'

const colorMap = {
  UPDATED: 'var(--colors-blue-dark)',
  ADDED: 'var(--colors-green)',
  REMOVED: 'var(--colors-red)'
}

type Props = {
  location?: ExternalLocationType
}

export const ExternalLocationDiff: FunctionComponent<Props> = ({ location }) => {
  if (!location) {
    return null
  }

  const { oldValue } = location

  const changedValues =
    oldValue && location.status === 'UPDATED'
      ? Object.keys(location).reduce((acc: any, key: string) => {
          if (oldValue[key as keyof ExternalLocationType] !== location[key as keyof ExternalLocationType]) {
            return [...acc, key]
          }

          return acc
        }, [])
      : []

  const locationHasChanged =
    (changedValues.includes('latitude') || changedValues.includes('longitude')) &&
    Boolean(location.latitude && location.longitude)

  const addressHasChanged =
    changedValues.includes('address') ||
    changedValues.includes('city') ||
    changedValues.includes('zipCode') ||
    changedValues.includes('state')

  return (
    <>
      {locationHasChanged && <ExternalLocationChangedMap location={location} />}

      {location.status && (
        <div className="font-bold" style={{ color: colorMap[location.status] }}>
          {location.status}
        </div>
      )}

      <div className="flex items-center justify-between">
        {changedValues.includes('name') ? (
          <>
            <div className="line-through flex-1 font-bold text-lg">{oldValue?.name}</div>
            <div className="w-3 flex justify-center">
              <ArrowRightIcon width="1rem" height="1rem" />
            </div>
            <h2 className="flex-1 font-bold text-lg">{location.name}</h2>
          </>
        ) : (
          <h2 className="flex-1 font-bold text-lg">{location.name}</h2>
        )}
      </div>

      <div className="flex items-center justify-between">
        {addressHasChanged ? (
          <>
            <div className="line-through flex-1">
              {oldValue?.address} {oldValue?.city} {oldValue?.zipCode} {oldValue?.state}
            </div>
            <div className="w-3 flex justify-center">
              <ArrowRightIcon width="1rem" height="1rem" />
            </div>
            <div className="flex-1">
              {location.address} {location.city} {location.zipCode} {location.state}
            </div>
          </>
        ) : (
          <div className="flex-1">
            {location.address} {location.city} {location.zipCode} {location.state}
          </div>
        )}
      </div>

      <div className="mt-md">
        <div className="font-bold text-storm">Opening hours</div>
        {changedValues.includes('openingHours') ? (
          <div className="flex items-center justify-between">
            <div className="line-through flex-1">{oldValue?.openingHours ?? '-'}</div>
            <div className="w-3 flex justify-center">
              <ArrowRightIcon width="1rem" height="1rem" />
            </div>
            <h3 className="flex-1">{location.openingHours ?? '-'}</h3>
          </div>
        ) : (
          <h3 className="flex-1">{location.openingHours ?? '-'}</h3>
        )}
      </div>

      {locationHasInvalidCoordinates(location) && (
        <div className="mt-md flex space-x-xs">
          <LocationIcon width="1.5rem" height="1.5rem" color="var(--colors-red)" />
          <div className="text-red font-bold">
            This location has invalid or missing coordinates and will not be shown on the map
          </div>
        </div>
      )}

      <div className="flex mt-md">
        <div className="flex-1">
          <div className="font-bold text-storm">External ID</div>
          <div>{location.externalId ?? '-'}</div>
        </div>

        <div className="w-3" />

        <div className="flex-1">
          <div className="font-bold text-storm">Collection point type</div>
          {changedValues.includes('collectionPointType') ? (
            <div className="flex items-center justify-between">
              <div className="line-through flex-1">{oldValue?.collectionPointType ?? '-'}</div>
              <div className="w-3 flex justify-center">
                <ArrowRightIcon width="1rem" height="1rem" />
              </div>
              <div className="flex-1">{location.collectionPointType ?? '-'}</div>
            </div>
          ) : (
            (location.collectionPointType ?? '-')
          )}
        </div>
      </div>

      <div className="flex mt-md">
        <div className="flex-1">
          <div className="font-bold text-storm">Latitude</div>
          {changedValues.includes('latitude') ? (
            <div className="flex items-center justify-between">
              <div className="line-through flex-1">{oldValue?.latitude ?? '-'}</div>
              <div className="w-3 flex justify-center">
                <ArrowRightIcon width="1rem" height="1rem" />
              </div>
              <div className="flex-1">{location.latitude ?? '-'}</div>
            </div>
          ) : (
            (location.latitude ?? '-')
          )}
        </div>

        <div className="w-3" />

        <div className="flex-1">
          <div className="font-bold text-storm">Longitude</div>
          {changedValues.includes('longitude') ? (
            <div className="flex items-center justify-between">
              <div className="line-through flex-1">{oldValue?.longitude ?? '-'}</div>
              <div className="w-3 flex justify-center">
                <ArrowRightIcon width="1rem" height="1rem" />
              </div>
              <div className="flex-1">{location.longitude ?? '-'}</div>
            </div>
          ) : (
            (location.longitude ?? '-')
          )}
        </div>
      </div>

      <div className="flex justify-between mt-md">
        {location.created && (
          <div className="flex-1">
            <div className="font-bold text-storm">Created</div>
            <div>{new Date(location.created).toLocaleDateString()}</div>
          </div>
        )}

        <div className="w-3" />

        {location.lastUpdated && (
          <div className="flex-1">
            <div className="font-bold text-storm">Updated</div>
            <div>{new Date(location.lastUpdated).toLocaleDateString()}</div>
          </div>
        )}
      </div>
    </>
  )
}
