import React, { FunctionComponent, useEffect, useState } from 'react'
import { ReviewPendingChanges } from './ReviewPendingChanges'
import { API_HOST, authenticatedFetchData } from '../../../../../lib'
import { logError } from '@tomra/datadog-browser-logging'

type Props = {
  charityId: string
  campaignId?: string
  onComplete: (changesToMerge?: CharityType | CharityCampaignType) => void
}

export const PendingChangesReviewButton: FunctionComponent<Props> = ({ charityId, campaignId, onComplete }) => {
  const [showReview, setShowReview] = useState(false)
  const [changesToReview, setChangesToReview] = useState<CharityType | CharityCampaignType | null>(null)

  useEffect(() => {
    const { run, abort } = campaignId
      ? authenticatedFetchData(`${API_HOST}/charities/admin/v1/charities/${charityId}/pending-campaigns/${campaignId}`)
      : authenticatedFetchData(`${API_HOST}/charities/admin/v1/pending-charities/${charityId}`)

    run()
      .then(response => {
        setChangesToReview(response)
      })
      .catch(error => {
        if (error.name !== 'AbortError' && error.status !== 404) {
          logError(new Error('Unable to fetch pending changes'), error)
        }
      })

    return () => abort()
  }, [])

  return (
    <>
      <button className="btn" onClick={() => setShowReview(true)}>
        Review
      </button>

      {changesToReview && showReview ? (
        <ReviewPendingChanges
          charityId={charityId}
          campaignId={campaignId}
          changesToReview={changesToReview}
          onComplete={() => {
            setShowReview(false)
            onComplete(changesToReview)
          }}
          onCancel={() => {
            setShowReview(false)
          }}
        />
      ) : null}
    </>
  )
}
