import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { Map, MapDialog } from '../../../common/map'
import { ExternalLocationDiff } from '../diff/ExternalLocationDiff'
import bluePin from '../../../common/map/pins/blueMapPin.svg'
import redPin from '../../../common/map/pins/redMapPin.svg'
import greenPin from '../../../common/map/pins/greenMapPin.svg'
import { logError } from '@tomra/datadog-browser-logging'
import { locationHasValidCoordinates } from '../locationHelpers'
import { ExternalLocationsInvalidCoordinatesWarning } from './ExternalLocationsInvalidCoordinatesWarning'

const pinMap = {
  UPDATED: bluePin,
  ADDED: greenPin,
  REMOVED: redPin
}

type Props = {
  locations: ExternalLocationType[]
  selectedLocation?: ExternalLocationType
  onLocationClicked: (location: ExternalLocationType) => void
  onCloseMapInfo: () => void
}

export const ExternalLocationsMap: FunctionComponent<Props> = ({
  locations,
  selectedLocation,
  onLocationClicked,
  onCloseMapInfo
}) => {
  const [mapRef, setMapRef] = useState<google.maps.Map>()
  const [markers, setMarkers] = useState<google.maps.Marker[]>([])
  const [bounds, setBounds] = useState<google.maps.LatLngBounds>()

  const mapCenter = selectedLocation
    ? { lat: selectedLocation.latitude as number, lng: selectedLocation.longitude as number }
    : { lat: -25, lng: 132 }

  const resetMap = useCallback(() => {
    if (mapRef && bounds) {
      markers.forEach(marker => marker.setAnimation(null))
      mapRef?.fitBounds(bounds)
    }
  }, [mapRef, bounds, markers])

  const bounceSelectedLocationPin = useCallback(
    (marker: google.maps.Marker) => {
      if (selectedLocation) {
        const latitude = marker.getPosition()?.lat()
        const longitude = marker.getPosition()?.lng()

        if (selectedLocation.latitude === latitude && selectedLocation.longitude === longitude) {
          marker.setAnimation(google.maps.Animation.BOUNCE)
          mapRef?.panBy(0, 100)
        } else {
          marker.setAnimation(null)
        }
      }
    },
    [mapRef, selectedLocation]
  )

  useEffect(() => {
    if (!selectedLocation) {
      resetMap()
    } else {
      markers.forEach(bounceSelectedLocationPin)
    }
  }, [selectedLocation, markers, resetMap, bounceSelectedLocationPin])

  const onMapLoaded = ({ map, maps }: { map: google.maps.Map; maps: typeof google.maps }) => {
    if (map && maps) {
      setMapRef(map)

      try {
        const initialBounds = new google.maps.LatLngBounds()

        const markers = locations.filter(locationHasValidCoordinates).reduce((acc: google.maps.Marker[], location) => {
          initialBounds.extend({ lat: location.latitude as number, lng: location.longitude as number })

          const marker = addMarkerForLocation(location, map, marker => {
            onLocationClicked(location)
            markers.forEach(marker => marker.setAnimation(null))
            marker.setAnimation(google.maps.Animation.BOUNCE)
          })
          return [...acc, marker]
        }, [])

        setBounds(initialBounds)
        setMarkers(markers)
        map.fitBounds(initialBounds)
      } catch (error: any) {
        logError(new Error('Unable to show locations in map'), error)
      }
    }
  }

  return (
    <div
      className="relative w-full h-full col-span-8 rounded-half overflow-hidden shadow"
      role="region"
      aria-label={`${selectedLocation?.name} details`}
    >
      <ExternalLocationsInvalidCoordinatesWarning locations={locations} />

      <Map zoom={selectedLocation ? 12 : 8} center={mapCenter} onMapLoaded={onMapLoaded} />

      <MapDialog isOpen={!!selectedLocation} onClose={onCloseMapInfo}>
        <ExternalLocationDiff location={selectedLocation} />
      </MapDialog>
    </div>
  )
}

const addMarkerForLocation = (
  location: ExternalLocationType,
  map: google.maps.Map,
  onMarkerClicked: (marker: google.maps.Marker) => void
) => {
  const marker = new google.maps.Marker({
    position: { lat: location.latitude as number, lng: location.longitude as number },
    map,
    icon: location.status ? pinMap[location.status] : bluePin,
    title: `View ${location.name}`
  })

  marker.addListener('click', () => onMarkerClicked(marker))

  return marker
}
