import React, { FunctionComponent } from 'react'
import { CharitySearch } from './search/CharitySearch'
import { CharityActionList } from './CharityActionList'
import { PromotedCampaignsProvider } from './promoted/PromotedCampaignsProvider'
import { PromotedCampaigns } from './promoted/PromotedCampaigns'
import { Link } from 'react-router-dom'

export const CharitiesHomePage: FunctionComponent = () => {
  return (
    <div className="p-lg">
      <div className="flex items-center justify-between">
        <h1 className="text-xl">Charities</h1>

        <div className="flex space-x-md mb-md justify-end pr-lg">
          <Link to="/charities/list" className="link">
            View all charities
          </Link>
          <Link to="/campaigns/list" className="link">
            View all campaigns
          </Link>
        </div>
      </div>

      <div className="flex">
        <div className="w-2/3 mr-lg">
          <CharityActionList />
        </div>

        <div className="flex-1">
          <CharitySearch />

          <PromotedCampaignsProvider>
            <PromotedCampaigns />
          </PromotedCampaignsProvider>
        </div>
      </div>
    </div>
  )
}
