export const legalStructuresMap = {
  ACNC_CHARITY: 'Registered as a charity',
  REGISTERED_SCHOOL: 'Registered school',
  NON_PROFIT_UNDER_AIA2009: 'Incorporated association',
  NON_PROFIT_COMPANY: 'Incorporated as a company limited guarantee',
  OTHER: 'Other'
}

export const geoFootprintMap = {
  LOCAL: 'Local',
  STATE: 'State',
  NATIONAL: 'National'
}

export const benefitMap = {
  ANIMAL_WELFARE: 'Animal welfare',
  ARTS_AND_CULTURE: 'Arts and culture',
  COMMUNITY_DEVELOPMENT: 'Community development',
  DISABILITY_SERVICES: 'Disability services',
  DOMESTIC_AND_FAMILY: 'Domestic and family',
  DROUGHT_AND_BUSHFIRE_RELIEF: 'Natural Disaster Relief',
  EDUCATION: 'Education',
  ENVIRONMENT: 'Environment',
  HEALTH: 'Health',
  HUMAN_AND_CIVIL_RIGHTS: 'Human and civil rights',
  HUMAN_SERVICES: 'Human services',
  INTERNATIONAL_AID: 'International aid',
  RELIGION: 'Religion',
  RESEARCH_AND_PUBLIC_POLICY: 'Research and public policy',
  SPORTS: 'Sports',
  VETERANS_SERVICES: 'Veteran services'
}

export const dgrStatusMap = {
  NONE: "My organisation doesn't have the DGR endorsement",
  UNRESTRICTED: 'My organisation has unrestricted DGR endorsement with the ATO',
  RESTRICTED: 'My organisation has restricted DGR endorsement with the ATO',
  RESTRICTED_DGR_ACTIVITIES_YES:
    'My organisation has restricted DGR endorsement with the ATO. The funds raised through the app will be spent exclusively on DGR related activities.',
  RESTRICTED_DGR_ACTIVITIES_NO:
    'My organisation has restricted DGR endorsement with the ATO. The funds raised through the app will not be spent exclusively on DGR related activities.'
}

export const charityValueMap: { [key: string]: string } = {
  ...legalStructuresMap,
  ...geoFootprintMap,
  ...benefitMap,
  ...dgrStatusMap,
  NONE: 'None',
  RESTRICTED: 'Restricted',
  UNRESTRICTED: 'Unrestricted',
  name: 'Name',
  organizationAbn: 'ABN',
  operatingState: 'Registered in',
  establishedDate: 'Established',
  legalStructure: 'Legal stucture',
  legalStructureDetails: 'Legal structure details',
  geoFootprint: 'Operational footprint',
  incorporationNumber: 'Incorporation number',
  displayName: 'Display name',
  localGroupName: 'Local group name',
  streetAddress: 'Street address',
  suburb: 'Suburb',
  postCode: 'Postcode',
  benefit: 'Benefit',
  description: 'Description',
  websiteUrl: 'Website url',
  accountName: 'Name',
  accountBsb: 'BSB',
  accountNumber: 'Account number',
  dgrStatus: 'DGR status',
  firstName: 'First name',
  lastName: 'Last name',
  position: 'Position',
  email: 'E-mail',
  phone: 'Phone',
  organization: 'Organisation',
  bankingData: 'Banking data',
  primaryContact: 'Primary contact',
  secondaryContact: 'Secondary contact',
  status: 'Status',
  startTime: 'Start',
  endTime: 'End',
  financialTarget: 'Financial target',
  engagementPlan: 'Engagement plan',
  createdAt: 'Created at',
  facebookHandle: 'Facebook',
  instagramHandle: 'Instagram',
  linkedinHandle: 'LinkedIn',
  snapchatHandle: 'SnapChat',
  tiktokHandle: 'TikTok',
  youtubeHandle: 'YouTube'
}

export const availableStates = ['NSW', 'VIC', 'TAS']

export const schemeMap: { [key: string]: string } = {
  NSW: 'Return and Earn',
  VIC: 'CDS Vic West',
  TAS: 'Recycle Rewards'
}
