import { Map } from '../../../common/map'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import redPin from '../../../common/map/pins/redMapPin.svg'
import bluePin from '../../../common/map/pins/blueMapPin.svg'

type Props = {
  location: ExternalLocationType
}

export const ExternalLocationChangedMap: FunctionComponent<Props> = ({ location }) => {
  const [mapRef, setMapRef] = useState<google.maps.Map>()
  const mapData = useRef<(google.maps.Marker | google.maps.Polyline)[]>([])

  useEffect(() => {
    if (mapRef && location) {
      mapData.current.forEach(data => data.setMap(null))
      mapData.current = []

      const oldLocation = getLocationCoordinates(location.oldValue)
      const newLocation = getLocationCoordinates(location)

      const bounds = new google.maps.LatLngBounds()

      if (oldLocation) {
        const oldLocationMarker = createMapMarker(new google.maps.LatLng(oldLocation), mapRef, redPin, bounds)
        mapData.current = [...mapData.current, oldLocationMarker]
      }

      if (newLocation) {
        const newLocationMarker = createMapMarker(new google.maps.LatLng(newLocation), mapRef, bluePin, bounds)
        mapData.current = [...mapData.current, newLocationMarker]
      }

      if (oldLocation && newLocation) {
        const path = createLocationChangedPath([oldLocation, newLocation], mapRef)
        mapData.current = [...mapData.current, path]
        mapRef.fitBounds(bounds)
      }
    }
  }, [mapRef, location])

  const onMapLoaded = ({ map, maps }: { map: google.maps.Map; maps: typeof google.maps }) => {
    if (map && maps) {
      setMapRef(map)
    }
  }

  return (
    <div className="mb-md" style={{ height: '15rem' }}>
      <Map
        zoom={11}
        center={{ lat: location.latitude as number, lng: location.longitude as number }}
        onMapLoaded={onMapLoaded}
      />
    </div>
  )
}

const createLocationChangedPath = (coordinates: { lat: number; lng: number }[], map: google.maps.Map) => {
  const lineSymbol = {
    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
  }

  const path = new google.maps.Polyline({
    path: coordinates,
    icons: [
      {
        icon: lineSymbol,
        offset: '100%'
      }
    ]
  })

  path.setMap(map)

  return path
}

const createMapMarker = (
  position: google.maps.LatLng,
  map: google.maps.Map,
  icon: string,
  bounds: google.maps.LatLngBounds
) => {
  const marker = new google.maps.Marker({ position, map, icon })
  bounds.extend(position)
  return marker
}

const getLocationCoordinates = (location: ExternalLocationType | undefined) => {
  return location?.latitude && location?.longitude ? { lat: location.latitude, lng: location.longitude } : null
}
