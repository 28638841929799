import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { AlertIcon } from '../../../../icons'

type Props = {
  url: string
  busy?: boolean
  size: 'small' | 'large'
}

export const CharityLogo: FunctionComponent<Props> = ({ url, busy, size }) => {
  const [error, setError] = useState<string>('')
  const [imageLoading, setImageLoading] = useState<boolean>(false)
  const imageRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    if (imageRef?.current?.naturalWidth === 0) {
      setImageLoading(true)
    } else {
      setImageLoading(false)
    }
  }, [imageRef, url])

  const loadingImageFailed = () => {
    if (url) {
      setImageLoading(false)
      setError('Cannot load image from url')
    }
  }

  return (
    <div className="relative group">
      <div
        className="flex items-center justify-center ring-2 ring-dust rounded overflow-hidden ml-auto mr-auto"
        style={{
          width: size === 'large' ? '200px' : '80px',
          height: size === 'large' ? '200px' : '80px',
          overflow: 'hidden'
        }}
      >
        {(imageLoading || busy) && !error && (
          <div
            className="absolute z-[1] rounded flex items-center justify-center bg-fog"
            style={{
              width: size === 'large' ? '200px' : '80px',
              height: size === 'large' ? '200px' : '80px',
              overflow: 'hidden'
            }}
          >
            <div className="loadingSpinner" />
          </div>
        )}

        {error ? (
          <div className="flex items-center justify-center text-red-dark px-md bg-white w-full h-full">
            <AlertIcon width="2rem" height="2rem" title={error} />
          </div>
        ) : (
          <img
            ref={imageRef}
            className={`object-cover transition ${imageLoading ? `opacity-0` : `opacity-100`}`}
            src={url}
            alt="Charity logo"
            onError={loadingImageFailed}
            onLoad={() => setImageLoading(false)}
          />
        )}

        {error && (
          <div className="group-hover:opacity-100 transition opacity-0 absolute w-12 bg-black-transparent text-white rounded-half p-sm z-10 -top-md left-1/2">
            {error}
          </div>
        )}
      </div>
    </div>
  )
}
