import React, { FunctionComponent, ReactNode } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import type { DropResult } from 'react-beautiful-dnd'

type Props = {
  items: ReactNode[]
  onOrderUpdated: (oldPosition: number, newPosition: number) => void
  labelledById: string
  isDisabled: boolean
}

export const DragDropList: FunctionComponent<Props> = ({ items, onOrderUpdated, labelledById, isDisabled }) => {
  const onDragEnd = (result: DropResult) => {
    if (!result.destination || isDisabled) {
      return // prevent error from dragging out of bounds
    }

    const oldPosition = result.source.index
    const newPosition = result.destination.index
    onOrderUpdated(oldPosition, newPosition)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="dropId">
        {provided => (
          <div aria-labelledby={labelledById} ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, i) => (
              <Draggable draggableId={`li-${i}`} index={i} key={`li-${i}`} isDragDisabled={isDisabled}>
                {drProvided => (
                  <div ref={drProvided.innerRef} {...drProvided.draggableProps} {...drProvided.dragHandleProps}>
                    {item}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
