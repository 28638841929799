import React, { ReactNode, FunctionComponent } from 'react'

type Props = {
  label: string | ReactNode
  ariaLabel?: string
  trailingIcon?: ReactNode
  onClick?: () => void
}

export const ListItem: FunctionComponent<Props> = ({ label, trailingIcon, onClick, ariaLabel }) => {
  return (
    <li className="pt-md pb-md pl-lg pr-lg cursor-pointer hover:bg-grey-light">
      <button className="flex align-middle text-left w-full" onClick={onClick} aria-label={ariaLabel}>
        <div className="flex-1">{label}</div>

        {trailingIcon && <div className="text-right">{trailingIcon}</div>}
      </button>
    </li>
  )
}
