import React, { useState } from 'react'
import { PromotedCampaign } from './PromotedCampaign'
import { DragDropList } from '../../../../common/DragDropList'
import { usePromotedCampaigns } from './PromotedCampaignsProvider'
import { ConfirmDialog } from '../../../../common'
import { PromotedCampaignActions } from './PromotedCampaignActions'
import { hasCharityEditPermission } from '../../../../../services/permission.service'

const canGoUp = (newPosition: number) => newPosition >= 0
const canGoDown = (listLength: number, newPosition: number) => {
  return newPosition <= listLength - 1
}

const getExistingListPosition = (list: CampaignSummaryType[], id: string) => list.findIndex(item => item.id === id)

const getNewListPosition = (list: CampaignSummaryType[], oldPosition: number, direction: 'up' | 'down'): number => {
  const desiredNewPosition = direction === 'up' ? oldPosition - 1 : oldPosition + 1

  const canReposition =
    (direction === 'up' && canGoUp(desiredNewPosition)) ||
    (direction === 'down' && canGoDown(list.length, desiredNewPosition))

  return canReposition ? desiredNewPosition : oldPosition
}

export const PromotedCampaignList = () => {
  const { promoted, updateItemOrder, updatingCampaign, removeCampaign } = usePromotedCampaigns()
  const [campaignToRemove, setCampaignToRemove] = useState<CampaignSummaryType | undefined>(undefined)

  const onRemoveCampaign = (campaignId: string) => {
    setCampaignToRemove(undefined)
    removeCampaign(campaignId)
  }

  const repositionItem = (campaignId: string, direction: 'up' | 'down') => {
    const oldPosition = getExistingListPosition(promoted, campaignId)
    const newPosition = getNewListPosition(promoted, oldPosition, direction)
    updateItemOrder(oldPosition, newPosition)
  }

  return (
    <>
      <DragDropList
        labelledById="promotedCampaignsTitle"
        onOrderUpdated={updateItemOrder}
        isDisabled={!hasCharityEditPermission()}
        items={promoted.map((campaign, i) => (
          <div className="flex" key={`c-${i}`}>
            <div className="flex-1">
              <PromotedCampaign key={`pr-${i}`} position={i + 1} campaign={campaign} />
            </div>

            {hasCharityEditPermission() && (
              <div className="flex justify-end">
                <PromotedCampaignActions
                  campaign={campaign}
                  disabled={updatingCampaign === campaign.id}
                  moveUp={campaignId => repositionItem(campaignId, 'up')}
                  moveDown={campaignId => repositionItem(campaignId, 'down')}
                  removeItem={() => setCampaignToRemove(campaign)}
                />
              </div>
            )}
          </div>
        ))}
      />

      {campaignToRemove && (
        <ConfirmDialog
          title={`Remove "${campaignToRemove.name}" from promoted?`}
          description="The campaign will be removed from the promoted list in myTOMRA"
          confirmButtonText="Remove"
          cancelButtonText="Cancel"
          onCancel={() => setCampaignToRemove(undefined)}
          onConfirm={() => onRemoveCampaign(campaignToRemove.id)}
        />
      )}
    </>
  )
}
