import * as localforage from 'localforage'
import { AuthStore, WebStorage } from '@tomra/client-side-authentication'
import { KEYCLOAK_HOST } from './environment'
import jwtDecode from 'jwt-decode'

export const storage: WebStorage = {
  setItem: localforage.setItem as (key: string, value: string) => Promise<void>,
  getItem: localforage.getItem as (key: string) => Promise<string>,
  removeItem: localforage.removeItem,
  clear: localforage.clear
}

export const authentication = new AuthStore(
  'TomraConnectUsers',
  'mytomra-hotline-tool-ui',
  KEYCLOAK_HOST,
  'en',
  storage
)

export const loggedInUserHasRole = (role: string) => {
  if (authentication.getToken()) {
    const { full_resource_access } = jwtDecode(authentication.getToken()) as { full_resource_access: string[] }
    return full_resource_access.includes(role)
  }

  return false
}
