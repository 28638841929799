import React, { FunctionComponent } from 'react'
import { Dialog } from '../Dialog'

type Props = {
  title: string
  description?: string
  confirmButtonText: string
  cancelButtonText: string
  onCancel: () => void
  onConfirm: () => void
}

export const ConfirmDialog: FunctionComponent<Props> = ({
  title,
  description,
  cancelButtonText,
  confirmButtonText,
  onCancel,
  onConfirm
}) => {
  return (
    <Dialog ariaLabel={title}>
      <div className="card p-lg min-w-screen-md">
        <h1 className="font-bold text-xl mb-lg">{title}</h1>
        {description && <p>{description}</p>}

        <div className="mt-lg flex space-x-md justify-end">
          <button className="btn" onClick={onCancel}>
            {cancelButtonText}
          </button>

          <button className="btn btn-primary-dark" onClick={onConfirm}>
            {confirmButtonText}
          </button>
        </div>
      </div>
    </Dialog>
  )
}
