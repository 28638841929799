import React, { FunctionComponent, ReactNode, useState } from 'react'
import { MinusIcon, PlusIcon } from '../../icons'

type Props = {
  title: string | ReactNode
  leadingElement?: ReactNode
  trailingElement?: ReactNode
  isOpen?: boolean
  children?: ReactNode
}

export const AccordionPanel: FunctionComponent<Props> = ({
  children,
  title,
  leadingElement,
  trailingElement,
  isOpen
}) => {
  const [panelIsOpen, setPanelIsOpen] = useState<boolean>(isOpen || false)

  return (
    <section className="card mb-lg">
      <h2>
        <button
          aria-expanded={panelIsOpen}
          className="text-left w-full p-lg"
          onClick={() => setPanelIsOpen(!panelIsOpen)}
        >
          <span className="flex space-x-md items-center justify-between">
            {leadingElement && <span className="">{leadingElement}</span>}
            <span className="text-md">{title}</span>
            <span className="flex-1" />
            {trailingElement && <span>{trailingElement}</span>}
            {panelIsOpen ? (
              <MinusIcon title="hide" width="1.5rem" height="1.5rem" />
            ) : (
              <PlusIcon title="show" width="1.5rem" height="1.5rem" />
            )}
          </span>
        </button>
      </h2>

      {panelIsOpen ? <div className="pr-lg pb-lg pl-lg">{children}</div> : null}
    </section>
  )
}
