import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { API_HOST, authenticatedFetchData } from '../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { Dialog } from '../../common/Dialog'

type Props = {
  email: string
  userId: string
  appContext: string
  onClose: () => void
}

export const SendPasswordDialog = ({ email, userId, appContext, onClose }: Props) => {
  const [status, setStatus] = useState<RequestStatusType>('idle')
  const [password] = useState(uuidv4().substring(0, 8))

  const enablePasswordLogin = async () => {
    setStatus('pending')

    try {
      await authenticatedFetchData(`${API_HOST}/mytomra/v1.0/hotlinetool/enable-user-password`, {
        method: 'POST',
        body: JSON.stringify({
          userId,
          password
        })
      }).run()
      setStatus('success')
    } catch (error: any) {
      setStatus('failure')
      logError(new Error('Failed to set password login on user'), error)
    }
  }

  return (
    <>
      <Dialog ariaLabel={`Changes ${status}`} onClose={onClose}>
        <div className="card p-lg flex flex-col gap-y-md pt-xl max-w-30">
          <h1 className="text-xl">Set user password</h1>

          {status === 'idle' ? (
            <>
              <p>
                Note! When logging into {appContext}, the user will be prompted to type in a password INSTEAD of
                receiving an email login link.
              </p>
              <p>Password will be set for the following user:</p>

              <p>
                <b>{email}</b>
              </p>

              <p>Are you sure? Once set, the user can&apos;t go back to email link based authentication.</p>
            </>
          ) : status === 'success' ? (
            <div>
              <p>The following password was set on the user:</p>

              <p className="p-md mt-md border-2 rounded-half bg-grey-light text-center text-lg">
                <b className="max-w-30 break-words text-green-dark">{password}</b>
              </p>
            </div>
          ) : null}

          {status === 'failure' && <div className="alert alert-danger">Whoopsie! Something went wrong.</div>}

          {status !== 'success' && (
            <button onClick={() => enablePasswordLogin()} className="btn btn-primary-dark ml-auto">
              {status === 'pending' ? <div className="loadingSpinner" /> : 'Continue'}
            </button>
          )}
        </div>
      </Dialog>
    </>
  )
}
