import React, { SyntheticEvent, useState } from 'react'
import { Dialog } from '../../common/Dialog'
import { authenticatedFetchData, API_HOST } from '../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { DateWithUTCTooltip } from '../../common'
import { UserSearch } from '../../common/UserSearch'

type Props = {
  closeDialog: () => void
  bagToLink: UnclaimedBagType
  bagLinkedSuccessFully: () => void
}

export const LinkUnclaimedBagDialog = ({ closeDialog, bagToLink, bagLinkedSuccessFully }: Props) => {
  const [user, setUser] = useState<UserInfoType>()
  const [linkUnclaimedBagStatus, setLinkUnclaimedBagStatus] = useState('')

  const linkUnclaimedBag = (e: SyntheticEvent) => {
    e.preventDefault()

    setLinkUnclaimedBagStatus('loading')

    const options = {
      method: 'POST',
      body: JSON.stringify({
        bagtag: bagToLink.bagtag,
        userId: user?.id,
        created: new Date().toISOString()
      })
    }

    authenticatedFetchData(API_HOST + '/mytomra/v1.0/hotlinetool/unclaimed', options)
      .run()
      .then(
        () => {
          setLinkUnclaimedBagStatus('success')

          window.setTimeout(() => {
            setLinkUnclaimedBagStatus('')
            bagLinkedSuccessFully()
          }, 2000)
        },
        (error: Error) => {
          setLinkUnclaimedBagStatus('failed')
          logError(new Error('Failed to link bag to user'), error)

          window.setTimeout(() => {
            setLinkUnclaimedBagStatus('')
          }, 2000)
        }
      )
  }

  return (
    <Dialog ariaLabel="Link bag to user" onClose={closeDialog} position="TOP">
      {linkUnclaimedBagStatus === 'loading' ? (
        <div className="loadingSpinner" />
      ) : linkUnclaimedBagStatus === 'failed' ? (
        <div className="alert alert-danger">Whoopsie! Failed to link bag to user</div>
      ) : linkUnclaimedBagStatus === 'success' ? (
        <div className="alert alert-success">Bag linked successfully!</div>
      ) : (
        <div className="card p-lg w-[80vw] mt-[5vh]">
          <h1 className="text-xl mb-md">Link bag to user</h1>

          <dl className="mb-md">
            <dt id="bagtag" className="mb-sm text-storm">
              Bagtag
            </dt>
            <dd aria-labelledby="bagtag">{bagToLink.bagtag}</dd>
          </dl>

          <dl className="mb-md">
            <dt id="date" className="mb-sm text-storm">
              Date
            </dt>
            <dd aria-labelledby="date">
              <DateWithUTCTooltip date={bagToLink.created} />
            </dd>
          </dl>

          <dl className="mb-md">
            <dt id="serial" className="mb-sm text-storm">
              Serial
            </dt>
            <dd aria-labelledby="serial">{bagToLink.serialNumber}</dd>
          </dl>

          <dl className="mb-md">
            <dt id="amount" className="mb-sm text-storm">
              Amount
            </dt>
            <dd aria-labelledby="amount">
              {bagToLink.amount.toFixed(2)} {bagToLink.currency}
            </dd>
          </dl>

          <dl className="mb-md">
            <dt id="location" className="mb-sm text-storm">
              Location
            </dt>
            <dd aria-labelledby="location">{bagToLink.location}</dd>
          </dl>

          {!user && (
            <div className="w-1/2">
              <UserSearch selectUser={user => setUser(user)} />
            </div>
          )}

          {user && (
            <>
              <div className="mt-md flex items-center space-x-md">
                <h2 className="text-lg">Selected user</h2>

                <button className="btn" onClick={() => setUser(undefined)}>
                  Change user
                </button>
              </div>

              <dl className="mb-md">
                <dt id="userId" className="mb-sm text-storm">
                  ID
                </dt>
                <dd aria-labelledby="userId">{user.id}</dd>
              </dl>

              <dl className="mb-md">
                <dt id="name">Name</dt>
                <dd aria-labelledby="name">{user.firstName}</dd>
              </dl>

              <dl className="mb-md">
                <dt id="email">Email</dt>
                <dd aria-labelledby="email">{user.email}</dd>
              </dl>

              <div className="flex space-x-md justify-end mt-lg">
                <button className="btn" onClick={() => closeDialog()}>
                  Cancel
                </button>

                <button className="btn btn-primary-dark" onClick={linkUnclaimedBag}>
                  {`Link bag to ${user.firstName}`}
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </Dialog>
  )
}
