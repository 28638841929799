import React, { FunctionComponent, useContext, useState } from 'react'
import { CharityReviewContext } from '../../../../../contexts'
import { API_HOST, authenticatedFetchData } from '../../../../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { FormField } from '../../../../../common'
import { EditableApplicationSection } from './EditableApplicationSection'

const dgrStatus = {
  NONE: 'None',
  RESTRICTED: 'Restricted',
  UNRESTRICTED: 'Unrestricted'
}

type Props = {
  charityId: string
}

export const BankingDataSection: FunctionComponent<Props> = ({ charityId }) => {
  const title = 'Banking data'
  const { charity, setCharity, setEditingSection, attachments } = useContext(CharityReviewContext)
  const [status, setStatus] = useState<RequestStatusType>('idle')

  const { bankingData } = charity
  const bankingDocuments: CharityFileAttachmentType['type'][] = ['ProofOfBankDetails']

  const onUpdate = (data: { [key: string]: any }) => {
    setStatus('pending')

    authenticatedFetchData(`${API_HOST}/charities/admin/v1/charities/${charityId}/banking_data`, {
      method: 'PUT',
      body: JSON.stringify(data)
    })
      .run()
      .then((response: BankingDataType) => {
        setStatus('idle')
        setCharity({ ...charity, bankingData: response })
      })
      .catch((error: any) => {
        logError(new Error('Failed to update banking data'), error)
        setStatus('failure')
      })

    setEditingSection('')
  }

  const formFields: FormField[] = [
    { label: 'Account name', name: 'accountName', required: true },
    { label: 'BSB', name: 'accountBsb', required: true },
    { label: 'Account number', name: 'accountNumber', required: true },
    { label: 'DGR status', name: 'dgrStatus', required: true, options: dgrStatus }
  ]

  const isBankingDocument = (doc: CharityFileAttachmentType) => bankingDocuments.indexOf(doc.type) !== -1

  return (
    <>
      <EditableApplicationSection
        title={title}
        formFields={formFields}
        initialValues={bankingData}
        onUpdate={onUpdate}
        isUpdating={status === 'pending'}
        attachments={attachments.filter(isBankingDocument)}
        documentTypes={bankingDocuments}
      />
      {status === 'failure' && <div className="alert alert-danger">Failed to update section. Please try again.</div>}
    </>
  )
}
