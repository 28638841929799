import React, { FunctionComponent } from 'react'
import { BROWSER_TIMEZONE } from '../../lib'
import { DateWithUTCTooltip } from './DateWithUTCTooltip'

type Props = {
  pendingBags: PendingBagType[]
}

export const PendingBagsTable: FunctionComponent<Props> = ({ pendingBags }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Bagtag</th>
          <th>Created ({BROWSER_TIMEZONE})</th>
        </tr>
      </thead>
      <tbody>
        {pendingBags.map(({ bagtag, created }) => (
          <tr key={bagtag}>
            <td>{bagtag}</td>
            <td>
              <DateWithUTCTooltip date={created} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
