import React, { useState } from 'react'
import { API_HOST, authenticatedFetchData } from '../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { Dialog } from '../../common/Dialog'

type Props = {
  userId: string
  appContext: string
  onClose: () => void
}

export const CreateLoginLinkDialog = ({ userId, appContext, onClose }: Props) => {
  const [status, setStatus] = useState<RequestStatusType | 'password-enabled'>('idle')
  const [loginLink, setLoginLink] = useState('')
  const [copied, setCopied] = useState<boolean>(false)

  const createLoginLink = async () => {
    try {
      setStatus('pending')
      const signInLink = await authenticatedFetchData(
        `${API_HOST}/mytomra/v1.0/hotlinetool/create-signin-link?userId=${encodeURIComponent(userId)}`,
        {
          method: 'POST'
        }
      ).run()
      setLoginLink(signInLink)
      setStatus('success')
    } catch (error: any) {
      if (error.status === 412) {
        setStatus('password-enabled')
      } else {
        setStatus('failure')
        logError(new Error('Failed to create login link'), error)
      }
    }
  }

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(loginLink)
      .then(() => {
        setCopied(true)
        setTimeout(() => setCopied(false), 3000)
      })
      .catch(() => {})
  }

  return (
    <>
      <Dialog ariaLabel={`Changes ${status}`} onClose={onClose}>
        <div className="card p-lg flex flex-col gap-y-lg">
          <h1 className="text-xl">Create login link</h1>

          {status === 'idle' ? (
            <p>Create a login link for the user to sign in to {appContext}.</p>
          ) : status === 'success' ? (
            <div className="space-y-lg">
              <p>Great! You can now forward the following link to the user:</p>

              <p className="p-md border-2 rounded-half flex items-center space-x-lg bg-grey-light">
                <b className="max-w-30 break-words text-green-dark">{loginLink}</b>

                <button className="btn" disabled={copied} onClick={copyToClipboard}>
                  {copied ? 'Copied!' : 'Copy'}
                </button>
              </p>
            </div>
          ) : status === 'failure' ? (
            <div className="alert alert-danger">Whoopsie! Something went wrong.</div>
          ) : status === 'password-enabled' ? (
            <div className="alert alert-warning">
              The user has a password login enabled. Please ask the user to sign in with their password or generate a
              new one.
            </div>
          ) : null}

          {status !== 'success' && status !== 'password-enabled' && (
            <button className="btn btn-primary-dark self-end" onClick={createLoginLink}>
              {status === 'pending' ? <div className="loadingSpinner" /> : 'Create link'}
            </button>
          )}
        </div>
      </Dialog>
    </>
  )
}
