import React, { FunctionComponent } from 'react'
import returnAndEarnLogo from '../../../../images/returnAndEarnWhite.svg'
import cdsVicWestLogo from '../../../../images/cdsvicwest.svg'
import recycleRewardsLogo from '../../../../images/recycleRewards.png'

const stateSchemeMap: { [key: string]: string } = {
  NSW: returnAndEarnLogo,
  VIC: cdsVicWestLogo,
  TAS: recycleRewardsLogo
}

type Props = {
  state: string
}

export const SchemeAppLogo: FunctionComponent<Props> = ({ state }) => {
  return (
    <div className="w-[3.3rem] h-[3.3rem] rounded overflow-hidden flex items-center justify-center ring-2 ring-fog bg-white">
      <img className="p-sm" src={stateSchemeMap[state]} alt={`${state} scheme logo`} />
    </div>
  )
}
