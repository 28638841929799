import React, { FunctionComponent, useState } from 'react'
import { API_HOST, authenticatedFetchData } from '../../../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { Dialog } from '../../../../common/Dialog'
import { CheckmarkIcon } from '../../../../icons'

type Props = {
  charityId: string
  campaignId?: string
  onClose: () => void
}

export const RejectPendingChangesButton: FunctionComponent<Props> = ({ charityId, campaignId, onClose }) => {
  const [status, setStatus] = useState<RequestStatusType | 'rejected'>('idle')

  const approveChanges = () => {
    setStatus('pending')

    const url = campaignId
      ? `${API_HOST}/charities/admin/v1/charities/${charityId}/campaign/change/${campaignId}/reject`
      : `${API_HOST}/charities/admin/v1/charities/change/${charityId}/reject`

    authenticatedFetchData(url, { method: 'DELETE' })
      .run()
      .then(() => setStatus('rejected'))
      .catch(error => {
        logError(new Error(`Unable to reject changes`), error)
        setStatus('failure')
      })
  }

  return (
    <>
      {status === 'failure' && (
        <div className="absolute left-lg pt-sm font-bold text-red-dark">Something went wrong</div>
      )}

      <button type="button" className="btn btn-primary-danger flex items-center" onClick={approveChanges}>
        {status === 'pending' ? <div className="loadingSpinner" /> : 'Reject'}
      </button>

      {status === 'rejected' && (
        <Dialog ariaLabel="Changes rejected">
          <div className="card p-lg flex flex-col gap-y-md items-center pt-xl" style={{ minWidth: '30rem' }}>
            <CheckmarkIcon color="var(--colors-green)" width="6rem" height="6rem" />
            <h1 className="text-xl">Done!</h1>
            <p>Changes were rejected.</p>

            <button className="btn self-end" onClick={onClose}>
              Close
            </button>
          </div>
        </Dialog>
      )}
    </>
  )
}
