import React, { FunctionComponent } from 'react'
import { locationHasInvalidCoordinates } from '../locationHelpers'
import { LocationIcon } from '../../../icons'

type Props = {
  locations: ExternalLocationType[]
}

export const ExternalLocationsInvalidCoordinatesWarning: FunctionComponent<Props> = ({ locations }) => {
  const locationsWithInvalidCoordinates = locations.filter(locationHasInvalidCoordinates)
  const numLocationsWithInvalidCoordinates = locationsWithInvalidCoordinates.length
  const locationMsg = numLocationsWithInvalidCoordinates === 1 ? 'location is' : 'locations are'

  return numLocationsWithInvalidCoordinates > 0 ? (
    <div className="group absolute top-sm w-[80%] left-1/2 -translate-x-1/2 p-md border-2 border-yellow rounded-half font-bold bg-[rgba(255,255,255,0.9)] z-[2] flex items-center justify-center cursor-pointer">
      <LocationIcon width="1.5rem" height="1.5rem" />
      <div className="ml-sm">
        {numLocationsWithInvalidCoordinates} {locationMsg} not displayed on the map due to invalid/missing coordinates.
      </div>
      <div className="absolute top-[3.5rem] left-0 w-full py-sm px-md text-white rounded-half bg-[rgba(0,0,0,0.8)] transform translate-y-[20%] opacity-0 text-left pointer-events-none transition group-hover:translate-y-[0] group-hover:opacity-100">
        {locationsWithInvalidCoordinates.map(location => (
          <span key={`error-${location.name}`} className="block">
            {location.name}
          </span>
        ))}
      </div>
    </div>
  ) : null
}
