import React from 'react'
import { useLoaderData } from 'react-router-dom'
import type { LoaderFunction } from 'react-router-dom'
import { DateWithUTCTooltip } from '../common'
import { authenticatedFetchData, API_HOST, BROWSER_TIMEZONE } from '../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { PayoutsTable } from '../common/PayoutsTable'
import { Notes } from '../common/notes/Notes'

export const consumerSessionLoader: LoaderFunction = async ({ params }) => {
  try {
    const { dpuid } = params

    const consumerSessionInfo = await authenticatedFetchData(
      `${API_HOST}/mytomra/v1.0/hotlinetool/cs?dpuid=${encodeURIComponent(dpuid || '')}`
    ).run()
    return { consumerSessionInfo }
  } catch (error: any) {
    logError(new Error('Failed to fetch CS info'), error)
    return { error }
  }
}

type ConsumerSessionInfo = {
  consumerSession: any
  payouts: any[]
}

export const ConsumerSessionPage = () => {
  const { consumerSessionInfo, error } = useLoaderData() as { consumerSessionInfo?: ConsumerSessionInfo; error?: any }

  if (error || !consumerSessionInfo) {
    return (
      <div>
        <h1 className="sr-only">Consumer session</h1>
        <div className="alert alert-danger centerAbsolute">
          Whoopsie! Failed to find consumer session info. Please try again.
        </div>
      </div>
    )
  }

  const { consumerSession, payouts } = consumerSessionInfo
  const { created, dpuid, itemCount, refund, currency, rvmSerial, latestStatus, voucherBarcode, errorCategory } =
    consumerSession

  return (
    <div className="p-lg">
      <h1 className="text-xl mb-md">Consumer session</h1>

      <div className="flex">
        <div className="mr-sm w-1/4">
          <div className="card p-lg mb-lg">
            <dl className="mb-md">
              <dt id="dpuid" className="mb-sm text-storm">
                DPUID
              </dt>
              <dd aria-labelledby="dpuid">{dpuid}</dd>
            </dl>

            <dl className="mb-md">
              <dt id="date" className="mb-sm text-storm">
                Date ({BROWSER_TIMEZONE})
              </dt>
              <dd aria-labelledby="date">
                <DateWithUTCTooltip date={created} />
              </dd>
            </dl>

            <dl className="mb-md">
              <dt id="rvmSerial" className="mb-sm text-storm">
                RVM Serial
              </dt>
              <dd aria-labelledby="rvmSerial">{rvmSerial}</dd>
            </dl>

            <dl className="mb-md">
              <dt id="itemCount" className="mb-sm text-storm">
                Item count
              </dt>
              <dd aria-labelledby="itemCount">{itemCount}</dd>
            </dl>

            <dl className="mb-md">
              <dt id="refund" className="mb-sm text-storm">
                Refund
              </dt>
              <dd aria-labelledby="refund">
                {refund && refund.toFixed(2)} {currency}
              </dd>
            </dl>

            {voucherBarcode && (
              <dl className="mb-md">
                <dt id="voucherBarcode" className="mb-sm text-storm">
                  Voucher barcode
                </dt>
                <dd aria-labelledby="voucherBarcode">{voucherBarcode}</dd>
              </dl>
            )}

            <dl className="mb-md">
              <dt id="status" className="mb-sm text-storm">
                Latest status
              </dt>
              <dd aria-labelledby="status">
                {latestStatus}
                {errorCategory && <span> ({errorCategory}))</span>}
              </dd>
            </dl>
          </div>

          <Notes sourceId={dpuid} />
        </div>

        <div className="ml-sm w-3/4">
          <div className="card">
            <h2 className="inline-block ml-lg my-md text-lg">Payouts</h2>

            <PayoutsTable payouts={payouts} />
          </div>
        </div>
      </div>
    </div>
  )
}
