import React from 'react'
import { authentication } from './lib'
import { App } from './App'
import '@tomra/design-system/src/config/tailwind.config.css'
import './styles.css'
import { createRoot } from 'react-dom/client'

const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container)

const Index = () => (
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

authentication.init().then(
  () => root.render(<Index />),
  () => authentication.login()
)
