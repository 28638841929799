import { API_HOST, authenticatedFetchData } from '../lib'
import { uploadFile } from './file.service'

export const fetchExternalLocations = () => {
  return authenticatedFetchData(`${API_HOST}/mytomra/v1.0/manager/externallocations`)
}

export const uploadExternalLocationsCSV = async (file: File): Promise<string> => {
  const { url, fileId } = await authenticatedFetchData(
    `${API_HOST}/mytomra/v1.0/manager/externallocations/upload`
  ).run()
  await uploadFile(url, file)
  return fileId
}

export const fetchExternalLocationDiff = (fileId: string): Promise<ExternalLocationDiffResponseType> => {
  return authenticatedFetchData(`${API_HOST}/mytomra/v1.0/manager/externallocations/diff/${fileId}`).run()
}

export const applyExternalLocationDiff = (fileId: string, diff: ExternalLocationDiffType) => {
  return authenticatedFetchData(`${API_HOST}/mytomra/v1.0/manager/externallocations/apply/${fileId}`, {
    method: 'PUT',
    body: JSON.stringify({ data: diff })
  }).run()
}

export const rejectExternalLocationDiff = (fileId: string) => {
  return authenticatedFetchData(`${API_HOST}/mytomra/v1.0/manager/externallocations/reject/${fileId}`, {
    method: 'PUT'
  }).run()
}
