import { useCallback, useState } from 'react'
import { authenticatedFetchData } from '../lib'
import { logError } from '@tomra/datadog-browser-logging'

export const usePagination = (limit: number) => {
  const [status, setStatus] = useState<RequestStatusType>('idle')
  const [offset, setOffset] = useState<number>(0)
  const [canGoNext, setCanGoNext] = useState<boolean>(false)
  const [results, setResults] = useState<any[]>([])
  const [error, setError] = useState<string>('')

  const canGoBack = offset > 0 && results.length > 0

  const clearResults = useCallback(() => setResults([]), [])

  const search = useCallback(
    (url: string, newOffset: number) => {
      setStatus('pending')
      setOffset(newOffset)

      const searchUrl = new URL(url)
      searchUrl.searchParams.append('limit', (limit + 1).toString())
      searchUrl.searchParams.append('offset', newOffset.toString())

      authenticatedFetchData(searchUrl.toString())
        .run()
        .then((results: any[]) => {
          setStatus('idle')

          const hasMoreResults = results.length > limit
          setCanGoNext(hasMoreResults)

          if (hasMoreResults) {
            setResults(results.slice(0, results.length - 1))
          } else {
            setResults(results)
          }
        })
        .catch(error => {
          logError(new Error('Failed to load data'), error)
          setError('Something went wrong. Please try again.')
          setStatus('failure')
        })
    },
    [limit]
  )

  return {
    status,
    results,
    offset,
    error,
    canGoNext,
    canGoBack,
    search,
    clearResults
  }
}
