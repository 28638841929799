export const uploadFile = (preSignedUrl: string, file: Blob | File) =>
  fetch(preSignedUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type
    },
    body: file
  }).then(response => {
    if (!response.ok) {
      return Promise.reject('Upload file failed')
    }

    return response
  })
