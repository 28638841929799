import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { DateWithUTCTooltip } from '../common'
import { authenticatedFetchData, API_HOST } from '../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { CSVLink } from 'react-csv'
import { convertCSVObjectsToReadable } from '../../lib/convertCSVObjectsToReadable'

const HEADERS = [
  { label: 'Bagtag', key: 'bagtag' },
  { label: 'Created', key: 'created' },
  { label: 'UserId', key: 'userId' }
]

const DATEOPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit'
}

export const PendingBagsPage = () => {
  const [pendingBags, setPendingBags] = useState<PendingBagType[]>([])
  const [findPendingBagsStatus, setFindPendingBagsStatus] = useState<string>('loading')
  const [csvData, setCSVData] = useState<any[]>([])

  useEffect(() => {
    const f = authenticatedFetchData(API_HOST + '/mytomra/v1.0/hotlinetool/pending')

    f.run().then(
      (pendingBags: PendingBagType[]) => {
        setFindPendingBagsStatus('')
        setPendingBags(pendingBags)
        setCSVData(pendingBags.map(convertCSVObjectsToReadable))
      },
      (error: Error) => {
        if (error.name !== 'AbortError') {
          setFindPendingBagsStatus('failed')
          logError(new Error('Failed to fetch pending bags'), error)
        }
      }
    )

    return () => f.abort()
  }, [])

  if (findPendingBagsStatus === 'loading') {
    return (
      <div className="centerAbsolute flex flex-col items-center space-y-md">
        <div className="loadingSpinner" />
        <p>This may take a while...</p>
      </div>
    )
  }

  if (findPendingBagsStatus === 'failed') {
    return (
      <div>
        <h1 className="sr-only">Pending bags</h1>
        <div className="alert alert-danger centerAbsolute">Whoopsie! Failed to pending bags. Please try again.</div>
      </div>
    )
  }

  return (
    <div className="p-lg">
      <div className="flex items-center justify-between mb-md">
        <h1 className="text-xl">Pending bags</h1>

        <CSVLink
          data={csvData}
          separator=";"
          headers={HEADERS}
          filename={'tomra_pendingBags_' + new Date().toLocaleString('en-us', DATEOPTIONS) + '.csv'}
        >
          <div className="btn btn-primary-dark flex items-center">Export</div>
        </CSVLink>
      </div>

      <div className="card">
        <table className="table">
          <thead>
            <tr>
              <th>Bagtag</th>
              <th>Created</th>
              <th>User ID</th>
            </tr>
          </thead>
          <tbody>
            {pendingBags.map(({ bagtag, userId, created }) => (
              <tr key={bagtag}>
                <td>{bagtag}</td>
                <td>
                  <DateWithUTCTooltip date={created} />
                </td>
                <td>
                  <Link to={'/user/' + userId}>{userId}</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
