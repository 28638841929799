import React, { FunctionComponent, useEffect, useState } from 'react'
import { PendingChangesReviewButton } from '../../common/pending-changes/PendingChangesReviewButton'
import { API_HOST, authenticatedFetchData } from '../../../../../lib'
import { logError } from '@tomra/datadog-browser-logging'

type Props = {
  charityId: string
  campaignId?: string
  mergeChanges: (changesToMerge: CharityType | CharityCampaignType) => void
}

export const HasPendingChangesBanner: FunctionComponent<Props> = ({ charityId, campaignId, mergeChanges }) => {
  const [showButton, setShowButton] = useState(false)

  useEffect(() => {
    const { run, abort } = campaignId
      ? authenticatedFetchData(`${API_HOST}/charities/admin/v1/charities/${charityId}/pending-campaigns/${campaignId}`)
      : authenticatedFetchData(`${API_HOST}/charities/admin/v1/pending-charities/${charityId}`)

    run()
      .then(() => setShowButton(true))
      .catch(error => {
        if (error.name !== 'AbortError' && error.status !== 404) {
          logError(new Error('Unable to fetch pending changes'), error)
        }
      })

    return () => abort()
  }, [])

  return showButton ? (
    <div className="alert alert-warning mb-lg flex justify-between items-center">
      <div>There are changes waiting for approval</div>

      <PendingChangesReviewButton
        charityId={charityId}
        campaignId={campaignId}
        onComplete={changesToMerge => {
          setShowButton(false)

          if (changesToMerge) {
            mergeChanges(changesToMerge)
          }
        }}
      />
    </div>
  ) : null
}
