import React, { FunctionComponent } from 'react'
import { ErrorBoundary } from '../../../../../../ErrorBoundary'
import { charityValueMap } from '../../charity'
import { DiffRow } from './DiffRow'

type Props = {
  diff: any
}

export const Diff: FunctionComponent<Props> = ({ diff }) => {
  return (
    <ErrorBoundary>
      <div className="relative min-w-screen-md max-w-screen-xl overflow-scroll" style={{ maxHeight: '80vh' }}>
        <h1 className="sticky top-0 bg-white text-xl mb-md">Review changes</h1>

        {Object.keys(diff).length === 0 && <div>No changes to review.</div>}

        <div className="flex flex-col space-y-md">
          {Object.keys(diff).map(sectionName => {
            const field = diff[sectionName as keyof (CharityType | CharityCampaignType)]

            return field.oldValue || field.newValue ? (
              <DiffRow
                key={`section-${sectionName}`}
                fieldName={charityValueMap[sectionName]}
                oldValue={field.oldValue}
                newValue={field.newValue}
              />
            ) : (
              <div key={`section-${sectionName}`}>
                <h2 className="text-md text-storm mb-sm">{charityValueMap[sectionName]}</h2>

                {Object.keys(field).map((propertyName, i) => (
                  <ul key={`prop-${i}`}>
                    <li>
                      <DiffRow
                        fieldName={charityValueMap[propertyName]}
                        oldValue={field[propertyName].oldValue}
                        newValue={field[propertyName].newValue}
                      />
                    </li>
                  </ul>
                ))}
              </div>
            )
          })}
        </div>
      </div>
    </ErrorBoundary>
  )
}
