import React, { FunctionComponent } from 'react'
import { locationHasInvalidCoordinates } from '../locationHelpers'
import { ArrowRightIcon, LocationIcon } from '../../../icons'

type Props = {
  ariaLabel: string
  locations: ExternalLocationType[]
  selectedLocation?: ExternalLocationType
  onLocationClicked: (location: ExternalLocationType) => void
}

export const ExternalLocationsList: FunctionComponent<Props> = ({
  ariaLabel,
  locations,
  selectedLocation,
  onLocationClicked
}) => {
  const locationIsSelected = (location: ExternalLocationType) => selectedLocation?.externalId === location.externalId

  return (
    <ul aria-label={ariaLabel}>
      {locations.map((location: ExternalLocationType) => (
        <li key={location.externalId} className="relative odd:bg-grey-light">
          <button
            aria-label={location.name}
            className={
              locationIsSelected(location)
                ? 'p-md text-left w-full outline-none bg-fog'
                : 'p-md text-left w-full outline-none focus:ring-4 focus:ring-grey focus:ring-opacity-50'
            }
            onClick={() => onLocationClicked(location)}
          >
            <div className="flex space-x-md items-center">
              <div className="flex-1">
                <div className="font-bold mb-xs">{location.name}</div>
                <div className="text-sm">
                  {location.address}, {location.city} {location.state}, {location.zipCode}
                </div>
              </div>

              {location?.oldValue ? (
                <ArrowRightIcon width="1rem" height="1rem" />
              ) : location.lastUpdated ? (
                <div className="text-sm">{new Date(location.lastUpdated).toLocaleDateString()}</div>
              ) : location.created ? (
                <div className="text-sm">{new Date(location.created).toLocaleDateString()}</div>
              ) : null}

              {locationHasInvalidCoordinates(location) && (
                <div className="mt-xs group">
                  <span className="absolute top-0 right-sm py-sm px-md text-white rounded-half bg-[rgba(0,0,0,0.8)] transform translate-y-[20%] transition opacity-0 pointer-events-none group-hover:opacity-100 group-hover:translate-y-[0]">
                    {location.name} has invalid or missing coordinates and will not be shown on the map
                  </span>
                  <LocationIcon width="1.5rem" height="1.5rem" color="var(--colors-red)" />
                </div>
              )}
            </div>
          </button>
        </li>
      ))}
    </ul>
  )
}
