import React, { useState, useEffect, FunctionComponent } from 'react'
import { authenticatedFetchData, API_HOST } from '../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { PayoutsTable } from '../../common/PayoutsTable'
import { useParams } from 'react-router-dom'

export const UserPayouts: FunctionComponent = () => {
  const { userId } = useParams<{ userId: string }>()
  const [userPayoutList, setUserPayoutList] = useState<PayoutType[]>([])
  const [requestStatus, setRequestStatus] = useState<string>('loading')
  const [limit, setLimit] = useState<number>(25)
  const canPotentiallyFetchMore = userPayoutList.length === limit

  const fetchMore = () => setLimit(limit + 25)

  useEffect(() => {
    limit > 25 ? setRequestStatus('loadingMore') : setRequestStatus('loading')

    const f = authenticatedFetchData(
      `${API_HOST}/mytomra/v1.0/hotlinetool/userpayout?userid=${encodeURIComponent(userId || '')}&limit=${limit}`
    )

    f.run().then(
      (result: PayoutType[]) => {
        setUserPayoutList(result)
        setRequestStatus('')
      },
      (error: Error) => {
        if (error.name !== 'AbortError') {
          setRequestStatus('failed')
          logError(new Error('Failed to fetch user payouts'), error)
        }
      }
    )

    return () => f.abort()
  }, [userId, limit])

  if (requestStatus === 'loading') {
    return (
      <div className="centerAbsolute">
        <div className="loadingSpinner" />
      </div>
    )
  }

  if (requestStatus === 'failed') {
    return <div className="alert alert-danger centerAbsolute">Whoopsie! Failed to fetch user payouts.</div>
  }

  return (
    <>
      <PayoutsTable payouts={userPayoutList} />

      <div className="flex justify-center py-lg">
        <button className="btn btn-primary-dark" disabled={!canPotentiallyFetchMore} onClick={fetchMore}>
          {requestStatus === 'loadingMore' ? <div className="loadingSpinner" /> : 'More'}
        </button>
      </div>
    </>
  )
}
