import React, { FormEvent, FunctionComponent, useState } from 'react'
import { usePagination } from '../../hooks/usePagination'
import { PaginationButtons } from './PaginationButtons'

export type PaginatedSearchResultItemProps = {
  result: any
  onItemClicked: () => void
}

type Props = {
  id: string
  label: string
  limit: number
  searchUrl: string
  ItemRenderer: FunctionComponent<PaginatedSearchResultItemProps>
  searchLabelId: string
}

export const PaginatedSearch: FunctionComponent<Props> = ({
  id,
  label,
  limit,
  searchUrl,
  ItemRenderer,
  searchLabelId
}) => {
  const {
    status,
    search,
    offset,
    canGoBack,
    canGoNext,
    results: searchResults,
    clearResults,
    error
  } = usePagination(limit)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [hasSearched, setHasSearched] = useState<boolean>(false)

  const isDisabled = status === 'pending' || !searchTerm
  const showPaginationButtons = (searchResults && searchResults.length > 0 && canGoNext) || canGoBack
  const noResults = hasSearched && searchResults.length === 0 && status !== 'pending'

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    onSearch(0)
  }

  const onSearch = (newOffset = 0) => {
    setHasSearched(true)

    const url = new URL(searchUrl)
    url.searchParams.append('name', searchTerm)
    search(url.toString(), newOffset)
  }

  const onChange = (value: string) => {
    if (value === '') {
      setHasSearched(false)
      clearResults()
    }
    setSearchTerm(value)
  }

  return (
    <>
      <form aria-label={label} className="pr-md pb-md pl-lg" onSubmit={onSubmit}>
        <label htmlFor={id} className="block mb-sm">
          {label}
        </label>

        <div className="flex">
          <input
            id={id}
            type="text"
            className="max-w-full mr-md"
            value={searchTerm}
            onChange={({ target: { value } }) => onChange(value)}
          />

          <button type="submit" className="btn w-2/4" disabled={isDisabled}>
            Search
          </button>
        </div>
      </form>

      {status === 'pending' && searchResults?.length === 0 ? (
        <div className="flex justify-center">
          <div className="loadingSpinner" />
        </div>
      ) : null}

      {error && (
        <div className="pr-md pb-md pl-lg">
          <div className="alert alert-danger">{error}</div>
        </div>
      )}

      {noResults && <div className="pr-md pb-md pl-lg">No results matched `&quot;{searchTerm}`&quot;</div>}

      {searchResults?.length > 0 && (
        <ul aria-labelledby={searchLabelId}>
          {searchResults.map((result, i) => (
            <ItemRenderer key={`result-${i}`} result={result} onItemClicked={() => clearResults()} />
          ))}
        </ul>
      )}

      {showPaginationButtons && (
        <PaginationButtons
          isLoading={status === 'pending'}
          limit={limit}
          offset={offset}
          onOffsetChange={newOffset => onSearch(newOffset)}
          canGoBack={canGoBack}
          canGoNext={canGoNext}
        />
      )}
    </>
  )
}
