import React, { useState } from 'react'
import { API_HOST, authenticatedFetchData, emailRegex } from '../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { Dialog } from '../../common/Dialog'

type Props = {
  userId: string
  onClose: () => void
}

export const UpdateEmailDialog = ({ userId, onClose }: Props) => {
  const [status, setStatus] = useState<RequestStatusType | 'validation-failed' | 'email-in-use'>('idle')

  const updateEmail = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    setStatus('pending')

    try {
      const formData = new FormData(evt.target as HTMLFormElement)
      const newEmail = formData.get('newEmail')?.toString() || ''
      const isValidEmail = !!new RegExp(emailRegex).exec(newEmail)

      if (!isValidEmail) {
        setStatus('validation-failed')
        return
      }

      await authenticatedFetchData(
        `${API_HOST}/mytomra/v1.0/hotlinetool/update-user-email?userId=${encodeURIComponent(userId)}&email=${encodeURIComponent(newEmail)}`,
        {
          method: 'POST'
        }
      ).run()
      setStatus('success')
    } catch (error: any) {
      if (error.status === 409) setStatus('email-in-use')
      else setStatus('failure')
      logError(new Error('Failed to set password login on user'), error)
    }
  }

  return (
    <>
      <Dialog ariaLabel={`Changes ${status}`} onClose={onClose}>
        <div className="card p-lg flex flex-col gap-y-md pt-xl max-w-30">
          <h1 className="text-xl">Update user email</h1>
          <p>
            The email wont be updated until the user completes verification by clicking on the verification link sent to
            the users new email address.
          </p>

          <form onSubmit={updateEmail} className="flex flex-col gap-y-md">
            <label htmlFor="newEmail">New email</label>
            <input
              type="email"
              id="newEmail"
              name="newEmail"
              placeholder="New email"
              autoComplete="email"
              title="Must be a valid email address"
              className="mb-sm"
              required
            />

            {status === 'success' ? (
              <div className="alert alert-success">Success! A verification email has been sent to the user.</div>
            ) : status === 'failure' ? (
              <div className="alert alert-danger">Whoopsie! Something went wrong.</div>
            ) : status === 'validation-failed' ? (
              <div className="alert alert-warning">Must be a valid email address.</div>
            ) : status === 'email-in-use' ? (
              <div className="alert alert-danger">New email is already in use.</div>
            ) : null}

            <button
              type="submit"
              className="btn btn-primary-dark ml-auto"
              disabled={status === 'pending' || status === 'success'}
              aria-disabled={status === 'pending' || status === 'success'}
            >
              {status === 'pending' ? <div className="loadingSpinner" /> : 'Confirm'}
            </button>
          </form>
        </div>
      </Dialog>
    </>
  )
}
