import { API_HOST, authenticatedFetchData } from '../../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import React, { useState } from 'react'

export const CharityReportButton = () => {
  const [status, setStatus] = useState<RequestStatusType>('idle')

  const createBlob = (data: string) => new Blob([data], { type: 'text/csv;charset=utf-8;' })

  const triggerDownload = (blob: Blob) => {
    try {
      const link = document.createElement('a')

      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', `charity_contact_list.csv`)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    } catch (e) {
      throw new Error(`Error while triggering charity report download`)
    }
  }

  const getReportData = () => {
    setStatus('pending')

    authenticatedFetchData(`${API_HOST}/charities/admin/v1/charities-contact-list`)
      .run()
      .then(createBlob)
      .then(triggerDownload)
      .then(() => setStatus('idle'))
      .catch((error: any) => {
        setStatus('failure')
        logError(new Error(`Unable to download charity report`), error)
      })
  }

  return (
    <div className="flex flex-col items-end">
      <button className="btn btn-primary-dark" onClick={getReportData}>
        {status === 'pending' ? <span data-testid="spinner" className="loadingSpinner" /> : 'Download report'}
      </button>

      {status === 'failure' && (
        <div className="mt-sm alert alert-danger">An error occurred while processing report data</div>
      )}
    </div>
  )
}
