import React, { FunctionComponent, useState } from 'react'
import { ViewAttachment } from './ViewAttachment'
import { CheckmarkIcon } from '../../../../../icons'
import { UploadAttachment } from './UploadAttachment'
import { hasCharityEditPermission } from '../../../../../../services/permission.service'

type Props = {
  charityId: string
  onReviewed: () => void
  attachments: CharityFileAttachmentType[]
  documentTypes: CharityFileAttachmentType['type'][]
}

const icon = <CheckmarkIcon width="1.5rem" height="1.5rem" color="var(--colors-black)" />
const approvedIcon = <CheckmarkIcon width="1.5rem" height="1.5rem" color="var(--colors-green)" />

export const SectionAttachments: FunctionComponent<Props> = ({ charityId, onReviewed, attachments, documentTypes }) => {
  const [reviewed, setReviewed] = useState<CharityFileAttachmentType['type'][]>([])
  const [activeTab, setActiveTab] = useState<CharityFileAttachmentType['type'] | undefined>(undefined)

  const onViewed = (type: CharityFileAttachmentType['type']) => {
    const isReviewed = reviewed.indexOf(type) !== -1

    if (!isReviewed) {
      const newState = [...reviewed, type]
      setReviewed(newState)

      if (newState.length === attachments.length) {
        onReviewed()
      }
    }
  }

  return (
    <div className="my-xl">
      <h3 className="mb-md font-bold text-storm">Attachments</h3>

      <div className="flex flex-col space-y-lg">
        {documentTypes.map(type => {
          const typeReviewed = reviewed.indexOf(type) !== -1

          const doc = attachments.find(attachment => attachment.type === type)

          return (
            <React.Fragment key={`attachment-${type}`}>
              <div className="flex space-x-md">
                <div className="w-3">{!doc ? null : typeReviewed ? approvedIcon : icon}</div>

                <div className="flex-1">
                  <div className="font-bold">{type}</div>

                  {doc ? (
                    <div className="text-sm">
                      Uploaded {new Date(doc.savedAt).toLocaleString()} by {doc.savedByEmail}
                    </div>
                  ) : (
                    <div className="text-sm">Not uploaded</div>
                  )}
                </div>

                <div className="self-center">
                  {doc && (
                    <button
                      className="btn w-full"
                      aria-label={`View ${type}`}
                      onClick={() => {
                        if (activeTab === type) {
                          setActiveTab(undefined)
                        } else {
                          setActiveTab(type)
                        }
                      }}
                    >
                      {activeTab === type ? 'Close preview' : 'Preview'}
                    </button>
                  )}
                </div>

                {hasCharityEditPermission() && (
                  <div className="self-center flex justify-end">
                    <UploadAttachment charityId={charityId} type={type} />
                  </div>
                )}
              </div>

              {doc && activeTab === type && (
                <ViewAttachment
                  charityId={charityId}
                  contentType={doc.contentType}
                  type={activeTab}
                  onViewed={onViewed}
                />
              )}
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}
