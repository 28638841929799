import React, { useEffect, useState } from 'react'
import { LinkUnclaimedBagDialog } from './LinkUnclaimedBagDialog'
import { Filter, DateWithUTCTooltip } from '../../common'
import { authenticatedFetchData, API_HOST } from '../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { PaginationControls } from './PaginationControls'

const BAGS_PER_PAGINATION = 100

const getFilteredUnclaimedBagsKeys = (unclaimedBags: UnclaimedBagType[], searchValue: string) => {
  const unclaimedBagsAsRenderedInList = unclaimedBags.map((unclaimedBag: UnclaimedBagType) => {
    const { created, amount, currency } = unclaimedBag

    return {
      ...unclaimedBag,
      created: new Date(created).toLocaleString(),
      amount: `${amount.toFixed(2)} ${currency}`
    }
  })

  const filteredBagsAsRenderedInList = unclaimedBagsAsRenderedInList.filter(unclaimedBag =>
    JSON.stringify(Object.values(unclaimedBag)).toLowerCase().includes(searchValue)
  )

  return filteredBagsAsRenderedInList.map(bag => bag.bagtag)
}

const sortByKey = (sortKey: any, sortOrder: any) => {
  return (a: any, b: any) => {
    if (a[sortKey] < b[sortKey]) {
      return sortOrder === 'asc' ? -1 : 1
    }
    if (a[sortKey] > b[sortKey]) {
      return sortOrder === 'asc' ? 1 : -1
    }
    return 0
  }
}

export const UnclaimedBagsPage = () => {
  const [unclaimedBags, setUnclaimedBags] = useState<UnclaimedBagType[]>([])
  const [filteredUnclaimedBagsKeys, setFilteredUnclaimedBagsKeys] = useState<string[]>([])
  const [sortKey, setSortKey] = useState<any>('bagtag')
  const [searchableBagTag, setSearchableBagTag] = useState<string>('')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const [findUnclaimedBagsStatus, setFindUnclaimedBagsStatus] = useState<RequestStatusType>('pending')
  const [bagToLink, setBagToLink] = useState<UnclaimedBagType>()

  const [offset, setOffset] = useState(0)

  const onFilterChange = (newFilterValue: string) => {
    if (newFilterValue.length === 24) {
      setSearchableBagTag(newFilterValue)
    } else {
      setSearchableBagTag('')
      setFilteredUnclaimedBagsKeys(getFilteredUnclaimedBagsKeys(unclaimedBags, newFilterValue))
    }
  }

  useEffect(() => {
    setFindUnclaimedBagsStatus('pending')
    const params = searchableBagTag
      ? `?bagtag=${encodeURIComponent(searchableBagTag)}`
      : `?limit=${BAGS_PER_PAGINATION}&offset=${offset}`
    const f = authenticatedFetchData(`${API_HOST}/mytomra/v1.0/hotlinetool/unclaimed${params}`)
    f.run().then(
      (unclaimedBags: UnclaimedBagType[]) => {
        setUnclaimedBags(unclaimedBags)
        setFilteredUnclaimedBagsKeys(getFilteredUnclaimedBagsKeys(unclaimedBags, ''))
        setFindUnclaimedBagsStatus('success')
      },
      (error: Error) => {
        if (error.name !== 'AbortError') {
          setFindUnclaimedBagsStatus('failure')
          logError(new Error('Failed to fetch unclaimed bags'), error)
        }
      }
    )

    return () => f.abort()
  }, [offset, searchableBagTag])

  const goBack = () => setOffset(prev => (prev !== 0 ? prev - BAGS_PER_PAGINATION : 0))
  const goNext = () => setOffset(prev => prev + BAGS_PER_PAGINATION)

  if (findUnclaimedBagsStatus === 'pending' && unclaimedBags.length === 0) {
    return (
      <div className="centerAbsolute flex flex-col items-center space-y-md">
        <div className="loadingSpinner" />
        <p>This may take a while...</p>
      </div>
    )
  }

  if (findUnclaimedBagsStatus === 'failure') {
    return (
      <div>
        <h1 className="sr-only">Unclaimed bags</h1>
        <div className="alert alert-danger centerAbsolute">Whoopsie! Failed to unclaimed bags. Please try again.</div>
      </div>
    )
  }

  return (
    <div className="p-lg">
      <h1 className="text-xl mb-md">Unclaimed bags</h1>

      <div className="card">
        <div className="py-lg pl-lg w-1/4">
          <Filter onChange={onFilterChange} />
        </div>

        <PaginationControls
          findUnclaimedBagsStatus={findUnclaimedBagsStatus}
          currentListLength={unclaimedBags.length}
          goBack={goBack}
          goNext={goNext}
          offset={offset}
        />

        <table className="table">
          <thead>
            <tr>
              <th
                className={sortKey === 'bagtag' ? sortOrder : 'cursor-pointer'}
                onClick={() => {
                  setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
                  setSortKey('bagtag')
                }}
              >
                Bagtag
              </th>
              <th
                className={sortKey === 'created' ? sortOrder : 'cursor-pointer'}
                onClick={() => {
                  setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
                  setSortKey('created')
                }}
              >
                Date
              </th>
              <th
                className={sortKey === 'serialnumber' ? sortOrder : 'cursor-pointer'}
                onClick={() => {
                  setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
                  setSortKey('serialnumber')
                }}
              >
                Serial
              </th>
              <th
                className={sortKey === 'amount' ? sortOrder : 'cursor-pointer'}
                onClick={() => {
                  setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
                  setSortKey('amount')
                }}
              >
                Amount
              </th>
              <th
                className={sortKey === 'location' ? sortOrder : 'cursor-pointer'}
                onClick={() => {
                  setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
                  setSortKey('location')
                }}
              >
                Location
              </th>

              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {unclaimedBags
              .filter(bag => filteredUnclaimedBagsKeys.some(key => key === bag.bagtag))
              .sort((a, b) => sortByKey(sortKey, sortOrder)(a, b))
              .map(bag => {
                const { bagtag, created, serialNumber, amount, currency, location } = bag

                return (
                  <tr key={bagtag + created}>
                    <td>{bagtag}</td>
                    <td>
                      <DateWithUTCTooltip date={created} />
                    </td>
                    <td>{serialNumber}</td>
                    <td>
                      {amount.toFixed(2)} {currency}
                    </td>
                    <td>{location}</td>
                    <td>
                      <button className="btn" onClick={() => setBagToLink(bag)}>
                        Link bag to user
                      </button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>

        <PaginationControls
          findUnclaimedBagsStatus={findUnclaimedBagsStatus}
          currentListLength={unclaimedBags.length}
          goBack={goBack}
          goNext={goNext}
          offset={offset}
        />
      </div>

      {bagToLink && (
        <LinkUnclaimedBagDialog
          bagToLink={bagToLink}
          bagLinkedSuccessFully={() => setBagToLink(undefined)}
          closeDialog={() => setBagToLink(undefined)}
        />
      )}
    </div>
  )
}
