import React, { FunctionComponent } from 'react'

export const CharityActionListItemSkeleton: FunctionComponent<{ count: number }> = ({ count }) => {
  const array = Array.from(new Array(count))

  return (
    <>
      {array.map((_, i) => (
        <div key={`skeleton-${i}`} className="px-lg py-md grid grid-cols-12 gap-x-md items-center animate-pulse">
          <div className="col-span-2">
            <div className="w-5 h-[1.5rem] bg-fog rounded-r" />
          </div>
          <div className="col-span-1">
            <div className="w-[2.5rem] h-[1.5rem] bg-fog rounded-r" />
          </div>
          <div className="flex flex-col space-y-xs col-span-3">
            <div className="w-[10rem] h-[1.5rem] bg-fog rounded-r" />
            <div className="w-[12rem] h-[1rem] bg-fog rounded-r" />
          </div>
          <div className="col-span-3 flex justify-end">
            <div className="w-[5rem] h-[1.5rem] bg-fog rounded-r" />
          </div>
          <div className="col-span-3 flex justify-end">
            <div className="w-[7rem] h-[1.5rem] bg-fog rounded-r" />
          </div>
        </div>
      ))}
    </>
  )
}
