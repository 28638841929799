type EnvironmentConfig = {
  ENV: string
  STAGE: string
  VERSION: string
  DATADOG_CONFIG: {
    rumApplicationId: string
    clientToken: string
    site: string
    service: string
  }
  CONFIG_BY_STAGE: { [key: string]: { GOOGLE_MAPS_API_KEY: string; CONFIG_BY_ENV: { [key: string]: any } } }
}

// @ts-ignore
const envConfig = window.__ENVIRONMENT__ as EnvironmentConfig

const { ENV: ENVIRONMENT, STAGE, CONFIG_BY_STAGE, DATADOG_CONFIG, VERSION } = envConfig
const { GOOGLE_MAPS_API_KEY, CONFIG_BY_ENV } = CONFIG_BY_STAGE[STAGE]
const { KEYCLOAK_HOST, API_HOST, FIREBASE_REGION, MYTOMRA_CHARITY_FIREBASE_CONFIG } = CONFIG_BY_ENV[ENVIRONMENT]

export {
  ENVIRONMENT,
  KEYCLOAK_HOST,
  API_HOST,
  STAGE,
  GOOGLE_MAPS_API_KEY,
  FIREBASE_REGION,
  MYTOMRA_CHARITY_FIREBASE_CONFIG,
  DATADOG_CONFIG,
  VERSION
}

export const BROWSER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone
