import React, { FunctionComponent, ReactNode } from 'react'

type Props = {
  label: string
  searchTerm: string
  disabled: boolean
  onSubmit: () => void
  onChange: (term: string) => void
  clearResults: () => void
  children?: ReactNode
}

export const SearchForm: FunctionComponent<Props> = ({
  label,
  searchTerm,
  disabled,
  onSubmit,
  onChange,
  clearResults,
  children
}) => {
  const disableButton = disabled || !searchTerm

  const _onChange = (value: string) => {
    if (value === '') {
      clearResults()
    }

    onChange(value)
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <label className="block mb-sm" htmlFor="searchField">
        {label}
      </label>

      <div className="flex justify-between">
        <input
          id="searchField"
          type="search"
          className="mr-md"
          value={searchTerm}
          disabled={disabled}
          onChange={({ target: { value } }) => _onChange(value)}
          autoComplete="off"
        />
        <button type="submit" className="btn w-2/4" disabled={disableButton}>
          Search
        </button>
      </div>

      {children}
    </form>
  )
}
