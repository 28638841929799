import React from 'react'
import { authentication } from '../lib'
import tomraArrow from '../images/tomra-arrow.png'
import { NavLink } from 'react-router-dom'
import { AppMenuMountDiv } from './AppMenuMountDiv'
import { ExitIcon } from './icons'
import {
  hasExternalLocationWritePermission,
  hasMytomraBackofficePermission,
  hasViewCharitiesPermission,
  hasViewUnclaimedBagsPermission
} from '../services/permission.service'

export const Header = () => {
  const navLinkClassNames = ({ isActive }: { isActive: boolean }) =>
    isActive ? 'navtab flex-1 active' : 'navtab flex-1'

  return (
    <div className="navbar">
      <div className="max-w-screen-2xl w-full flex items-center gap-x-md mx-auto">
        <div className="flex items-center mr-sm">
          <img src={tomraArrow} className="mr-md h-2" alt="TOMRA logo" />
          <NavLink to="/">myTOMRA Manager</NavLink>
        </div>

        {hasMytomraBackofficePermission() && (
          <NavLink className={navLinkClassNames} to="/search">
            Search
          </NavLink>
        )}

        {hasViewCharitiesPermission() && (
          <NavLink className={navLinkClassNames} to="/charities">
            Charities
          </NavLink>
        )}

        {hasViewUnclaimedBagsPermission() && (
          <>
            <NavLink className={navLinkClassNames} to="/unclaimedbags">
              Unclaimed bags
            </NavLink>
            <NavLink className={navLinkClassNames} to="/pendingbags">
              Pending bags
            </NavLink>
          </>
        )}

        {hasExternalLocationWritePermission() && (
          <NavLink className={navLinkClassNames} to="/external-locations">
            External locations
          </NavLink>
        )}

        <div className="col-span-2 col-start-11 flex justify-end">
          <div className="h-4 flex items-center">
            <AppMenuMountDiv />
          </div>

          <button aria-label="Log out" className="btn btn-icon ml-md" onClick={() => authentication.logout()}>
            <ExitIcon color="white" width="1.5rem" height="1.5rem" />
          </button>
        </div>
      </div>
    </div>
  )
}
