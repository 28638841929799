import React, { FunctionComponent, ReactNode } from 'react'
import { CloseIcon } from '../../icons'

type Props = {
  isOpen: boolean
  onClose: () => void
  children?: ReactNode
}

export const MapDialog: FunctionComponent<Props> = ({ isOpen, onClose, children }) => {
  return (
    <div
      className={`card mx-lg absolute p-lg w-[calc(100%-3rem)] transition z-[2] ${
        isOpen ? '-translate-y-[98%]' : '-translate-y-[100%]}'
      }`}
    >
      <button
        className="absolute top-md right-md btn btn-icon rounded-sm z-10 bg-white"
        onClick={onClose}
        aria-label="Close"
      >
        <CloseIcon width="1.5rem" height="1.5rem" />
      </button>
      {children}
    </div>
  )
}
