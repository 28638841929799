import { httpsCallable } from 'firebase/functions'
import { mytomraCharityAuth, mytomraCharityFunctions, mytomraCharityStorage } from '../firebase'
import { authentication } from '../lib'
import { signInWithCustomToken } from 'firebase/auth'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import type { StorageReference } from 'firebase/storage'

export const verifyCustomAuth = async () => {
  const customToken = await httpsCallable(
    mytomraCharityFunctions,
    'createCustomTokenSecondGen'
  )({ token: authentication.getToken(), realm: 'TomraConnectUsers' }).then(result => result.data as string)

  return await signInWithCustomToken(mytomraCharityAuth, customToken)
}

export const fetchLogoOriginalUrl = async (charityId: string, thumbnailUrl: string) => {
  const timestamp = thumbnailUrl.split(charityId)?.[1].replace('_400x400?alt=media', '')
  const orgFilePath = `uploads/${charityId}${timestamp}`
  const storageRef = ref(mytomraCharityStorage, orgFilePath)
  return await getDownloadURL(storageRef)
}

export const uploadImage = (organizationLogoFileName: string, organizationLogoBlob: Blob): Promise<string> => {
  const storageRef = ref(mytomraCharityStorage, `uploads/${organizationLogoFileName}`)
  const uploadTask = uploadBytesResumable(storageRef, organizationLogoBlob)

  const thumbnailRef = ref(mytomraCharityStorage, `uploads/public/${organizationLogoFileName}_400x400`)

  return new Promise((resolve, reject) => {
    uploadTask.on('state_changed', null, reject, () => retryGetImageUrl(10, thumbnailRef).then(resolve))
  })
}

const delay = (delayMs: number) => new Promise(resolve => setTimeout(resolve, delayMs))

// We don't need the token for public images, so just remove it to prevent storing tokens in the backend database
const removeTokenFromImageUrl = (url: string) => url.split('&')?.[0]

const retryGetImageUrl = (retriesRemaining: number, thumbnailRef: StorageReference): Promise<string> => {
  if (retriesRemaining < 0) {
    return Promise.reject('Unable to fetch image URL')
  }

  return getDownloadURL(thumbnailRef)
    .then(removeTokenFromImageUrl)
    .catch(error => {
      if (error.code === 'storage/object-not-found') {
        return delay(1000).then(() => retryGetImageUrl(retriesRemaining - 1, thumbnailRef))
      }

      return Promise.reject(error)
    })
}
