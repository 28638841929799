import { charityValueMap } from '../../charity'

export const findObjectDifference = (originalObject: PartialObjectType, changedObject: PartialObjectType) => {
  const keys = Object.keys(originalObject).length > Object.keys(changedObject).length ? originalObject : changedObject

  return Object.keys(keys).reduce((acc, key) => {
    const original = originalObject[key as keyof PartialObjectType]
    const changes = changedObject[key as keyof PartialObjectType]

    if (isObject(original) && isObject(changes)) {
      const diff = findObjectDifference(original, changes)

      if (Object.keys(diff).length > 0) {
        acc[key] = diff
      }
    } else if (isObject(original) && changes === null) {
      acc[key] = findObjectDifference(original, {})
    } else if (isObject(changes) && original === null) {
      acc[key] = findObjectDifference({}, changes)
    } else if (original !== changes) {
      acc[key] = {
        oldValue: charityValueMap[original] ?? (original || ''),
        newValue: charityValueMap[changes] ?? (changes || '')
      }
    }

    return acc
  }, {} as DiffObjectType)
}

export const isObject = (value: any) => typeof value === 'object' && value !== null && value !== undefined
