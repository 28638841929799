import React, { createRef, FunctionComponent, useEffect, useState } from 'react'
import { API_HOST, authenticatedFetchData } from '../../../../../../lib'
import { logError } from '@tomra/datadog-browser-logging'

const a4Ratio = 1.41451612903

type Props = {
  charityId: string
  type: CharityFileAttachmentType['type']
  contentType: 'application/pdf' | 'image/jpeg' | 'image/png'
  onViewed: (type: CharityFileAttachmentType['type']) => void
}

export const ViewAttachment: FunctionComponent<Props> = ({ charityId, type, contentType, onViewed }) => {
  const previewRef = createRef<HTMLIFrameElement>()
  const [status, setStatus] = useState<RequestStatusType>('idle')
  const [fileIsLoaded, setFileIsLoaded] = useState<boolean>(false)
  const [fileUrl, setFileUrl] = useState<string>('')

  const contentTypePreviewSupported = contentType === 'application/pdf' || contentType.startsWith('image')

  useEffect(() => {
    if (previewRef.current) {
      const height = Math.floor(previewRef.current.clientWidth * a4Ratio)
      const eightPercent = (8 * height) / 100
      previewRef.current.style.height = `${height + eightPercent}px`
    }
  }, [previewRef])

  useEffect(() => {
    if (type) {
      setStatus('pending')
      setFileIsLoaded(false)

      authenticatedFetchData(`${API_HOST}/charities/admin/v1/charities/${charityId}/attachments/${type}`)
        .run()
        .then(({ url }: CharityFileAttachmentType) => {
          setStatus('idle')
          setFileUrl(url)
        })
        .catch((error: Error) => {
          setStatus('failure')
          logError(new Error(`Failed to fetch charity ${type} attachment`), error)
        })
    }
  }, [charityId, type])

  const onFileLoaded = () => {
    setFileIsLoaded(true)
    onViewed(type)
  }

  const onFileLoadError = () => {
    setStatus('failure')
    onViewed(type)
    setFileIsLoaded(true)
  }

  if (status === 'failure') {
    return !contentTypePreviewSupported ? (
      <div className="text-center py-xl">
        <div className="text-red text-lg">Preview of this file type is not supported.</div>
        <a className="inline-block link mt-sm" href={fileUrl}>
          Try downloading the document from the url
        </a>
      </div>
    ) : (
      <div className="alert alert-danger">Unable to load {type}</div>
    )
  }

  if (fileUrl) {
    return (
      <div className="relative">
        {!fileIsLoaded && (
          <div className="pt-lg pb-lg flex justify-center">
            <div className="loadingSpinner" />
          </div>
        )}

        {contentType === 'application/pdf' ? (
          <iframe
            className="mt-lg mb-lg w-full border border-grey transition-all ease-out duration-300"
            style={fileIsLoaded ? { opacity: 1 } : { opacity: 0 }}
            title={`${type} viewer`}
            ref={previewRef}
            src={fileUrl}
            aria-label={type}
            onLoad={onFileLoaded}
            onError={onFileLoadError}
          />
        ) : (
          <div ref={previewRef} className="mt-md w-full h-full">
            <img
              className="w-full h-full object-fit"
              alt={type}
              src={fileUrl}
              onLoad={onFileLoaded}
              onError={onFileLoadError}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="pt-lg pb-lg flex justify-center">
      <div className="loadingSpinner" />
    </div>
  )
}
