import React, { FunctionComponent, useEffect, useState } from 'react'
import { usePagination } from '../../../../hooks/usePagination'
import { API_HOST } from '../../../../lib'
import { PaginationButtons } from '../../../common/PaginationButtons'
import { CharityApplicationTable } from './CharityApplicationTable'
import { CharityReportButton } from './CharityReportButton'

export const CharitiesListPage: FunctionComponent = () => {
  const [filter, setFilter] = useState<string>('')
  const limit = 20
  const { status, search, results: applications, offset, canGoNext, canGoBack } = usePagination(limit)
  const showPaginationButtons = applications && applications.length > 0 && (canGoBack || canGoNext)
  const onSearch = (newOffset: number) => search(`${API_HOST}/charities/admin/v1/charities`, newOffset)

  useEffect(() => {
    search(`${API_HOST}/charities/admin/v1/charities`, 0)
  }, [search])

  const filteredCampaigns = filter ? applications.filter(a => a.campaignRunState === filter) : applications

  return (
    <div className="py-lg">
      <div className="flex mb-md justify-between items-center">
        <h1 className="text-xl">All Charities</h1>

        <CharityReportButton />
      </div>

      {status === 'failure' ? (
        <div className="alert alert-danger">Failed to load charities. Please try again.</div>
      ) : status === 'pending' && applications.length === 0 ? (
        <div className="pt-lg pb-lg flex content-center justify-center">
          <div className="loadingSpinner" />
        </div>
      ) : applications.length > 0 ? (
        <div className="card pt-lg">
          <select
            aria-label="Filter results"
            className="select ml-lg mb-lg w-1/4"
            value={filter}
            onChange={({ target }) => setFilter(target.value)}
          >
            <option value="">Filter results</option>
            <option value="NO_SCHEDULED_CAMPAIGN">No scheduled campaign</option>
            <option value="SCHEDULED_CAMPAIGN">Scheduled campaign</option>
            <option value="RUNNING_CAMPAIGN">Running campaign</option>slack
          </select>

          <CharityApplicationTable applications={filteredCampaigns} />

          {showPaginationButtons && (
            <PaginationButtons
              isLoading={status === 'pending'}
              limit={limit}
              offset={offset}
              onOffsetChange={newOffset => onSearch(newOffset)}
              canGoBack={canGoBack}
              canGoNext={canGoNext}
            />
          )}
        </div>
      ) : (
        <p className="pr-lg pl-lg pb-lg">No charity applications</p>
      )}
    </div>
  )
}
