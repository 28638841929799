import React, { FunctionComponent, useState } from 'react'
import { NoteEdit } from './NoteEdit'
import { DeleteIcon, EditIcon } from '../../icons'
import { API_HOST, authenticatedFetchData } from '../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { ConfirmDialog } from '../dialogs'
import { hasCharityEditPermission } from '../../../services/permission.service'

type Props = {
  refetchNotes: () => void
  id: string
  created: Date
  name: string
  email: string
  updated: Date
  note: string
}

export const NoteComment: FunctionComponent<Props> = ({ id, created, name, email, updated, note, refetchNotes }) => {
  const [editModeEnabled, setEditMode] = useState<boolean>(false)
  const [deleteStatus, setDeleteStatus] = useState<RequestStatusType>('idle')
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false)

  const deleteNote = () => {
    setDeleteStatus('pending')

    authenticatedFetchData(`${API_HOST}/mytomra/v1.0/hotlinetool/managernotes/${id}`, {
      method: 'DELETE'
    })
      .run()
      .then(
        () => {
          setDeleteStatus('idle')
          refetchNotes()
        },
        (error: Error) => {
          setDeleteStatus('failure')
          logError(new Error('Failed to delete note'), error)
        }
      )
  }

  const date = new Date(updated) > new Date(created) ? updated : created

  return (
    <div className="border-b border-grey-light pt-md pb-md">
      {editModeEnabled ? (
        <NoteEdit noteId={id} note={note} setEditMode={setEditMode} refetchNotes={refetchNotes} />
      ) : (
        <div className="flex justify-between">
          <div className="flex-1 mr-md">
            <div className="relative cursor-pointer group">
              <span>{name}</span>
              <span className="absolute py-xs px-sm left-0 bottom-[100%] text-white rounded-half bg-black opacity-0 transition group-hover:opacity-100">
                {email}
              </span>
            </div>

            <div className="mt-sm">{note}</div>

            <div className="relative cursor-pointer group">
              <>{date}</>
              <span className="absolute py-xs px-sm left-0 bottom-[100%] text-white rounded-half bg-black opacity-0 transition group-hover:opacity-100">
                {new Date(date).toLocaleString('default', { timeZone: 'UTC' }) + ' in UTC'}
              </span>
            </div>
          </div>

          {hasCharityEditPermission() && (
            <>
              <button className="btn btn-icon self-start" aria-label="Edit note" onClick={() => setEditMode(true)}>
                <EditIcon width="1.5rem" height="1.5rem" />
              </button>

              <button
                className="btn btn-icon self-start ml-md"
                aria-label="Delete note"
                onClick={() => setConfirmDelete(true)}
              >
                {deleteStatus === 'pending' ? (
                  <div className="loadingSpinner" aria-label="Please wait" />
                ) : (
                  <DeleteIcon width="1.5rem" height="1.5rem" />
                )}
              </button>
            </>
          )}
        </div>
      )}

      {deleteStatus === 'failure' && <div className="alert alert-danger mt-sm mb-md">Failed to delete note</div>}

      {confirmDelete && (
        <ConfirmDialog
          title="Delete note?"
          confirmButtonText="Delete"
          cancelButtonText="Cancel"
          onCancel={() => setConfirmDelete(false)}
          onConfirm={() => {
            setConfirmDelete(false)
            deleteNote()
          }}
        />
      )}
    </div>
  )
}
