import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'
import { getAuth } from 'firebase/auth'
import { getFunctions } from 'firebase/functions'
import { FIREBASE_REGION, MYTOMRA_CHARITY_FIREBASE_CONFIG } from './lib'

// We use a firebase function in mytomra-charity to authenticate users for uploading images to storage. We need to
export const mytomraCharityApp = initializeApp(MYTOMRA_CHARITY_FIREBASE_CONFIG)
export const mytomraCharityStorage = getStorage(mytomraCharityApp)
export const mytomraCharityAuth = getAuth(mytomraCharityApp)
export const mytomraCharityFunctions = getFunctions(mytomraCharityApp, FIREBASE_REGION)
