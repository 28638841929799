import { fetchData } from '@tomra/client-side-http-client'
import { authentication } from './authentication'

const config = {
  fetchNewToken: authentication?.fetchNewToken,
  getToken: authentication?.getToken,
  timeoutInMs: 60000
}

export const authenticatedFetchData = (url: string, options = {}) => {
  return fetchData(url, options, config)
}
