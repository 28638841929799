import React, { FunctionComponent, useContext, useState } from 'react'
import { CharityReviewContext } from '../../../../../contexts'
import { API_HOST, authenticatedFetchData } from '../../../../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { FormField } from '../../../../../common'
import { EditableApplicationSection } from './EditableApplicationSection'
import { benefitMap, geoFootprintMap, legalStructuresMap } from '../../../common/charity'

const states = {
  NSW: 'NSW',
  NT: 'NT',
  QLD: 'QLD',
  VIC: 'VIC',
  TAS: 'TAS',
  ACT: 'ACT',
  WA: 'WA'
}

type Props = {
  charityId: string
}

export const OrganizationSection: FunctionComponent<Props> = ({ charityId }) => {
  const title = 'Organisation'
  const { charity, setCharity, setEditingSection, attachments } = useContext(CharityReviewContext)
  const [status, setStatus] = useState<RequestStatusType>('idle')

  const { organization } = charity
  const organizationDocuments: CharityFileAttachmentType['type'][] = [
    'IncorporationFile',
    'ABNDocument',
    'ParentOrganizationLetter'
  ]

  const onUpdate = (data: { [key: string]: any }) => {
    setStatus('pending')

    authenticatedFetchData(`${API_HOST}/charities/admin/v1/charities/${charityId}/organization`, {
      method: 'PUT',
      body: JSON.stringify(data)
    })
      .run()
      .then((response: OrganizationType) => {
        setStatus('idle')
        setCharity({ ...charity, organization: response })
      })
      .catch((error: any) => {
        logError(new Error('Failed to update organisation'), error)
        setStatus('failure')
      })

    setEditingSection('')
  }

  const formFields: FormField[] = [
    ...(charity.organization.externalId ? [{ label: 'Donation Partner ID', name: 'externalId', readOnly: true }] : []),
    { label: 'Name', name: 'name', required: true },
    { label: 'Display name', name: 'displayName' },
    { label: 'Website url', name: 'websiteUrl' },
    { label: 'Description', type: 'textarea', maxLength: 500, name: 'description', required: true },
    { label: 'ABN', name: 'organizationAbn', readOnly: true },
    { label: 'Operating state', name: 'operatingState', readOnly: true },
    { label: 'Registered in', name: 'registeredState', required: true, options: states },
    { label: 'Established', name: 'establishedDate', type: 'date', required: true },
    { label: 'Legal structure', name: 'legalStructure', required: true, options: legalStructuresMap },
    {
      label: 'Legal structure details',
      name: 'legalStructureDetails',
      required: true,
      showIf: { field: 'legalStructure', value: 'OTHER' }
    },
    { label: 'Operational footprint', name: 'geoFootprint', required: true, options: geoFootprintMap },
    { label: 'Incorporation number', name: 'incorporationNumber' },
    { label: 'Local group name', name: 'localGroupName' },
    { label: 'Street address', name: 'streetAddress', maxLength: 35, required: true },
    { label: 'Suburb', name: 'suburb' },
    { label: 'Postcode', name: 'postCode', required: true },
    { label: 'Benefit', name: 'benefit', required: true, options: benefitMap }
  ]

  const isOrganizationDocument = (doc: CharityFileAttachmentType) => organizationDocuments.indexOf(doc.type) !== -1

  return (
    <>
      <EditableApplicationSection
        isUpdating={status === 'pending'}
        title={title}
        formFields={formFields}
        initialValues={organization}
        onUpdate={onUpdate}
        attachments={attachments.filter(isOrganizationDocument)}
        documentTypes={organizationDocuments}
      />

      {status === 'failure' && <div className="alert alert-danger">Failed to update section. Please try again.</div>}
    </>
  )
}
