import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { CharityReviewContext } from '../../../../contexts'
import { API_HOST, authenticatedFetchData } from '../../../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { CheckmarkIcon } from '../../../../icons'
import { hasCharityEditPermission } from '../../../../../services/permission.service'

type AdminUser = {
  email: string
  firstName: string
  lastName: string
}

export const CharityAdminForm = () => {
  const { charity, setCharity } = useContext(CharityReviewContext)
  const [status, setStatus] = useState<RequestStatusType | 'notFound'>('idle')
  const [showSetNewAdminForm, setShowSetNewAdminForm] = useState(false)
  const [adminUser, setAdminUser] = useState<AdminUser>({
    email: charity.administratorEmail,
    firstName: '',
    lastName: ''
  })
  const [showFeedback, setShowFeedback] = useState(false)

  const updateCharityAdmin = (e: FormEvent) => {
    e.preventDefault()

    setStatus('pending')

    authenticatedFetchData(`${API_HOST}/charities/admin/v1/charities/${charity.id}/admin_user`, {
      method: 'PUT',
      body: JSON.stringify(adminUser)
    })
      .run()
      .then(() => {
        setStatus('idle')
        setCharity({ ...charity, administratorEmail: adminUser.email })
        setShowSetNewAdminForm(false)
        setShowFeedback(true)
      })
      .catch(error => {
        if (error.status === 404) {
          setStatus('notFound')
        } else {
          setStatus('failure')
        }

        if (error.name !== 'AbortError') {
          logError(new Error('Failed to update charity admin user'), error)
        }
      })
  }

  useEffect(() => {
    if (!showFeedback) {
      return
    }

    const id = setTimeout(() => setShowFeedback(false), 5000)
    return () => clearTimeout(id)
  }, [showFeedback])

  return showSetNewAdminForm ? (
    <form onSubmit={updateCharityAdmin}>
      <label className="label" htmlFor="email">
        E-mail
      </label>
      <input
        id="email"
        type="text"
        value={adminUser?.email || ''}
        onChange={({ target }) => setAdminUser({ ...adminUser, email: target.value })}
        required
      />

      {status === 'pending' ? (
        <div className="mt-md flex justify-end">
          <div className="loadingSpinner" />
        </div>
      ) : (
        <>
          <div className="mt-md flex space-x-md justify-end">
            <button className="btn" onClick={() => setShowSetNewAdminForm(false)}>
              Cancel
            </button>

            <button type="submit" className="btn btn-primary-dark" aria-disabled={!adminUser?.email}>
              Update
            </button>
          </div>

          {status === 'notFound' && (
            <div className="text-red font-bold mt-md text-right">
              User not found.
              <br />
              Have they registered in the charity portal?
            </div>
          )}

          {status === 'failure' && (
            <div className="text-red font-bold mt-md text-right">Unable to update charity admin</div>
          )}
        </>
      )}
    </form>
  ) : (
    <>
      <div className="flex justify-between items-center">
        <div>{charity.administratorEmail}</div>
        {hasCharityEditPermission() && (
          <button className="btn" onClick={() => setShowSetNewAdminForm(true)}>
            Edit admin
          </button>
        )}
      </div>

      {showFeedback && (
        <div className="mt-md font-bold text-green-dark flex justify-center space-x-sm">
          <CheckmarkIcon width="1.5rem" height="1.5rem" />
          <span>Admin was updated!</span>
        </div>
      )}
    </>
  )
}
