import React from 'react'

type Props = {
  onChange: Function
}

type State = {
  filterValue: string
  loading: boolean
}

export class Filter extends React.Component<Props, State> {
  _timeoutRef: number = 0

  state: State = {
    filterValue: '',
    loading: false
  }

  componentWillUnmount() {
    window.clearTimeout(this._timeoutRef)
  }

  _onChange = (filterValue: string) => {
    this.setState({ filterValue, loading: true })

    window.clearTimeout(this._timeoutRef)

    this._timeoutRef = window.setTimeout(() => {
      this.setState({ loading: false })
      this.props.onChange(filterValue)
    }, 500)
  }

  render() {
    const { filterValue, loading } = this.state

    return (
      <div className="flex items-end space-x-md">
        <div className="flex-1">
          <label htmlFor="filter" className="label">
            Search & filter
          </label>
          <input
            id="filter"
            type="search"
            value={filterValue}
            onChange={evt => this._onChange(evt.target.value.trim())}
          />
        </div>

        {loading && <div className="loadingSpinner" />}
      </div>
    )
  }
}
