import React, { useState } from 'react'
import { authenticatedFetchData, API_HOST } from '../../../lib'
import { logError } from '@tomra/datadog-browser-logging'

type Props = {
  sourceId: string
  refetchNotes: Function
}

export const NotesForm = (props: Props) => {
  const [writtenNote, setWrittenNote] = useState<string>('')
  const [notesStatus, setNotesStatus] = useState<RequestStatusType>('idle')
  const sourceId = props.sourceId

  const onSubmit = (e: any) => {
    e.preventDefault()
    setNotesStatus('pending')

    const options = {
      method: 'POST',
      body: JSON.stringify({
        note: writtenNote
      })
    }

    authenticatedFetchData(API_HOST + '/mytomra/v1.0/hotlinetool/managernotes/' + sourceId, options)
      .run()
      .then(
        () => {
          setNotesStatus('idle')
          props.refetchNotes()
          setWrittenNote('')
        },
        (error: Error) => {
          setNotesStatus('failure')
          logError(new Error('Failed to create note'), error)
        }
      )
  }

  return (
    <div>
      {notesStatus === 'failure' && (
        <div className="alert alert-danger">Whoopsie! Failed to add manager notes. Please try again.</div>
      )}

      <form onSubmit={onSubmit} className="flex items-end">
        <textarea
          className="textarea max-w-full mr-md"
          aria-label="Write a note"
          onChange={({ target: { value } }) => setWrittenNote(value)}
          value={writtenNote}
          placeholder="Leave a note!"
        />

        <button
          className="btn btn-primary-dark min-w-6"
          type="submit"
          disabled={writtenNote === '' || notesStatus === 'pending'}
          value="submit"
        >
          {notesStatus === 'pending' ? <div aria-label="Please wait" className="loadingSpinner" /> : 'Add'}
        </button>
      </form>
    </div>
  )
}
