import { API_HOST, authenticatedFetchData } from '../lib'
import { uploadFile } from './file.service'

export const uploadCharityAttachment = (
  charityId: string,
  type: CharityFileAttachmentType['type'],
  file: Blob | File
) => {
  return authenticatedFetchData(`${API_HOST}/charities/admin/v1/charities/${charityId}/attachments/${type}/update`)
    .run()
    .then(({ url }) => uploadFile(url, file))
    .then(() => authenticatedFetchData(`${API_HOST}/charities/admin/v1/charities/${charityId}/attachments`).run())
}

export const loadNewCharityActions = () => {
  const newCharities = authenticatedFetchData(`${API_HOST}/charities/admin/v1/charities?status=NEW&limit=500&offset=0`)
  const newCampaigns = authenticatedFetchData(`${API_HOST}/charities/admin/v1/campaigns?status=NEW&limit=500&offset=0`)
  const newPendingCharities = authenticatedFetchData(
    `${API_HOST}/charities/admin/v1/pending-charities?limit=500&offset=0`
  )
  const newPendingCampaigns = authenticatedFetchData(
    `${API_HOST}/charities/admin/v1/pending-campaigns?limit=500&offset=0`
  )

  const runAll = () =>
    Promise.all([newCharities.run(), newCampaigns.run(), newPendingCharities.run(), newPendingCampaigns.run()]).then(
      ([charities, campaigns, pendingCharities, pendingCampaigns]) => {
        return [...charities, ...campaigns, ...pendingCharities, ...pendingCampaigns]
      }
    )

  return {
    runAll,
    abortAll: () => {
      newCharities.abort()
      newCampaigns.abort()
      newPendingCharities.abort()
      newPendingCampaigns.abort()
    }
  }
}
