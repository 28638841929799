import React, { FunctionComponent, useEffect, useState } from 'react'
import { usePagination } from '../../../../hooks/usePagination'
import { API_HOST } from '../../../../lib'
import { PaginationButtons } from '../../../common/PaginationButtons'
import { CharityCampaignTable } from './CharityCampaignTable'

export const CampaignListPage: FunctionComponent = () => {
  const [filter, setFilter] = useState<string>('')
  const [url, setUrl] = useState<string>(`${API_HOST}/charities/admin/v1/campaigns`)
  const limit = 20
  const { status, search, results: applications, clearResults, offset, canGoNext, canGoBack } = usePagination(limit)
  const showPaginationButtons = applications && applications.length > 0 && (canGoBack || canGoNext)

  const onSearch = (newOffset: number) => search(url, newOffset)

  useEffect(() => {
    clearResults()
    search(url, 0)
  }, [search, url, clearResults])

  useEffect(() => {
    const newUrl = filter
      ? `${API_HOST}/charities/admin/v1/campaigns?status=${encodeURIComponent(filter)}`
      : `${API_HOST}/charities/admin/v1/campaigns`
    setUrl(newUrl)
  }, [filter])

  const isLoading = status === 'pending'

  return (
    <div className="py-lg">
      <div className="flex mb-md justify-between items-center">
        <h1 className="text-xl">All campaigns</h1>
        <div>
          <select
            aria-label="Filter results"
            className="select"
            value={filter}
            defaultValue={filter}
            onChange={({ target }) => setFilter(target.value)}
          >
            <option value="">All statuses</option>
            <option value="NEW">New</option>
            <option value="ACTIVE">Active</option>
            <option value="EXPIRED">Expired</option>
          </select>
        </div>
      </div>

      {status === 'failure' ? (
        <div className="alert alert-danger">Failed to load campaigns. Please try again.</div>
      ) : status === 'pending' && applications.length === 0 ? (
        <div className="pt-lg pb-lg flex content-center justify-center">
          <div className="loadingSpinner" />
        </div>
      ) : applications.length > 0 ? (
        <div className="card">
          <CharityCampaignTable campaigns={applications} showStatus={true} />

          {showPaginationButtons && (
            <PaginationButtons
              isLoading={isLoading}
              limit={limit}
              offset={offset}
              onOffsetChange={newOffset => onSearch(newOffset)}
              canGoBack={canGoBack}
              canGoNext={canGoNext}
            />
          )}
        </div>
      ) : (
        <p>No campaigns</p>
      )}
    </div>
  )
}
