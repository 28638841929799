import React, { FunctionComponent } from 'react'
import { ListItem } from '../../../../common/styled'
import { Status } from '../../../../common/styled/Status'
import { ArrowRightIcon } from '../../../../icons'
import { useNavigate } from 'react-router'
import { campaignRunningStateMap } from '../../common/charityCampaignMap'

type Props = {
  charity: CharitySummaryType
}

export const CharityListItem: FunctionComponent<Props> = ({ charity }) => {
  const navigate = useNavigate()

  const { id, displayName, organizationName, campaignRunState, status } = charity

  return (
    <ListItem
      onClick={() => navigate(`/charities/${id}`)}
      ariaLabel={`Show charity ${displayName}`}
      label={
        <div className="flex space-x-sm align-middle">
          <div className="w-2/3 self-center">
            <h2 className="font-bold">{displayName}</h2>
            <div className="mt-xs">{organizationName}</div>
          </div>

          <div className="flex-1 text-right">
            <div className="inline-block font-bold mb-xs text-sm">Charity</div>

            {status !== 'NEW' ? (
              <div className="text-sm">{campaignRunningStateMap[campaignRunState]}</div>
            ) : (
              <Status status={status} />
            )}
          </div>

          <div className="self-center">
            <ArrowRightIcon width="1rem" height="1rem" />
          </div>
        </div>
      }
    />
  )
}
