import React, { FunctionComponent, ReactNode, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { CloseIcon } from '../icons'

type Props = {
  ariaLabel: string
  onClose?: () => void
  position?: 'TOP' | 'CENTER' | 'BOTTOM'
  children?: ReactNode
}

export const Dialog: FunctionComponent<Props> = ({ ariaLabel, children, onClose, position = 'CENTER' }) => {
  const justifyContent = position === 'TOP' ? 'items-start' : position === 'BOTTOM' ? 'items-end' : 'items-center'

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (onClose && event.key === 'Escape') {
        event.stopPropagation()
        onClose()
      }
    }

    window.addEventListener('keydown', handleEscapeKey)

    return () => window.removeEventListener('keydown', handleEscapeKey)
  }, [onClose])

  return createPortal(
    <div
      aria-label={ariaLabel}
      role="dialog"
      className={`fixed top-0 left-0 flex w-full h-full ${justifyContent} justify-center z-10`}
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
    >
      <div className="relative z-10">
        {children}

        {onClose && (
          <button aria-label="Close" className="btn btn-icon absolute top-sm right-sm" onClick={onClose}>
            <CloseIcon width="1.5rem" height="1.5rem" />
          </button>
        )}
      </div>
    </div>,
    document.getElementById('root') as HTMLDivElement
  )
}
