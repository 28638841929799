import React, { FunctionComponent, useEffect, useState } from 'react'
import { Dialog } from '../../../../common/Dialog'
import { API_HOST, authenticatedFetchData } from '../../../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { Diff } from './diff/Diff'
import { findObjectDifference } from './diff/diffHelpers'
import { hasCharityEditPermission } from '../../../../../services/permission.service'
import { AlertIcon } from '../../../../icons'
import { ApprovePendingChangesButton } from './ApprovePendingChangesButton'
import { RejectPendingChangesButton } from './RejectPendingChangesButton'
import { isBefore } from 'date-fns'

const restrictedFields = [
  'id',
  'administratorUserId',
  'administratorEmail',
  'createdAt',
  'createdBy',
  'updatedAt',
  'updatedBy',
  'logoUrl',
  'status',
  'campaignCode',
  'campaignId',
  'charityId'
]

type Props = {
  charityId: string
  campaignId?: string
  changesToReview: CharityType | CharityCampaignType
  onComplete: () => void
  onCancel: () => void
}

export const ReviewPendingChanges: FunctionComponent<Props> = ({
  charityId,
  campaignId,
  changesToReview,
  onComplete,
  onCancel
}) => {
  const [status, setStatus] = useState<RequestStatusType | 'approve' | 'reject'>('idle')
  const [originalObject, setOriginalObject] = useState<CharityType | CharityCampaignType | null>()
  const [diff, setDiff] = useState<DiffObjectType | null>(null)

  useEffect(() => {
    setStatus('pending')
    const originalUrl = campaignId
      ? `${API_HOST}/charities/admin/v1/charities/${charityId}/campaign/${campaignId}`
      : `${API_HOST}/charities/admin/v1/charities/${charityId}`

    const fetchOriginal = authenticatedFetchData(originalUrl)
    fetchOriginal
      .run()
      .then(setOriginalObject)
      .catch((error: any) => {
        if (error.name !== 'AbortError') {
          logError(new Error('Failed to load original object'), error)
          setStatus('failure')
        }
      })

    return () => {
      fetchOriginal.abort()
    }
  }, [changesToReview])

  useEffect(() => {
    if (!originalObject) {
      return
    }

    const removeRestrictedFields = (object: CharityType | CharityCampaignType) => {
      const propsToCompare = Object.keys(object).filter((field: string) => restrictedFields.indexOf(field) === -1)

      return Object.keys(object).reduce(
        (acc, key) => {
          if (propsToCompare.indexOf(key) === -1) {
            return acc
          }

          return { ...acc, [key]: object[key as keyof (CharityType | CharityCampaignType)] }
        },
        {} as Partial<CharityType | CharityCampaignType>
      )
    }

    setDiff(findObjectDifference(removeRestrictedFields(originalObject), removeRestrictedFields(changesToReview)))
  }, [changesToReview, originalObject])

  const changesIncludeEndDate = diff ? Object.keys(diff).indexOf('endTime') !== -1 : false
  const newEndDateHasPassed = changesIncludeEndDate ? isBefore(new Date(diff?.endTime.newValue), new Date()) : false

  return (
    <>
      <Dialog ariaLabel="Review changes" onClose={onCancel}>
        <div className="card min-w-screen-md max-w-screen-lg p-lg overflow-hidden">
          {!hasCharityEditPermission() && <div className="alert alert-warning">You only have read-permissions</div>}

          {newEndDateHasPassed && (
            <div className="alert alert-danger">The new end date has passed. You must reject the changes.</div>
          )}

          {diff ? (
            <>
              <Diff diff={diff} />

              {hasCharityEditPermission() && (
                <div className="flex space-x-md justify-end">
                  <RejectPendingChangesButton charityId={charityId} campaignId={campaignId} onClose={onComplete} />
                  {!newEndDateHasPassed && (
                    <ApprovePendingChangesButton charityId={charityId} campaignId={campaignId} onClose={onComplete} />
                  )}
                </div>
              )}
            </>
          ) : status === 'failure' ? (
            <div className="h-10 flex flex-col items-center justify-center space-y-md text-red-dark">
              <AlertIcon width="2rem" height="2rem" />
              <div>Something went wrong</div>
            </div>
          ) : (
            <div className="h-10 flex items-center justify-center">
              <div className="loadingSpinner" />
            </div>
          )}
        </div>
      </Dialog>
    </>
  )
}
