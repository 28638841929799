import React, { FunctionComponent } from 'react'
import { FormField } from './Form'

type Props = {
  fields: FormField[]
  values: { [key: string]: any }
  displayValueMap: { [key: string]: string }
}

export const SectionData: FunctionComponent<Props> = ({ fields, values, displayValueMap }) => {
  return (
    <>
      {fields.map(field => {
        const value = values[field.name]

        return (
          <dl className="mb-lg" key={`field-${field.name}`}>
            <dt className="mb-sm text-storm" id={`data-${field.name}`}>
              {field.label}
            </dt>
            <dd className="break-all" aria-labelledby={`data-${field.name}`}>
              {field.type === 'date' ? new Date(value).toLocaleDateString() : displayValueMap[value] || value || ' - '}
            </dd>
          </dl>
        )
      })}
    </>
  )
}
