import React, { FunctionComponent } from 'react'
import { Dialog } from '../Dialog'

type Props = {
  text: string
}

export const StatusDialog: FunctionComponent<Props> = ({ text }) => {
  return (
    <Dialog ariaLabel="Status">
      <div className="card p-xl flex flex-col items-center justify-center">
        <div className="loadingSpinner text-xl mb-md" />
        <p className="text-lg">{text}</p>
      </div>
    </Dialog>
  )
}
