import React, { FunctionComponent } from 'react'
import { removeProperty } from '../../lib/helpers'

export type FormField = {
  label: string
  type?: 'text' | 'email' | 'tel' | 'date' | 'textarea'
  name: string
  required?: boolean
  pattern?: 'email'
  error?: string
  options?: { [key: string]: any }
  maxLength?: number
  showIf?: { field: string; value: any }
  readOnly?: boolean
}

type Props = {
  formFields: FormField[]
  state: { [key: string]: any }
  errors: { [key: string]: any }
  onChange: (values: { [key: string]: any }, errors: { [key: string]: any }) => void
}

const patterns = {
  email: {
    regex:
      "/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g",
    error: 'E-mail is not valid'
  }
}

type OnChange = (field: FormField, value: any, valid: boolean) => void

const createDatePicker = (field: FormField, state: string, onChange: OnChange, error?: string) => {
  const { name, label, required, readOnly } = field
  const date = new Date(state).toISOString().substring(0, 10)

  return (
    <div className="mb-lg" key={`field-${name}`}>
      <label htmlFor={name} className="label">
        {label}
      </label>

      <input
        id={name}
        value={date}
        type="date"
        required={required}
        readOnly={readOnly}
        className={readOnly ? 'cursor-not-allowed' : ''}
        onChange={({ target }) => onChange(field, new Date(target.value).toISOString(), true)}
      />

      {error && <div className="text-red">{error}</div>}
    </div>
  )
}

const createTextField = (field: FormField, state: string, onChange: OnChange, error?: string) => {
  const pattern = field.pattern ? patterns[field.pattern].regex : ''
  const { name, label, required, maxLength, readOnly } = field

  return (
    <div className="mb-lg" key={`field-${name}`}>
      <label id={name} className="label">
        {label}
      </label>

      <input
        type="text"
        aria-labelledby={name}
        className={readOnly ? 'cursor-not-allowed' : ''}
        value={state}
        required={required}
        pattern={pattern}
        maxLength={maxLength}
        readOnly={readOnly}
        onChange={({ target: { value } }) => onChange(field, value, true)}
      />

      {maxLength && (
        <div>
          {state.length}/{maxLength}
        </div>
      )}

      {error && <div className="text-red">{error}</div>}
    </div>
  )
}

const createTextArea = (field: FormField, state: string, onChange: OnChange, error?: string) => {
  const { name, label, required, maxLength, readOnly } = field

  return (
    <div className="mb-lg" key={`field-${name}`}>
      <label id={name} className="label">
        {label}
      </label>

      <textarea
        className={`resize-none ${readOnly ? 'cursor-not-allowed' : ''}`}
        aria-labelledby={name}
        value={state}
        required={required}
        maxLength={maxLength}
        readOnly={readOnly}
        onChange={({ target: { value } }) => onChange(field, value, true)}
      />

      {maxLength && (
        <div>
          {state?.length}/{maxLength}
        </div>
      )}

      {error && <div className="text-red">{error}</div>}
    </div>
  )
}

const createSelect = (
  field: FormField,
  state: string,
  onChange: (field: FormField, value: string, valid: boolean) => void
) => {
  if (field.options) {
    const { options, name, label, readOnly } = field

    return readOnly ? (
      createTextArea(field, state, onChange)
    ) : (
      <div className="mb-lg" key={`field-${name}`}>
        <label htmlFor={name} className="label">
          {label}
        </label>

        <select
          id={name}
          className="select"
          defaultValue={state}
          onChange={({ target }) => onChange(field, target.value, true)}
        >
          {Object.keys(options).map(key => (
            <option key={key} value={key}>
              {options[key]}
            </option>
          ))}
        </select>
      </div>
    )
  }

  return null
}

const createNewState = (field: FormField, valid: boolean, errors: { [key: string]: any }) => {
  return !valid
    ? { ...errors, [field.name]: field.error || `${field.label} is not valid` }
    : removeProperty(errors, field.name)
}

export const Form: FunctionComponent<Props> = ({ formFields, state, errors, onChange }) => {
  const _onChange = (field: FormField, value: any, valid: boolean) => {
    const newState = createNewState(field, valid, errors)
    onChange({ ...state, [field.name]: value }, newState)
  }

  return (
    <form>
      {formFields.map(field => {
        const { name, showIf } = field
        const error = errors[field.name]

        if (!showIf || state[showIf.field] === showIf.value) {
          if (field.type === 'textarea') {
            return createTextArea(field, state[name], _onChange, error)
          }

          if (field.type === 'date') {
            return createDatePicker(field, state[name], _onChange, error)
          }

          if (field.options) {
            return createSelect(field, state[name], _onChange)
          }

          return createTextField(field, state[name], _onChange, error)
        }

        return null
      })}
    </form>
  )
}
