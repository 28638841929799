import React, { useCallback, useEffect, useState } from 'react'
import { CreateExternalLocationsDiff } from './diff/CreateExternalLocationsDiff'
import { fetchExternalLocations } from '../../../services/external-locations.service'
import { logError } from '@tomra/datadog-browser-logging'
import { ExternalLocationDiffOverview } from './diff/ExternalLocationDiffOverview'
import { ExternalLocationsOverview } from './ExternalLocationsOverview'

const alphabetically = (a: ExternalLocationType, b: ExternalLocationType) => (a.name < b.name ? -1 : 1)

export const ExternalLocationsPage = () => {
  const [status, setStatus] = useState<RequestStatusType>('idle')
  const [externalLocations, setExternalLocations] = useState<ExternalLocationType[]>([])
  const [diffResponse, setDiffResponse] = useState<ExternalLocationDiffResponseType | null>(null)

  const handleLocationsResponse = useCallback((result: ExternalLocationType[]) => {
    setStatus('idle')
    setExternalLocations(result.sort(alphabetically))
  }, [])

  const handleLocationsError = useCallback((error: any) => {
    if (error.name !== 'AbortError') {
      setStatus('failure')
      logError(new Error('Unable to load external locations'), error)
    }
  }, [])

  useEffect(() => {
    setStatus('pending')

    const { run, abort } = fetchExternalLocations()
    run().then(handleLocationsResponse).catch(handleLocationsError)

    return () => abort()
  }, [handleLocationsError, handleLocationsResponse])

  const getLocations = () => {
    setStatus('pending')
    fetchExternalLocations().run().then(handleLocationsResponse).catch(handleLocationsError)
  }

  const isLoading = status === 'pending'
  const hasLocations = externalLocations.length > 0

  return (
    <>
      <div className="flex items-end justify-between my-md">
        <h1 className="text-xl">External locations</h1>

        {!isLoading && hasLocations && <CreateExternalLocationsDiff onDiffResponseReceived={setDiffResponse} />}
      </div>

      {isLoading ? (
        <div className="centerAbsolute">
          <div className="loadingSpinner" />
        </div>
      ) : status === 'failure' ? (
        <div className="alert alert-danger centerAbsolute font-bold w-1/2">Something went wrong</div>
      ) : hasLocations ? (
        <ExternalLocationsOverview allLocations={externalLocations} />
      ) : (
        <div className="card p-lg flex flex-col items-center">
          <h2 className="text-lg mb-sm">No external locations yet</h2>
          <p className="mb-md">Please select a file to upload.</p>
          <CreateExternalLocationsDiff onDiffResponseReceived={setDiffResponse} />
        </div>
      )}

      {diffResponse && (
        <ExternalLocationDiffOverview
          fileId={diffResponse.fileId}
          diff={diffResponse.diff}
          onDiffApplied={() => {
            getLocations()
            setDiffResponse(null)
          }}
          onDiffRejected={() => setDiffResponse(null)}
        />
      )}
    </>
  )
}
