import { BROWSER_TIMEZONE } from '../../lib'
import React, { FunctionComponent } from 'react'
import { DateWithUTCTooltip } from './DateWithUTCTooltip'
import { Link } from 'react-router-dom'
import { ArrowRightIcon } from '../icons'

type Props = {
  payouts: PayoutType[]
}

export const PayoutsTable: FunctionComponent<Props> = ({ payouts }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Payout ID</th>
          <th>Date ({BROWSER_TIMEZONE})</th>
          <th>Amount</th>
          <th>Clearing house</th>
          <th>Payout provider</th>
          <th>Latest status</th>
        </tr>
      </thead>
      <tbody>
        {payouts.map(({ id, created, currency, amount, clearingHouse, paymentProvider, latestStatus }) => (
          <tr key={id}>
            <td>{id}</td>
            <td>
              <DateWithUTCTooltip date={created} />
            </td>
            <td>
              {amount && amount.toFixed(2)} {currency}
            </td>
            <td>{clearingHouse}</td>
            <td>{paymentProvider}</td>
            <td>{latestStatus}</td>
            <td>
              <Link to={`/payout/${id}`} aria-label={`Show details for payout with id: ${id}`}>
                <ArrowRightIcon width="1rem" height="1rem" />
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
