import React, { FunctionComponent } from 'react'
import { CharityLogo } from '../../charities-review/charity-review/CharityLogo'

type Props = {
  campaign: CampaignSummaryType
  position: number
}

export const PromotedCampaign: FunctionComponent<Props> = ({ campaign, position }) => {
  return (
    <div className="p-lg flex items-center justify-center transition-colors ease-out">
      <CharityLogo url={campaign.charitySummary.logoUrl} size="small" />

      <div className="ml-md flex-1">
        <h3>
          {position}. {campaign.name}
        </h3>
        <h4>
          {new Date(campaign.startTime).toLocaleDateString()} - {new Date(campaign.endTime).toLocaleDateString()}
        </h4>
      </div>
    </div>
  )
}
