import React, { FunctionComponent } from 'react'
import { ListItem } from '../../../../common/styled'
import { Status } from '../../../../common/styled/Status'
import { ArrowRightIcon } from '../../../../icons'
import { useNavigate } from 'react-router'

type Props = {
  campaign: CampaignSummaryType
}

export const CampaignListItem: FunctionComponent<Props> = ({ campaign }) => {
  const navigate = useNavigate()

  return (
    <ListItem
      onClick={() => navigate(`/charities/${campaign.charitySummary.id}`)}
      ariaLabel={`Show campaign ${campaign.name}`}
      label={
        <div className="flex space-x-sm align-middle">
          <div className="w-2/3 self-center">
            <h2 className="font-bold">{campaign.name}</h2>
            <div className="mt-xs">{campaign.charitySummary.displayName}</div>
            <div className="mt-xs">
              {new Date(campaign.startTime).toLocaleDateString()} - {new Date(campaign.endTime).toLocaleDateString()}
            </div>
          </div>

          <div className="flex-1 text-right">
            <div className="inline-block mb-xs font-bold text-sm">Campaign</div>
            <Status status={campaign.status} />
          </div>

          <div className="self-center">
            <ArrowRightIcon width="1rem" height="1rem" />
          </div>
        </div>
      }
    />
  )
}
