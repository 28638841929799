import React, { FunctionComponent, ReactNode } from 'react'
import GoogleMap from 'google-map-react'
import { GOOGLE_MAPS_API_KEY } from '../../../lib'

type Props = {
  zoom: number
  center: { lat: number; lng: number }
  onMapLoaded: (maps: { map: any; maps: any; ref: Element | null }) => void
  children?: ReactNode
}

export const Map: FunctionComponent<Props> = ({ zoom, center, onMapLoaded, children }) => {
  // @ts-ignore
  return !window.Cypress ? (
    <GoogleMap
      bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
      defaultZoom={20}
      zoom={zoom}
      center={center}
      options={mapsSdk => ({
        streetViewControl: false,
        zoomControlOptions: {
          position: mapsSdk.ControlPosition.RIGHT_TOP
        },
        keyboardShortcuts: false
      })}
      onGoogleApiLoaded={onMapLoaded}
      yesIWantToUseGoogleMapApiInternals={true}
    >
      {children}
    </GoogleMap>
  ) : null
}
