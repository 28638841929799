import React, { useEffect } from 'react'
import { createBrowserRouter, Outlet, redirect, RouterProvider } from 'react-router-dom'
import { Header } from './components/Header'
import { UserInfoPage } from './components/pages/userInfo/UserInfoPage'
import { UsersPage } from './components/pages/UsersPage'
import { consumerSessionLoader, ConsumerSessionPage } from './components/pages/ConsumerSessionPage'
import { PayoutPage } from './components/pages/PayoutPage'
import { BagPage, bagtagLoader } from './components/pages/BagPage'
import { UnclaimedBagsPage } from './components/pages/unclaimedBags/UnclaimedBagsPage'
import { PendingBagsPage } from './components/pages/PendingBagsPage'
import { SearchPage } from './components/pages/SearchPage'
import {
  hasExternalLocationWritePermission,
  hasMytomraBackofficePermission,
  hasViewCharitiesPermission
} from './services/permission.service'
import { CharitiesHomePage } from './components/pages/charities/charity-home/CharitiesHomePage'
import { CharitiesListPage } from './components/pages/charities/charities-list/CharitiesListPage'
import { CharityReviewPage } from './components/pages/charities/charities-review/CharityReviewPage'
import { CharityReviewProvider } from './components/contexts'
import { ExternalLocationsPage } from './components/pages/external-locations/ExternalLocationsPage'
import { CampaignListPage } from './components/pages/charities/campaign-list/CampaignListPage'
import { ErrorBoundary } from 'react-error-boundary'
import { GeneralErrorBoundary, onGeneralErrorBoundary, RouterErrorBoundary } from './components/ErrorPage'
import { initDatadog } from '@tomra/datadog-browser-logging'
import { DATADOG_CONFIG, ENVIRONMENT, STAGE, VERSION } from './lib'

const AuthenticatedAppLayout = () => {
  return (
    <>
      <Header />

      <div className="max-w-screen-2xl mx-auto w-full h-full">
        <Outlet />
      </div>
    </>
  )
}

export const App = () => {
  const backofficeRoutes = hasMytomraBackofficePermission()
    ? [
        {
          path: 'user',
          element: <Outlet />,
          children: [
            {
              path: ':userId',
              element: <UserInfoPage />
            }
          ]
        },
        {
          path: 'users',
          element: <Outlet />,
          children: [
            {
              path: ':query',
              element: <UsersPage />
            }
          ]
        },
        {
          path: 'consumersession/:dpuid',
          loader: consumerSessionLoader,
          element: <ConsumerSessionPage />
        },
        {
          path: 'payout',
          element: <Outlet />,
          children: [
            {
              path: ':payoutId',
              element: <PayoutPage />
            }
          ]
        },
        {
          path: 'bagtag',
          element: <Outlet />,
          children: [
            {
              path: ':bagtag',
              loader: bagtagLoader,
              element: <BagPage />
            }
          ]
        },
        { path: 'unclaimedbags', element: <UnclaimedBagsPage /> },
        { path: 'pendingbags', element: <PendingBagsPage /> },
        { path: 'search', element: <SearchPage /> }
      ]
    : []

  const charityRoutes = hasViewCharitiesPermission()
    ? [
        {
          path: 'charities',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <CharitiesHomePage />
            },
            {
              path: 'list',
              element: <CharitiesListPage />
            },
            {
              path: ':charityId',
              element: (
                <CharityReviewProvider>
                  <CharityReviewPage />
                </CharityReviewProvider>
              )
            }
          ]
        },
        {
          path: 'campaigns',
          element: <Outlet />,
          children: [
            {
              path: 'list',
              element: <CampaignListPage />
            }
          ]
        }
      ]
    : []

  const externalLocationsRoutes = hasExternalLocationWritePermission()
    ? [
        {
          path: 'external-locations',
          element: <ExternalLocationsPage />
        }
      ]
    : []

  const authenticatedRoutes = [
    {
      element: <AuthenticatedAppLayout />,
      errorElement: <RouterErrorBoundary />,
      children: [
        ...backofficeRoutes,
        ...charityRoutes,
        ...externalLocationsRoutes,
        {
          path: 'search',
          element: <SearchPage />
        },
        {
          path: '*',
          loader: () => redirect('/search')
        }
      ]
    }
  ]

  useEffect(() => {
    initDatadog({ ...DATADOG_CONFIG, version: VERSION, env: `${ENVIRONMENT}.${STAGE}` })
  }, [])

  return (
    <ErrorBoundary fallbackRender={GeneralErrorBoundary} onError={onGeneralErrorBoundary}>
      <RouterProvider router={createBrowserRouter(authenticatedRoutes)} />
    </ErrorBoundary>
  )
}
