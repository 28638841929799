import React, { FormEvent, FunctionComponent, useContext, useState } from 'react'
import { CharityReviewContext } from '../../../../contexts'
import { ConfirmDialog } from '../../../../common'
import { API_HOST, authenticatedFetchData } from '../../../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { CharityAdminForm } from './CharityAdminForm'
import { FirebaseLogo } from './logo/FirebaseLogo'
import { hasCharityEditPermission } from '../../../../../services/permission.service'

export const CharityApplicationSummary: FunctionComponent = () => {
  const [reqStatus, setReqStatus] = useState<RequestStatusType>('idle')
  const [error, setError] = useState<string>('')
  const { charity, setCharity, allSectionsApproved } = useContext(CharityReviewContext)
  const [showConfirmDisableDialog, setShowConfirmDisableDialogDialog] = useState<boolean>(false)
  const [showConfirmActivateDialog, setShowConfirmActivateDialog] = useState<boolean>(false)
  const { id, status, logoUrl, createdAt, updatedAt } = charity

  const approve = (e: FormEvent) => {
    e.preventDefault()
    setError('')
    setReqStatus('pending')

    authenticatedFetchData(`${API_HOST}/charities/admin/v1/charities/${charity.id}/approve`, {
      method: 'PUT'
    })
      .run()
      .then((updated: CharityType) => {
        setReqStatus('idle')
        setCharity(updated)
      })
      .catch((error: any) => {
        logError(new Error('Failed to approve charity'), error)
        setReqStatus('failure')
        setError('Approve charity failed. Please try again.')
      })
  }

  const activate = () => {
    setShowConfirmActivateDialog(false)
    setError('')
    setReqStatus('pending')

    authenticatedFetchData(`${API_HOST}/charities/admin/v1/charities/${charity.id}/activate`, {
      method: 'PUT'
    })
      .run()
      .then((updated: CharityType) => {
        setReqStatus('idle')
        setCharity(updated)
      })
      .catch((error: any) => {
        logError(new Error('Failed to activate charity'), error)
        setReqStatus('failure')
        setError('Activate charity failed. Please try again.')
      })
  }

  const disable = () => {
    setShowConfirmDisableDialogDialog(false)
    setReqStatus('pending')

    authenticatedFetchData(`${API_HOST}/charities/admin/v1/charities/${charity.id}/disable`, {
      method: 'PUT'
    })
      .run()
      .then((updated: CharityType) => {
        setReqStatus('idle')
        setCharity(updated)
      })
      .catch((error: any) => {
        logError(new Error('Failed to disable charity'), error)
        setReqStatus('failure')
        setError('Disabling charity failed. Please try again.')
      })
  }

  const disableAll = reqStatus === 'pending'

  return (
    <div className="card relative pb-lg mb-md mt-lg pt-[5rem]">
      <div className="-mt-[12rem]">
        <FirebaseLogo charityId={id} thumbnailUrl={logoUrl} />
      </div>

      <div className="px-lg">
        <div className="mt-lg mb-xs font-bold text-storm">Charity admin</div>
        <CharityAdminForm />

        <div className="flex justify-between">
          <div>
            <div className="mt-lg mb-xs font-bold text-storm">Submitted</div>
            <div>{new Date(createdAt).toLocaleDateString()}</div>
          </div>
          <div>
            <div className="mt-lg mb-xs font-bold text-storm">Updated</div>
            <div>{new Date(updatedAt).toLocaleDateString()}</div>
          </div>
        </div>

        {error && <div className="alert alert-danger">{error}</div>}

        {hasCharityEditPermission() && (
          <div className="flex justify-end space-x-md mt-lg">
            <button
              className="btn btn-primary-danger flex-1"
              disabled={disableAll || status === 'DISABLED'}
              onClick={() => {
                setShowConfirmDisableDialogDialog(true)
              }}
            >
              Disable charity
            </button>

            {status === 'NEW' && (
              <button
                className="btn btn-primary-dark flex-1"
                disabled={!allSectionsApproved || disableAll}
                onClick={approve}
              >
                Approve charity
              </button>
            )}

            {status === 'DISABLED' && (
              <button
                className="btn btn-primary-dark flex-1"
                disabled={disableAll}
                onClick={() => setShowConfirmActivateDialog(true)}
              >
                Activate
              </button>
            )}
          </div>
        )}
      </div>

      {showConfirmDisableDialog && (
        <ConfirmDialog
          title="Disable charity?"
          description="The charity will not appear in the app."
          confirmButtonText="Disable"
          cancelButtonText="Cancel"
          onCancel={() => setShowConfirmDisableDialogDialog(false)}
          onConfirm={() => disable()}
        />
      )}

      {showConfirmActivateDialog && (
        <ConfirmDialog
          title="Activate charity?"
          description="The charity will appear in the app."
          confirmButtonText="Activate"
          cancelButtonText="Cancel"
          onCancel={() => setShowConfirmActivateDialog(false)}
          onConfirm={activate}
        />
      )}
    </div>
  )
}
