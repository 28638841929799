import React, { FunctionComponent } from 'react'
import { ArrowRightIcon } from '../icons'
import { BROWSER_TIMEZONE } from '../../lib'
import { DateWithUTCTooltip } from './DateWithUTCTooltip'
import { Link } from 'react-router-dom'

type Props = {
  consumerSessions: ConsumerSessionType[]
}

export const ConsumerSessionsTable: FunctionComponent<Props> = ({ consumerSessions }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Dpuid</th>
          <th>Date ({BROWSER_TIMEZONE})</th>
          <th>RVM serial number</th>
          <th>Item count</th>
          <th>Refund</th>
          <th>Latest status</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {consumerSessions.map(cs => (
          <tr key={cs.dpuid}>
            <td>{cs.dpuid}</td>
            <td>
              <DateWithUTCTooltip date={cs.created} />
            </td>
            <td>{cs.rvmSerial}</td>
            <td>{cs.itemCount}</td>
            <td>
              {cs.refund && cs.refund.toFixed(2)} {cs.currency}
            </td>
            <td>{cs.latestStatus || '-'}</td>
            <td>
              <Link to={`/consumersession/${cs.dpuid}`} aria-label={`View consumer session ${cs.dpuid}`}>
                <ArrowRightIcon width="1rem" height="1rem" />
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
