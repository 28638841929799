import React, { FunctionComponent } from 'react'
import { ArrowDownIcon, ArrowUpIcon, DeleteIcon } from '../../../../icons'

type Props = {
  campaign: CampaignSummaryType
  disabled: boolean
  moveUp: (campaignId: string) => void
  moveDown: (campaignId: string) => void
  removeItem: () => void
}

export const PromotedCampaignActions: FunctionComponent<Props> = ({
  moveUp,
  moveDown,
  disabled,
  campaign,
  removeItem
}) => {
  return (
    <>
      <button
        className="btn btn-icon"
        aria-label={`Move ${campaign.name} campaign up`}
        onClick={() => moveUp(campaign.id)}
        disabled={disabled}
      >
        <ArrowUpIcon width="1.5rem" height="1.5rem" />
      </button>
      <button
        className="btn btn-icon"
        aria-label={`Move ${campaign.name} campaign down`}
        onClick={() => moveDown(campaign.id)}
        disabled={disabled}
      >
        <ArrowDownIcon width="1.5rem" height="1.5rem" />
      </button>
      <button
        className="btn btn-icon"
        aria-label={`Remove ${campaign.name} campaign from promoted`}
        onClick={removeItem}
        disabled={disabled}
      >
        <DeleteIcon width="1.5rem" height="1.5rem" />
      </button>
    </>
  )
}
