import React, { FunctionComponent, useEffect, useState } from 'react'
import { CharityLogo } from '../CharityLogo'
import { AlertIcon, CheckmarkIcon, EditIcon, UploadIcon, ViewIcon } from '../../../../../icons'
import { ImageCropper } from './ImageCropper'
import { mytomraCharityAuth, mytomraCharityStorage } from '../../../../../../firebase'
import { API_HOST, authenticatedFetchData } from '../../../../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { fetchLogoOriginalUrl, uploadImage, verifyCustomAuth } from '../../../../../../services/firebase.service'
import { ref, getBlob } from 'firebase/storage'
import { hasCharityEditPermission } from '../../../../../../services/permission.service'

type Props = {
  charityId: string
  thumbnailUrl: string
}

export const FirebaseLogo: FunctionComponent<Props> = ({ charityId, thumbnailUrl }) => {
  const [status, setStatus] = useState<RequestStatusType>('idle')
  const [fileToCrop, setFileToCrop] = useState<File | null>(null)
  const [logoUrl, setLogoUrl] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    setLogoUrl(thumbnailUrl)
  }, [thumbnailUrl])

  const uploadLogo = async (file: Blob | File) => {
    setStatus('pending')
    setError('')

    try {
      // @ts-ignore
      if (!mytomraCharityAuth.currentUser && !window.Cypress) {
        await verifyCustomAuth()
      }

      const logoFileName = `${charityId}-${Date.now()}`
      const organizationLogoUrl = await uploadImage(logoFileName, file)
      setLogoUrl(organizationLogoUrl)

      await authenticatedFetchData(`${API_HOST}/charities/admin/v1/charities/${charityId}/notify-logo-updated`, {
        method: 'POST',
        body: JSON.stringify({ logoUrl: organizationLogoUrl })
      }).run()

      setStatus('success')
    } catch (error) {
      setStatus('failure')
      setError('Image upload failed. Please try again.')
      logError(new Error('Failed to upload logo file'), error as Error)
    }
  }

  const viewLogo = async () => {
    try {
      setError('')
      setStatus('pending')

      if (!mytomraCharityAuth.currentUser) {
        await verifyCustomAuth()
      }

      window.open(thumbnailUrl, '_blank')
      setStatus('idle')
    } catch (error) {
      setError('Image download failed.')
      setStatus('failure')
      logError(new Error('Failed to download logo file'), error as Error)
    }
  }

  const editLogo = async () => {
    try {
      setError('')
      setStatus('pending')

      if (!mytomraCharityAuth.currentUser) {
        await verifyCustomAuth()
      }

      const imageUrl = await fetchLogoOriginalUrl(charityId, thumbnailUrl).catch(error => {
        if (error.code) {
          return thumbnailUrl
        }

        throw error
      })

      const storageRef = ref(mytomraCharityStorage, imageUrl)
      const blob = await getBlob(storageRef)
      setFileToCrop(blob as File)
      setStatus('idle')
    } catch (error) {
      setError('Image download failed.')
      setStatus('failure')
      logError(new Error('Failed to download logo file'), error as Error)
    }
  }

  return (
    <>
      <div className="relative w-full h-full -mt-[12rem]">
        <div className="mx-auto">
          <CharityLogo url={logoUrl} size="large" busy={status === 'pending'} />
        </div>

        {hasCharityEditPermission() && (
          <div className="absolute left-1/2 -translate-x-1/2 -bottom-[2.5rem] z-[2]">
            <div className="flex space-x-sm bg-white ring-2 ring-dust shadow-sm rounded px-sm">
              <button className="btn btn-icon flex flex-col items-center justify-center" onClick={viewLogo}>
                <div className="rotate-180">
                  <ViewIcon width="1.5rem" height="1.5rem" aria-hidden="true" />
                </div>
                <span className="text-sm">View</span>
              </button>

              <button
                className="btn btn-icon flex flex-col items-center justify-center"
                onClick={editLogo}
                aria-label="Edit logo"
              >
                <div className="rotate-180">
                  <EditIcon width="1.5rem" height="1.5rem" aria-hidden="true" />
                </div>
                <span className="text-sm">Edit</span>
              </button>

              <label
                htmlFor="uploadLogo"
                className="btn btn-icon flex flex-col items-center justify-center cursor-pointer"
              >
                <UploadIcon width="1.5rem" height="1.5rem" aria-hidden="true" />
                <span className="text-sm">Upload</span>
              </label>
              <input
                id="uploadLogo"
                type="file"
                className="absolute opacity-0 bottom-[100%] left-[100%]"
                accept="image/png, image/jpeg, image/jpg"
                onClick={evt => {
                  // need to reset the input value, or else onChange
                  // wont fire upon selecting the same image multiple times
                  const element = evt.target as HTMLInputElement
                  element.value = ''
                }}
                onChange={(evt: any) => evt.target.files[0] && setFileToCrop(evt.target.files[0])}
              />
            </div>
          </div>
        )}
      </div>

      {error && (
        <div className="font-bold text-red mt-md flex justify-center space-x-sm">
          <AlertIcon width="1.5rem" height="1.5rem" />
          <span>{error}</span>
        </div>
      )}

      {status === 'success' && (
        <div className="font-bold text-green-dark mt-md flex justify-center space-x-sm">
          <CheckmarkIcon width="1.5rem" height="1.5rem" />
          <span>Logo was updated!</span>
        </div>
      )}

      {fileToCrop && <ImageCropper fileToCrop={fileToCrop} onCropped={uploadLogo} />}
    </>
  )
}
