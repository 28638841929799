import React, { ChangeEvent, createRef, FunctionComponent, useState } from 'react'
import { fetchExternalLocationDiff, uploadExternalLocationsCSV } from '../../../../services/external-locations.service'
import { logError } from '@tomra/datadog-browser-logging'
import { ExternalLocationValidationError } from './ExternalLocationValidationError'
import { ErrorDialog, StatusDialog } from '../../../common'

type Props = {
  className?: string
  onDiffResponseReceived: (diffResponse: ExternalLocationDiffResponseType) => void
}

const statusMap = {
  uploading: 'Uploading file...',
  parsing: 'Reading contents...'
}

export const CreateExternalLocationsDiff: FunctionComponent<Props> = ({ onDiffResponseReceived }) => {
  const fileUploadRef = createRef<HTMLInputElement>()
  const [status, setStatus] = useState<RequestStatusType | 'uploading' | 'parsing' | 'parsing_failed'>('idle')

  const [validationErrors, setValidationErrors] = useState<ExternalLocationValidationErrorsType | null>(null)

  const fetchDiff = (fileId: string) => {
    setStatus('parsing')

    fetchExternalLocationDiff(fileId)
      .then(diff => {
        onDiffResponseReceived(diff)
        setStatus('idle')
      })
      .catch((error: any) => {
        if (error.status === 400 && error.body.data.errors) {
          setValidationErrors(error.body.data.errors)
          setStatus('parsing_failed')
        } else {
          setStatus('failure')
          logError(new Error('Unable to parse external locations file'), error)
        }
      })
  }

  const uploadCSVFile = (file: File) => {
    setStatus('uploading')

    uploadExternalLocationsCSV(file)
      .then(fetchDiff)
      .catch(() => setStatus('failure'))
  }

  const onFileChanged = async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    const file = files && files.length > 0 ? files[0] : undefined

    if (file) {
      uploadCSVFile(file)
    }
  }

  const reset = (evt: any) => {
    // Reset to make sure the event fires on selecting the same file
    const element = evt.target as HTMLInputElement
    element.value = ''
  }

  return (
    <>
      <div>
        <h2 className="text-md mb-sm">Upload CSV</h2>
        <input
          aria-label="Upload CSV"
          ref={fileUploadRef}
          type="file"
          onChange={onFileChanged}
          onClick={reset}
          accept="text/csv"
        />
      </div>

      {status === 'uploading' || status === 'parsing' ? (
        <StatusDialog text={statusMap[status]} />
      ) : status === 'failure' ? (
        <ErrorDialog
          title="Upload failed"
          description="Something went wrong while uploading the file. Please try again later."
          onClose={() => setStatus('idle')}
        />
      ) : status === 'parsing_failed' ? (
        <ErrorDialog title="Reading file failed" onClose={() => setStatus('idle')}>
          <p className="text-red mb-md">Please correct the errors below and try again:</p>
          <ExternalLocationValidationError validationErrors={validationErrors!} />
        </ErrorDialog>
      ) : null}
    </>
  )
}
