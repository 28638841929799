import React, { useState } from 'react'
import { API_HOST, authenticatedFetchData } from '../../../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { PaginationButtons } from '../../../../common/PaginationButtons'
import { CampaignListItem } from './CampaignListItem'
import { CharityListItem } from './CharitySearchListItem'
import { SearchForm } from '../../../../common/SearchField'

const limit = 5

const searchCharities = (searchTerm: string, newOffset: number, excludeDisabled: boolean) => {
  return authenticatedFetchData(
    `${API_HOST}/charities/admin/v1/search/charities?name=${encodeURIComponent(searchTerm)}&limit=${
      limit + 1
    }&offset=${newOffset}&excludeDisabled=${excludeDisabled}`
  )
    .run()
    .then(response => {
      const hasMoreResults = response.length > limit

      return {
        hasMoreResults,
        results: hasMoreResults ? response.slice(0, response.length - 1) : response
      }
    })
}

const searchCampaigns = (searchTerm: string, newOffset: number) => {
  return authenticatedFetchData(
    `${API_HOST}/charities/admin/v1/search/campaigns?name=${encodeURIComponent(searchTerm)}&limit=${limit + 1}&offset=${newOffset}`
  )
    .run()
    .then(response => {
      const hasMoreResults = response.length > limit

      return {
        hasMoreResults,
        results: hasMoreResults ? response.slice(0, response.length - 1) : response
      }
    })
}

export const CharitySearch = () => {
  const [status, setStatus] = useState<RequestStatusType>('idle')
  const [offset, setOffset] = useState<number>(0)
  const [includeDisabled, setIncludeDisabled] = useState(false)
  const [canGoNext, setCanGoNext] = useState<boolean>(false)
  const [searchResults, setSearchResults] = useState<
    | {
        charities: CharitySummaryType[]
        campaigns: CampaignSummaryType[]
      }
    | undefined
  >(undefined)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [hasSearched, setHasSearched] = useState<boolean>(false)

  const hasResults = Boolean(
    searchResults && (searchResults.charities.length > 0 || searchResults.campaigns.length > 0)
  )
  const canGoBack = Boolean(offset > 0 && hasResults)
  const showPaginationButtons = (searchResults && hasResults && canGoNext) || canGoBack

  const clearResults = () => {
    setSearchResults({ charities: [], campaigns: [] })
    setHasSearched(false)
  }

  const search = (searchTerm: string, newOffset = 0) => {
    setStatus('pending')
    setOffset(newOffset)

    Promise.all([searchCharities(searchTerm, newOffset, !includeDisabled), searchCampaigns(searchTerm, newOffset)])
      .then(results => {
        setHasSearched(true)
        setStatus('idle')
        const [charities, campaigns] = results
        setCanGoNext(charities.hasMoreResults || charities.hasMoreResults)

        setSearchResults({
          charities: charities.results,
          campaigns: campaigns.results
        })
      })
      .catch((error: any) => {
        logError(new Error('Failed to load data'), error)
        setError('Something went wrong. Please try again.')
        setStatus('failure')
      })
  }

  const items = hasResults
    ? [
        // searchResults will never be undefined here so we can safely use non-null assertion
        ...searchResults!.charities.map(charity => <CharityListItem key={charity.id} charity={charity} />),
        ...searchResults!.campaigns.map(campaign => <CampaignListItem key={campaign.id} campaign={campaign} />)
      ]
    : []

  return (
    <div className="card">
      <h2 id="searchTitle" className="inline-block m-lg text-lg">
        Search
      </h2>

      <div className="pr-md pb-lg pl-lg">
        <SearchForm
          label="Search for charity or campaign"
          disabled={status === 'pending'}
          searchTerm={searchTerm}
          onChange={value => {
            clearResults()
            setSearchTerm(value)
          }}
          onSubmit={() => search(searchTerm, 0)}
          clearResults={clearResults}
        >
          <label className="pt-sm flex items-center">
            <input
              id="include-disabled-checkbox"
              className="checkbox mr-sm"
              type="checkbox"
              checked={includeDisabled}
              onChange={() => setIncludeDisabled(!includeDisabled)}
            />
            <label htmlFor="include-disabled-checkbox">Include disabled charities</label>
          </label>
        </SearchForm>

        {error && (
          <div className="pt-md">
            <div className="alert alert-danger">{error}</div>
          </div>
        )}
      </div>

      {items && <ul aria-label={`Search results for ${searchTerm}`}>{items}</ul>}

      {status === 'pending' && !showPaginationButtons ? (
        <div className="pr-md pb-lg pl-lg flex justify-center">
          <div className="loadingSpinner" />
        </div>
      ) : null}

      {hasSearched && !hasResults && (
        <div className="pr-md pb-lg pl-lg">No results matched &quot;{searchTerm}&quot;</div>
      )}

      {showPaginationButtons && (
        <PaginationButtons
          isLoading={status === 'pending'}
          limit={limit}
          offset={offset}
          onOffsetChange={newOffset => search(searchTerm, newOffset)}
          canGoBack={canGoBack}
          canGoNext={canGoNext}
        />
      )}
    </div>
  )
}
