import React, { FormEvent, FunctionComponent, useState, useEffect } from 'react'
import { Form, FormField } from './Form'
import { SectionData } from './SectionData'
import { ConfirmDialog } from './index'
import { hasCharityEditPermission } from '../../services/permission.service'

type Props = {
  isEditMode: boolean
  formFields: FormField[]
  initialValues: { [key: string]: any }
  displayValueMap: { [key: string]: string }
  onUpdate: (values: { [key: string]: any }) => void
  onEdit: () => void
  editCancelled: () => void
  isUpdating: boolean
}

export const EditableData: FunctionComponent<Props> = ({
  isEditMode,
  formFields,
  initialValues,
  displayValueMap,
  onUpdate,
  onEdit,
  editCancelled,
  isUpdating
}) => {
  const [showConfirmCancelDialog, setShowConfirmCancelDialog] = useState<boolean>(false)
  const [formValues, setFormValues] = useState<{ [key: string]: any }>(initialValues)
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})
  const [formIsValid, setFormIsValid] = useState<boolean>(true)

  useEffect(() => setFormValues(initialValues), [initialValues])

  const onFormChange = (values: { [key: string]: any }, errors: { [key: string]: any }) => {
    setFormValues({ ...formValues, ...values })
    setFormErrors(errors || {})
    setFormIsValid(errors ? Object.keys(errors).length === 0 : true)
  }

  const _onUpdate = (e: FormEvent) => {
    e.preventDefault()
    onUpdate(formValues)
  }

  const confirmCancel = (e: FormEvent) => {
    e.preventDefault()
    setShowConfirmCancelDialog(true)
  }

  return (
    <>
      <div className="flex">
        <div className="flex-1">
          {isEditMode ? (
            <Form formFields={formFields} state={formValues} errors={formErrors} onChange={onFormChange} />
          ) : (
            <SectionData fields={formFields} values={formValues} displayValueMap={displayValueMap} />
          )}
        </div>
        <div className="text-right">
          {!isEditMode && hasCharityEditPermission() && (
            <button className="btn" onClick={onEdit}>
              Edit
            </button>
          )}
        </div>
      </div>

      {isEditMode && hasCharityEditPermission() && (
        <div className="flex space-x-lg justify-end">
          <button className="btn" onClick={confirmCancel}>
            Cancel edit
          </button>
          <button
            className="btn btn-primary-dark flex items-center justify-center"
            onClick={_onUpdate}
            disabled={!formIsValid || isUpdating}
          >
            {isUpdating ? <div className="loadingSpinner" /> : 'Update'}
          </button>
        </div>
      )}

      {showConfirmCancelDialog && (
        <ConfirmDialog
          title="Are you sure you want to cancel?"
          description="All changes made to this section will be lost."
          cancelButtonText="No, don't cancel"
          confirmButtonText="Yes, cancel"
          onCancel={() => {
            setShowConfirmCancelDialog(false)
          }}
          onConfirm={() => {
            setShowConfirmCancelDialog(false)
            setFormValues(initialValues)
            editCancelled()
          }}
        />
      )}
    </>
  )
}
