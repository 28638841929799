import React, { useState, useEffect, FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { authenticatedFetchData, API_HOST } from '../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { PendingBagsTable } from '../../common/PendingBagsTable'

export const UserPendingBags: FunctionComponent = () => {
  const { userId } = useParams()
  const [pendingBagsList, setPendingBagsList] = useState<PendingBagType[]>()
  const [requestStatus, setRequestStatus] = useState<string>('loading')

  useEffect(() => {
    const f = authenticatedFetchData(
      `${API_HOST}/mytomra/v1.0/hotlinetool/pendingbags?userid=${encodeURIComponent(userId || '')}`
    )

    f.run().then(
      (pendingBagsList: PendingBagType[]) => {
        setRequestStatus('')
        setPendingBagsList(pendingBagsList)
      },
      (error: Error) => {
        if (error.name !== 'AbortError') {
          setRequestStatus('failed')
          logError(new Error('Failed to fetch pending bags for user'), error)
        }
      }
    )

    return () => f.abort()
  }, [userId])

  if (pendingBagsList) {
    return <PendingBagsTable pendingBags={pendingBagsList} />
  }

  if (requestStatus === 'failed') {
    return <div className="alert alert-danger centerAbsolute">Whoopsie! Failed to fetch pending bags for user.</div>
  }

  return (
    <div className="centerAbsolute">
      <div className="loadingSpinner" />
    </div>
  )
}
