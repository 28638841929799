import React, { createContext, Dispatch, FunctionComponent, ReactNode, useContext, useEffect, useState } from 'react'
import { API_HOST, authenticatedFetchData } from '../../../../../lib'
import { logError } from '@tomra/datadog-browser-logging'

type PromotedContext = {
  hasProvider: boolean
  promoted: CampaignSummaryType[]
  setPromoted: Dispatch<CampaignSummaryType[]>
  status: RequestStatusType
  setStatus: Dispatch<RequestStatusType>
  updatingCampaign: string
  setUpdatingCampaign: Dispatch<string>
  error: string
  setError: Dispatch<string>
  operatingState: CharityOperatingState
  setOperatingState: Dispatch<CharityOperatingState>
}

const PromotedCampaignsContext = createContext<PromotedContext>({
  hasProvider: false,
  promoted: [],
  setPromoted: () => {},
  status: 'pending',
  setStatus: () => {},
  updatingCampaign: '',
  setUpdatingCampaign: () => {},
  error: '',
  setError: () => {},
  operatingState: 'NSW',
  setOperatingState: () => {}
})

const maxPromotedCampaigns = 10

type Props = {
  children?: ReactNode
}

export const PromotedCampaignsProvider: FunctionComponent<Props> = ({ children }) => {
  const [hasProvider] = useState(true)
  const [operatingState, setOperatingState] = useState<CharityOperatingState>('NSW')
  const [promoted, setPromoted] = useState<CampaignSummaryType[]>([])
  const [status, setStatus] = useState<RequestStatusType>('pending')
  const [error, setError] = useState<string>('')
  const [updatingCampaign, setUpdatingCampaign] = useState<string>('')

  useEffect(() => {
    setStatus('pending')

    authenticatedFetchData(`${API_HOST}/charities/admin/v1/promoted`, { headers: { 'Charity-State': operatingState } })
      .run()
      .then((response: CampaignSummaryType[]) => {
        setStatus('idle')
        setPromoted(response)
      })
      .catch((error: any) => {
        logError(error, new Error('Failed to fetch promoted campaigns'))
        setStatus('failure')
        setError('Failed to fetch promoted campaigns')
      })
  }, [operatingState])

  return (
    <PromotedCampaignsContext.Provider
      value={{
        hasProvider,
        promoted,
        setPromoted,
        status,
        setStatus,
        updatingCampaign,
        setUpdatingCampaign,
        error,
        setError,
        operatingState,
        setOperatingState
      }}
    >
      {children}
    </PromotedCampaignsContext.Provider>
  )
}

const repositionCampaign = (existing: CampaignSummaryType[], oldPosition: number, newPosition: number) => {
  const copy = [...existing]
  const [reorderedItem] = copy.splice(oldPosition, 1)
  copy.splice(newPosition, 0, reorderedItem)
  return copy
}

export const usePromotedCampaigns = () => {
  const context = useContext(PromotedCampaignsContext)

  if (!context.hasProvider) {
    throw new Error('usePromotedCampaigns must be used within a PromotedCampaignsProvider')
  }

  const {
    promoted,
    setPromoted,
    status,
    setStatus,
    error,
    setError,
    updatingCampaign,
    setUpdatingCampaign,
    operatingState,
    setOperatingState
  } = context

  const updateItemOrder = (oldPosition: number, newPosition: number) => {
    if (oldPosition === newPosition) {
      return
    }

    const prevOrder = [...promoted] // Keep this in case updating order fails

    const campaignToUpdate = promoted[oldPosition]
    setUpdatingCampaign(campaignToUpdate.id)
    setPromoted(repositionCampaign(promoted, oldPosition, newPosition))

    authenticatedFetchData(`${API_HOST}/charities/admin/v1/promoted/${campaignToUpdate.id}/position/${newPosition}`, {
      method: 'PUT',
      headers: { 'Charity-State': operatingState }
    })
      .run()
      .then((response: CampaignSummaryType[]) => {
        setUpdatingCampaign('')
        setPromoted(response)
      })
      .catch((error: any) => {
        setUpdatingCampaign('')
        setPromoted(prevOrder)
        setError('Failed to reorder promoted campaigns')
        logError(error, new Error('Failed to reorder promoted campaigns'))
      })
  }

  const removeCampaign = (campaignId: string) => {
    setUpdatingCampaign(campaignId)

    authenticatedFetchData(`${API_HOST}/charities/admin/v1/promoted/${campaignId}`, {
      method: 'DELETE',
      headers: { 'Charity-State': operatingState }
    })
      .run()
      .then((updatedList: CampaignSummaryType[]) => {
        setPromoted(updatedList)
        setUpdatingCampaign('')
      })
      .catch((error: any) => {
        logError(error, new Error('Failed to remove promote campaigns'))
        setUpdatingCampaign('')
        setError('Failed to remove promoted campaign')
      })
  }

  const promoteCampaign = (campaignId: string) => {
    setStatus('pending')

    authenticatedFetchData(`${API_HOST}/charities/admin/v1/promoted/${campaignId}`, {
      method: 'POST',
      headers: { 'Charity-State': operatingState }
    })
      .run()
      .then((updatedList: CampaignSummaryType[]) => {
        setStatus('idle')
        setPromoted(updatedList)
      })
      .catch((error: any) => {
        logError(error, new Error('Failed to promote campaign'))
        setStatus('failure')
        setError('Failed to promote campaign')
      })
  }

  return {
    promoted,
    status,
    error,
    updateItemOrder,
    updatingCampaign,
    removeCampaign,
    promoteCampaign,
    maxPromotedCampaigns,
    operatingState,
    setOperatingState
  }
}
