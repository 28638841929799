import React, { FunctionComponent, ReactNode } from 'react'
import { Dialog } from '../Dialog'

type Props = {
  title: string
  description?: string
  onClose: () => void
  children?: ReactNode
}

export const AlertDialog: FunctionComponent<Props> = ({ title, description, onClose, children }) => {
  return (
    <Dialog ariaLabel={title}>
      <div className="card p-xl flex flex-col">
        <h1 className="text-xl mb-md">{title}</h1>

        {description && <p>{description}</p>}
        {children}

        <button className="mt-lg btn px-xl self-end" onClick={onClose}>
          Ok
        </button>
      </div>
    </Dialog>
  )
}
