import React, { FunctionComponent } from 'react'

type Props = {
  validationErrors: ExternalLocationValidationErrorsType
}

export const ExternalLocationValidationError: FunctionComponent<Props> = ({ validationErrors }) => {
  const { duplicateIds, linesWithWrongColumnCount, linesWithMissingRequiredValues, linesWithIllegalPositionValues } =
    validationErrors

  return (
    <ul className="ml-md list-disc">
      {duplicateIds.length > 0 && <li className="px-sm">Duplicate ids on row {duplicateIds.join(', ')}</li>}

      {linesWithIllegalPositionValues.length > 0 && (
        <li className="px-sm">Invalid coordinates on row {linesWithIllegalPositionValues.join(', ')}</li>
      )}

      {linesWithMissingRequiredValues.length > 0 && (
        <li className="px-sm">Missing required fields on row {linesWithMissingRequiredValues.join(', ')}</li>
      )}

      {linesWithWrongColumnCount.length > 0 && (
        <li className="px-sm">Missing columns on row {linesWithWrongColumnCount.join(', ')}</li>
      )}
    </ul>
  )
}
