import React, { useContext, useState } from 'react'
import { CharityReviewContext } from '../../../../../contexts'
import { API_HOST, authenticatedFetchData } from '../../../../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { EditableData, FormField } from '../../../../../common'
import { hasCharityEditPermission } from '../../../../../../services/permission.service'

const emptyContact = {
  firstName: '',
  lastName: '',
  position: '',
  email: '',
  phone: ''
}

export const ContactsSection = () => {
  const { charity, setCharity, editingSection, setEditingSection } = useContext(CharityReviewContext)
  const { primaryContact, secondaryContact } = charity
  const [status, setStatus] = useState<RequestStatusType>('idle')
  const [newSecondaryContact, setNewSecondaryContact] = useState(secondaryContact)

  const onUpdate = (key: string, data: { [key: string]: any }) => {
    setStatus('pending')

    authenticatedFetchData(`${API_HOST}/charities/admin/v1/charities/${charity.id}/${key}`, {
      method: 'PUT',
      body: JSON.stringify(data)
    })
      .run()
      .then((response: ContactType) => {
        setStatus('idle')
        setCharity({ ...charity, [key]: response })
        setEditingSection('')
      })
      .catch((error: any) => {
        setStatus('failure')
        logError(new Error(`Failed to update ${key}`), error)
      })
  }

  const formFields: FormField[] = [
    { label: 'First name', name: 'firstName', required: true },
    { label: 'Last name', name: 'lastName', required: true },
    { label: 'Position', name: 'position', required: false },
    { label: 'E-mail', name: 'email', required: true, pattern: 'email' },
    { label: 'Phone', name: 'phone', required: false }
  ]

  const editCancelled = () => {
    setEditingSection('')
  }

  return (
    <>
      <div className="flex space-x-lg">
        <div className="flex-1">
          <h3 className="mb-md text-md font-bold text-storm">Primary contact</h3>

          <EditableData
            isEditMode={editingSection === 'primary_contact'}
            formFields={formFields}
            initialValues={primaryContact}
            displayValueMap={{}}
            onEdit={() => setEditingSection('primary_contact')}
            isUpdating={status === 'pending'}
            onUpdate={values => onUpdate('primary_contact', values)}
            editCancelled={editCancelled}
          />
        </div>
        <div className="flex-1">
          <h3 className="mb-md text-md font-bold text-storm">Secondary contact</h3>

          {!newSecondaryContact ? (
            hasCharityEditPermission() ? (
              <div className="flex flex-col items-end">
                <button
                  type="button"
                  className="btn"
                  onClick={() => {
                    setNewSecondaryContact(emptyContact)
                    setEditingSection('secondary_contact')
                  }}
                >
                  Add contact
                </button>
              </div>
            ) : (
              <div>No secondary contact added.</div>
            )
          ) : (
            <EditableData
              isEditMode={editingSection === 'secondary_contact'}
              formFields={formFields}
              initialValues={secondaryContact ?? newSecondaryContact}
              displayValueMap={{}}
              onEdit={() => setEditingSection('secondary_contact')}
              isUpdating={status === 'pending'}
              onUpdate={values => onUpdate('secondary_contact', values)}
              editCancelled={editCancelled}
            />
          )}
        </div>
      </div>

      {status === 'failure' && (
        <div className="text-red mt-md">Failed to update contact information. Please try again.</div>
      )}
    </>
  )
}
