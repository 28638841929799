import React, { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react'
import Cropper from 'react-easy-crop'
import { Dialog } from '../../../../../common/Dialog'
import { ZoomInIcon, ZoomOutIcon } from '../../../../../icons'

const browserNotSupported = !!window.navigator.userAgent.toLowerCase().match(/(edge|trident)/g)

type Props = {
  fileToCrop: File
  onCropped: (blob: Blob, filename: string) => void
}

export const ImageCropper: FunctionComponent<Props> = ({ fileToCrop, onCropped }) => {
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [warningMsg, setWarningMsg] = useState<string>('')
  const [processingImage, setProcessingImage] = useState<HTMLImageElement | null>(null)
  const [processingImageFile, setProcessingImageFile] = useState<File | null>(null)
  const [showCropDialog, setShowCropDialog] = useState<boolean>(false)
  const [croppedImageFile, setCroppedImageFile] = useState<File | Blob | null>(null)
  const [cropCoordinates, setCropCoordinates] = useState({ x: 0, y: 0 })
  const [cropZoom, setCropZoom] = useState(0.5)
  const [backgroundColor, setBackgroundColor] = useState<'white' | 'black'>('white')
  const [imgSrc, setImgSrc] = useState<string>()

  useEffect(() => {
    if (fileToCrop) {
      const fr = new FileReader()
      fr.onload = () => setImgSrc(fr.result as string)
      fr.readAsDataURL(fileToCrop)
    }
  }, [fileToCrop, onCropped])

  const onImageLoad = (event: SyntheticEvent) => {
    const target = event.target as HTMLImageElement

    if (target.naturalHeight < 400 || target.naturalWidth < 400) {
      setWarningMsg('This image is quite small. Cropping it further should be avoided.')
    }

    if (browserNotSupported && target.naturalWidth !== target.naturalHeight) {
      setErrorMsg('Height and width must be identical (i.e a square)')
    } else if (browserNotSupported) {
      onCropped(fileToCrop, fileToCrop.type)
    } else {
      setProcessingImage(target)
      setProcessingImageFile(fileToCrop)
      setShowCropDialog(true)
    }
  }

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    const croppedWidth = croppedAreaPixels.width
    const croppedHeight = croppedAreaPixels.height

    const canvas = document.createElement('canvas')
    canvas.width = croppedWidth
    canvas.height = croppedHeight
    const context = canvas?.getContext('2d')

    if (context) {
      context.fillStyle = backgroundColor
      context.fillRect(0, 0, canvas.width, canvas.height)

      const sourceX = croppedAreaPixels.x
      const sourceY = croppedAreaPixels.y
      const sourceWidth = croppedWidth
      const sourceHeight = croppedHeight
      const destWidth = croppedWidth
      const destHeight = croppedHeight
      const destX = 0
      const destY = 0

      context.drawImage(
        processingImage!,
        sourceX,
        sourceY,
        sourceWidth,
        sourceHeight,
        destX,
        destY,
        destWidth,
        destHeight
      )

      canvas.toBlob(setCroppedImageFile, processingImageFile?.type)
    }
  }

  const onDone = () => {
    onCropped(croppedImageFile!, processingImageFile!.name)
    closeCropDialog()
  }

  const closeCropDialog = () => {
    setErrorMsg('')
    setShowCropDialog(false)
  }

  return (
    <>
      {errorMsg && (
        <Dialog ariaLabel="Crop failed" onClose={() => setErrorMsg('')}>
          <div className="card p-lg" style={{ minWidth: '50vw' }}>
            <div className="text-red">{errorMsg}</div>

            <div className="flex justify-end mt-lg">
              <button className="btn" onClick={closeCropDialog}>
                Ok
              </button>
            </div>
          </div>
        </Dialog>
      )}

      <img aria-hidden={true} alt="hidden image" src={imgSrc} onLoad={onImageLoad} style={{ width: 0, height: 0 }} />

      {showCropDialog && (
        <Dialog ariaLabel="Crop image">
          <div style={{ width: '80vw', height: '80vh' }}>
            <div className="w-full h-full flex flex-col items-center justify-between">
              <div />

              <Cropper
                image={processingImage?.src}
                crop={cropCoordinates}
                aspect={1}
                onCropChange={setCropCoordinates}
                onCropComplete={onCropComplete}
                onZoomChange={setCropZoom}
                minZoom={0.2}
                cropShape="round"
                zoom={cropZoom}
                restrictPosition={false}
                style={{ containerStyle: { backgroundColor } }}
                showGrid={false}
              />

              {warningMsg && <div className="absolute top-lg alert alert-warning w-full">{warningMsg}</div>}

              <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex items-center p-md space-x-4 z-10 bg-white rounded-half mb-md shadow-lg">
                <div className="relative group">
                  <div className="btn rounded-none border-0 shadow-none">Need help?</div>
                  <span className="opacity-0 group-hover:opacity-100 transition absolute left-[1.5625rem] bottom-[2rem] min-w-[15rem] rounded-half bg-midnight text-white p-md shadow-lg">
                    Place your logo within the circle by moving it with your cursor and/or zooming in and out. This will
                    reflect how your logo will look like in the app.
                  </span>
                </div>

                <div className="flex items-center">
                  <div className="mr-sm">Background</div>
                  <button
                    aria-label="Set image background to black"
                    className="btn btn-icon !p-xs"
                    onClick={() => {
                      setBackgroundColor('black')
                      // in order to trigger onCropComplete which triggers canvas background to change
                      setCropZoom(cropZoom + 0.0001)
                    }}
                  >
                    <div
                      className={`w-2 h-2 rounded bg-black border ${
                        backgroundColor === 'black' ? 'outline outline-red-transparent' : ''
                      }`}
                    />
                  </button>
                  <button
                    aria-label="Set image background to white"
                    className="btn btn-icon !p-xs"
                    onClick={() => {
                      setBackgroundColor('white')
                      // in order to trigger onCropComplete which triggers canvas background to change
                      setCropZoom(cropZoom + 0.0001)
                    }}
                  >
                    <div
                      className={`w-2 h-2 rounded bg-white border ${
                        backgroundColor === 'white' ? 'outline outline-red-transparent' : ''
                      }`}
                    />
                  </button>
                </div>

                <div className="flex">
                  <button
                    aria-label="Zoom out"
                    className="btn btn-icon"
                    onClick={() => cropZoom >= 0.2 && setCropZoom(cropZoom - 0.1)}
                  >
                    <ZoomOutIcon width="2rem" height="2rem" />
                  </button>
                  <button aria-label="Zoom in" className="btn btn-icon" onClick={() => setCropZoom(cropZoom + 0.1)}>
                    <ZoomInIcon width="2rem" height="2rem" />
                  </button>
                </div>

                <div className="flex space-x-md">
                  <button className="btn" onClick={closeCropDialog}>
                    Cancel
                  </button>

                  <button className="btn btn-primary-dark" disabled={croppedImageFile === null} onClick={onDone}>
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  )
}
