import React from 'react'
import { DateWithUTCTooltip } from '../common'
import { authenticatedFetchData, API_HOST, BROWSER_TIMEZONE } from '../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { ConsumerSessionsTable } from '../common/ConsumerSessionsTable'
import { useLoaderData, useParams } from 'react-router-dom'
import type { LoaderFunction } from 'react-router-dom'

export const bagtagLoader: LoaderFunction = async ({ params }) => {
  try {
    const tag = params.bagtag
    const bagInfo = await authenticatedFetchData(API_HOST + '/mytomra/v1.0/hotlinetool/bagsearch/' + tag).run()
    return { bagInfo }
  } catch (error: any) {
    logError(new Error('Failed to fetch bag info'), error)
    return { error }
  }
}

export const BagPage = () => {
  const params = useParams()
  const { bagInfo, error } = useLoaderData() as { bagInfo?: BagInfoType; error?: any }
  if (error || !bagInfo) {
    return (
      <div>
        <h1 className="sr-only">Bag info</h1>
        <div className="alert alert-danger centerAbsolute">Whoopsie! Failed to find bag info. Please try again.</div>
      </div>
    )
  }

  const { scannedBag, consumerSessions } = bagInfo
  const { bagtag = params.bagtag, userId, created } = scannedBag || {}

  return (
    <div className="p-lg">
      <h1 className="text-xl mb-md">Bag info</h1>

      <div className="flex space-x-md">
        <div className="w-1/4">
          <div className="card p-lg">
            <dl className="mb-md">
              <dt id="bagtag" className=" text-stormmb-sm">
                Bagtag
              </dt>
              <dd aria-labelledby="bagtag">{bagtag}</dd>
            </dl>

            <dl className="mb-md">
              <dt id="date" className=" text-stormmb-sm">
                Date ({BROWSER_TIMEZONE})
              </dt>
              <dd aria-labelledby="date">
                {created ? <DateWithUTCTooltip date={created} /> : 'No information/not claimed'}
              </dd>
            </dl>

            <dl>
              <dt id="userId" className=" text-stormmb-sm">
                User ID
              </dt>
              <dd aria-labelledby="userId">{userId ? userId : 'No information/not claimed'}</dd>
            </dl>
          </div>
        </div>

        <div className="w-3/4">
          <div className="card">
            <h2 className="text-lg inline-block ml-lg my-md">Consumer sessions</h2>

            <ConsumerSessionsTable consumerSessions={consumerSessions} />
          </div>
        </div>
      </div>
    </div>
  )
}
