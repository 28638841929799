import React, { FunctionComponent, useState } from 'react'
import { API_HOST, authenticatedFetchData } from '../../lib'
import { logError } from '@tomra/datadog-browser-logging'

type Props = {
  selectUser: (user: UserInfoType) => void
}

export const UserSearch: FunctionComponent<Props> = ({ selectUser }) => {
  const [status, setStatus] = useState<RequestStatusType>('idle')
  const [users, setUsers] = useState<UserInfoType[]>()
  const [userSearchTerm, setUserSearchTerm] = useState('')

  const searchForUser = () => {
    setStatus('pending')

    authenticatedFetchData(
      `${API_HOST}/mytomra/v1.0/hotlinetool/usersearch?search=${encodeURIComponent(userSearchTerm)}`
    )
      .run()
      .then(
        (users: UserInfoType[]) => {
          setStatus('idle')
          setUsers(users)

          if (users.length === 1) {
            selectUser(users[0])
          }
        },
        (error: Error) => {
          setStatus('failure')
          logError(new Error('Failed to fetch users'), error)
        }
      )
  }

  const buttonDisabled = status === 'pending' || !userSearchTerm

  const results =
    users && users.length > 1 ? (
      <div className="overflow-y-scroll" style={{ maxHeight: '30vh' }}>
        <table className="table">
          <tbody>
            {users.map(user => (
              <tr key={`u-${user.id}`}>
                <td>{user.firstName}</td>
                <td>{user.email}</td>
                <td>{user.appContext}</td>
                <td>
                  <button className="btn ml-auto" onClick={() => selectUser(user)}>
                    Select
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
      <>
        <p className="mt-md font-bold">Sorry, we couldn`&apos;t find that user.</p>
        <p>Try to include the entire email or first name and last name in the search.</p>
      </>
    )

  return (
    <>
      <form
        className="mb-md"
        onSubmit={e => {
          e.preventDefault()
          searchForUser()
        }}
      >
        <label htmlFor="searchField" className="inline-block mt-md mb-sm text-lg">
          Search for user
        </label>

        <div className="flex items-center justify-between space-x-md">
          <input
            id="searchField"
            type="search"
            className="max-w-full w-3/4"
            onChange={({ target: { value } }) => setUserSearchTerm(value)}
            value={userSearchTerm}
            placeholder="E-mail, name or ID"
            disabled={status === 'pending'}
          />
          <button className="btn btn-primary-dark w-1/4" type="submit" disabled={buttonDisabled}>
            {status === 'pending' ? <div className="loadingSpinner" /> : 'Search'}
          </button>
        </div>
      </form>

      {users ? results : null}
    </>
  )
}
