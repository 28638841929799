import React, { useState, useEffect, FunctionComponent } from 'react'
import { Link, useParams } from 'react-router-dom'
import { DateWithUTCTooltip } from '../common'
import { authenticatedFetchData, API_HOST, BROWSER_TIMEZONE } from '../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { ConsumerSessionsTable } from '../common/ConsumerSessionsTable'
import { Notes } from '../common/notes/Notes'

export const PayoutPage: FunctionComponent = () => {
  const { payoutId: sourceId } = useParams()
  const [payoutInfo, setPayoutInfo] = useState<PayoutInfoType>()
  const [findPayoutInfoStatus, setFindPayoutInfoStatus] = useState('loading')

  useEffect(() => {
    const f = authenticatedFetchData(
      `${API_HOST}/mytomra/v1.0/hotlinetool/payout?payoutid=${encodeURIComponent(sourceId || '')}`
    )

    f.run().then(
      (payoutInfo: PayoutInfoType) => {
        setFindPayoutInfoStatus('')
        setPayoutInfo(payoutInfo)
      },
      (error: Error) => {
        if (error.name !== 'AbortError') {
          setFindPayoutInfoStatus('failed')
          logError(new Error('Failed to fetch payout info.'), error)
        }
      }
    )

    return () => f.abort()
  }, [sourceId])

  if (findPayoutInfoStatus === 'failed') {
    return (
      <div>
        <h1 className="sr-only">Payout information</h1>
        <div className="alert alert-danger centerAbsolute">Whoopsie! Failed to payout info. Please try again.</div>
      </div>
    )
  }

  if (payoutInfo) {
    const { consumerSessions, payout } = payoutInfo
    const {
      amount,
      clearingHouse,
      created,
      currency,
      id,
      paymentProvider,
      userId,
      payoutResults,
      latestStatus,
      errorCategory
    } = payout

    return (
      <div className="p-lg">
        <h1 className="text-xl mb-md">Payout information</h1>

        <div className="flex">
          <div className="mr-sm w-1/4">
            <div className="card p-lg mb-lg">
              <dl className="mb-md">
                <dt id="payoutId" className="mb-sm text-storm">
                  Payout ID
                </dt>
                <dd aria-labelledby="payoutId">{id}</dd>
              </dl>

              <dl className="mb-md">
                <dt id="date" className="mb-sm text-storm">
                  Date ({BROWSER_TIMEZONE})
                </dt>
                <dd aria-labelledby="date">
                  <DateWithUTCTooltip date={created} />
                </dd>
              </dl>

              <dl className="mb-md">
                <dt id="amount" className="mb-sm text-storm">
                  Amount
                </dt>
                <dd aria-labelledby="amount">
                  {amount && amount.toFixed(2)} {currency}
                </dd>
              </dl>

              <dl className="mb-md">
                <dt id="status" className="mb-sm text-storm">
                  Latest status
                </dt>
                <dd aria-labelledby="status">
                  {latestStatus}
                  {errorCategory && ` (${errorCategory})`}
                </dd>
              </dl>

              <dl className="mb-md">
                <dt id="clearingHouse" className="mb-sm text-storm">
                  Clearing house
                </dt>
                <dd aria-labelledby="clearingHouse">{clearingHouse}</dd>
              </dl>

              <dl className="mb-md">
                <dt id="payoutProvider" className="mb-sm text-storm">
                  Payout provider
                </dt>
                <dd aria-labelledby="payoutProvider">{paymentProvider}</dd>
              </dl>

              <dl className="mb-md">
                <dt id="userId" className="mb-sm text-storm">
                  User ID
                </dt>
                <dd aria-labelledby="userId">
                  <Link to={`/user/${userId}`} className="link">
                    {userId}
                  </Link>
                </dd>
              </dl>
            </div>

            <Notes sourceId={sourceId!} />
          </div>

          <div className="ml-sm w-3/4">
            <div className="card">
              <h2 className="inline-block text-lg ml-lg my-md">Payout history</h2>

              <table className="table">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Version</th>
                    <th>Finalized date ({BROWSER_TIMEZONE})</th>
                    <th>External ID</th>
                  </tr>
                </thead>
                <tbody>
                  {payoutResults &&
                    payoutResults.map(({ externalId, finalized, status, version, payoutId }) => (
                      <tr key={payoutId + externalId}>
                        <td>{status}</td>
                        <td>{version}</td>
                        <td>{finalized && <DateWithUTCTooltip date={finalized} />}</td>
                        <td>{externalId}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <div className="card mt-lg">
              <h2 className="inline-block text-lg ml-lg my-md">Consumer sessions</h2>

              <ConsumerSessionsTable consumerSessions={consumerSessions} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="centerAbsolute">
      <div className="loadingSpinner" />
    </div>
  )
}
