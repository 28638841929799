import React from 'react'

type Props = {
  findUnclaimedBagsStatus: RequestStatusType
  offset: number
  currentListLength: number
  goBack: () => void
  goNext: () => void
}

export const PaginationControls = ({ findUnclaimedBagsStatus, goBack, goNext, currentListLength, offset }: Props) => {
  return (
    <div className="flex p-lg items-center justify-center">
      <button className="btn" onClick={goBack} disabled={findUnclaimedBagsStatus === 'pending' || offset === 0}>
        Previous
      </button>

      <div className="flex items-center justify-center pl-md pr-md">
        {findUnclaimedBagsStatus !== 'pending' && (
          <div>
            {offset} - {offset + currentListLength}
          </div>
        )}
        {findUnclaimedBagsStatus === 'pending' && <div className="loadingSpinner" />}
      </div>

      <button className="btn" onClick={goNext} disabled={findUnclaimedBagsStatus === 'pending'}>
        Next
      </button>
    </div>
  )
}
