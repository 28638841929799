import React, { FunctionComponent, ReactNode } from 'react'
import { Dialog } from '../Dialog'
import { AlertIcon } from '../../icons'

type Props = {
  title: string
  description?: string
  onClose: () => void
  children?: ReactNode
}

export const ErrorDialog: FunctionComponent<Props> = ({ title, description, onClose, children }) => {
  return (
    <Dialog ariaLabel={title}>
      <div className="card p-xl flex flex-col">
        <div className="self-center">
          <AlertIcon width="6rem" height="6rem" color="var(--colors-red)" />
        </div>

        <h1 className="text-xl mt-lg mb-md">{title}</h1>

        {description && <p>{description}</p>}
        {children}

        <button className="mt-lg btn px-xl self-end" onClick={onClose}>
          Ok
        </button>
      </div>
    </Dialog>
  )
}
