import React, { Component, ErrorInfo, ReactNode } from 'react'
import { logError } from '@tomra/datadog-browser-logging'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  }

  public static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logError(new Error('Uncaught error'), error, errorInfo)
  }

  public render() {
    if (this.state.hasError) {
      return <p className="text-red font-bold">Something went wrong. Our developers have been notified.</p>
    }

    return this.props.children
  }
}
