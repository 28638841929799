import React, { useState, useEffect, useCallback } from 'react'
import { authenticatedFetchData, API_HOST } from '../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { NotesForm } from './NotesForm'
import { NoteComment } from './NoteComment'
import { hasNotesWritePermission } from '../../../services/permission.service'

type Props = {
  sourceId: string
}

export const Notes = (props: Props) => {
  const [fetchedNotes, setFetchedNotes] = useState<NoteType[]>([])
  const [notesStatus, setNotesStatus] = useState<RequestStatusType>('idle')
  const sourceId = props.sourceId

  const fetchNotes = useCallback(() => {
    authenticatedFetchData(API_HOST + '/mytomra/v1.0/hotlinetool/managernotes/' + sourceId)
      .run()
      .then(
        (notes: NoteType[]) => {
          setFetchedNotes(notes)
          setNotesStatus('idle')
        },
        (error: Error) => {
          setNotesStatus('failure')
          logError(new Error('Failed to fetch Notes'), error)
        }
      )
  }, [sourceId])

  useEffect(() => {
    const { run, abort } = authenticatedFetchData(API_HOST + '/mytomra/v1.0/hotlinetool/managernotes/' + sourceId)

    run().then(
      (notes: NoteType[]) => {
        setFetchedNotes(notes)
        setNotesStatus('idle')
      },
      (error: Error) => {
        if (error.name !== 'AbortError') {
          setNotesStatus('failure')
          logError(new Error('Failed to fetch Notes'), error)
        }
      }
    )

    return () => abort()
  }, [sourceId])

  return notesStatus === 'pending' ? (
    <div className="mt-md mr-auto mb-md ml-auto">
      <div className="loadingSpinner" />
    </div>
  ) : notesStatus === 'failure' ? (
    <div className="alert alert-danger">Whoopsie! Failed to find manager notes info. Please try again.</div>
  ) : (
    <div className="card p-lg">
      {hasNotesWritePermission() && <NotesForm sourceId={sourceId} refetchNotes={fetchNotes} />}

      {fetchedNotes.length > 0
        ? fetchedNotes.map(({ id, created, name, email, updated, notes }) => (
            <NoteComment
              key={id}
              id={id}
              created={created}
              name={name}
              email={email}
              updated={updated}
              note={notes}
              refetchNotes={fetchNotes}
            />
          ))
        : null}
    </div>
  )
}
