import React, { FunctionComponent } from 'react'
import { ArrowRightIcon } from '../../../icons'
import { Link } from 'react-router-dom'

type Props = {
  campaigns: CampaignSummaryType[]
  showStatus?: boolean
}

export const CharityCampaignTable: FunctionComponent<Props> = ({ campaigns, showStatus = false }) => {
  return (
    <table className="table" aria-labelledby="campaignTableHeader">
      <thead>
        <tr>
          <th>Organisation</th>
          <th>Campaign name</th>
          {showStatus && <th>Status</th>}
          <th className="text-center">Date</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {campaigns
          ? campaigns.map(campaign => (
              <tr key={campaign.id}>
                <td>{campaign.charitySummary.displayName}</td>
                <td>{campaign.name}</td>
                {showStatus && <td className="capitalize">{campaign.status.toLowerCase()}</td>}
                <td className="text-right">{new Date(campaign.createdAt).toLocaleString()}</td>
                <td>
                  <div className="flex justify-end">
                    <Link to={`/charities/${campaign.charitySummary.id}`} aria-label={`View ${campaign.name} details`}>
                      <ArrowRightIcon width="1rem" height="1rem" />
                    </Link>
                  </div>
                </td>
              </tr>
            ))
          : null}
      </tbody>
    </table>
  )
}
