import React, { FunctionComponent, useState } from 'react'
import { Filter } from '../../common'
import { ExternalLocationsList } from './common/ExternalLocationsList'
import { ExternalLocationsMap } from './common/ExternalLocationsMap'

type Props = {
  allLocations: ExternalLocationType[]
}

export const ExternalLocationsOverview: FunctionComponent<Props> = ({ allLocations }) => {
  const [filteredExternalLocations, setFilteredExternalLocations] = useState<ExternalLocationType[]>([])
  const [filter, setFilter] = useState('')
  const [selectedLocation, setSelectedLocation] = useState<ExternalLocationType>()

  const locations = filter ? filteredExternalLocations : allLocations

  const filterLocations = (value: string) => {
    if (!value) {
      setFilteredExternalLocations([])
      setFilter('')
      return
    }

    const filtered = allLocations.filter(location => {
      const lowerCaseFilter = value.toLowerCase()
      const candidateName = location.name.toLowerCase()
      const candidateAddress = `${location.address} ${location.city} ${location.zipCode} ${location.state}`

      return candidateName.includes(lowerCaseFilter) || candidateAddress.toLowerCase().includes(lowerCaseFilter)
    })

    setFilter(value)
    setFilteredExternalLocations(filtered)
  }

  return (
    <div className="card p-lg">
      <div className="grid grid-cols-12 gap-x-md items-start">
        <div className="col-span-4 h-[80vh] overflow-hidden">
          <Filter onChange={filterLocations} />

          <div className="mt-md mb-xs text-sm font-bold">Showing {locations.length} locations</div>

          <div className="h-[calc(100%-7.2rem)] overflow-y-scroll">
            <ExternalLocationsList
              ariaLabel="All external locations"
              locations={locations}
              selectedLocation={selectedLocation}
              onLocationClicked={setSelectedLocation}
            />
          </div>
        </div>

        <ExternalLocationsMap
          locations={locations}
          selectedLocation={selectedLocation}
          onLocationClicked={setSelectedLocation}
          onCloseMapInfo={() => setSelectedLocation(undefined)}
        />
      </div>
    </div>
  )
}
