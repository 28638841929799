import React, { FunctionComponent } from 'react'

type Props = {
  isLoading: boolean
  limit: number
  offset: number
  onOffsetChange: (offset: number) => void
  canGoBack: boolean
  canGoNext: boolean
}

export const PaginationButtons: FunctionComponent<Props> = ({
  isLoading,
  limit,
  offset,
  onOffsetChange,
  canGoBack,
  canGoNext
}) => {
  const onPrev = () => onOffsetChange(offset - limit)
  const onNext = () => onOffsetChange(offset + limit)
  const goToFirst = () => onOffsetChange(0)

  return (
    <div className="flex items-center justify-between p-lg text-center">
      <button className="btn" onClick={onPrev} disabled={!canGoBack}>
        Previous page
      </button>

      {isLoading && <div className="loadingSpinner" />}

      {!isLoading && canGoBack && (
        <button className="btn" onClick={goToFirst}>
          Go to first page
        </button>
      )}

      <button className="btn" onClick={onNext} disabled={!canGoNext}>
        Next page
      </button>
    </div>
  )
}
